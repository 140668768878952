import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React from "react";
import {ApiClient, ApiInvoiceFilterRequest, InvoiceType} from "../../../../utils/http/apiClient";
import {Flex, Title} from "@mantine/core";
import {IconReceipt} from "@tabler/icons-react";
import {StatusBadge} from "../../../../common/StatusBadge";
import {formatDate} from "../../../../utils/date/dateUtils";
import {formatAmount} from "../../../../utils/formatUtils";
import {ObjectLink} from "../../../../common/ObjectLink";
import {DocumentLink} from "../../../../common/DocumentLink";
import {Column, DataTable, SelectColumn} from "../../../../common/datatable/DataTable";
import {createFilterMap, TableFilter} from "../../../../common/datatable/filter/types";

interface Props {
  invoiceType: InvoiceType
}

export const InvoiceList = ({invoiceType}: Props) => {
  const organisationId = useOrganisationId() ?? '';

  const titleMap = {
    'INCOMING_INVOICE': 'Incoming Invoices',
    'REGISTER_REPORT': 'Register reports'
  } as Record<InvoiceType, string>;

  const columnMap: Record<string, Column> = {
    id: {
      name: 'id',
      label: 'ID',
      type: 'TEXT',
      render: (record, column, openModal) => {
        return <DocumentLink type={invoiceType} id={record[column.name]}
                             onClick={() => openModal?.(invoiceType, record[column.name])}/>
      },
      filterDisabled: true
    },
    bookingStatus: {
      name: 'bookingStatus',
      label: 'Status',
      type: 'SELECT',
      options: [
        {label: 'DRAFT', value: 'DRAFT'},
        {label: 'BOOKED', value: 'BOOKED'},
        {label: 'PARTIAL', value: 'PARTIAL'},
      ],
      optionLookup: {},
      render: (record, column) =>
        <div style={{textAlign: 'center', justifyContent: 'center', display: 'flex'}}>
          <StatusBadge status={record[column.name]}/>
        </div>
    } as SelectColumn,
    bookingDate: {
      name: 'bookingDate',
      label: 'Booking Date',
      type: 'DATE',
      render: (record, column) => formatDate(record[column.name]),
    } as Column,
    supplier: {
      name: 'objectId',
      label: 'Supplier',
      type: 'SELECT',
      options: [],
      optionLookup: {},
      fetchOptions: () =>
        ApiClient.getContacts(organisationId)
          .then(resp => resp.data.map(c => ({label: c.name, value: c.id}))),
      render: (record, column, openModal) => {
        const selectCol = column as SelectColumn;
        const value = record[column.name];
        return <ObjectLink name={selectCol.optionLookup[value]}
                           onClick={() => openModal?.('CONTACT', value)}/>
      },
    } as SelectColumn,
    register: {
      name: 'objectId',
      label: 'Register',
      type: 'SELECT',
      options: [],
      optionLookup: {},
      fetchOptions: () =>
        ApiClient.getRegisters(organisationId)
          .then(resp => resp.data.map(c => ({label: c.name, value: c.id}))),
      render: (record, column, openModal) => {
        const selectCol = column as SelectColumn;
        const value = record[column.name];
        return <ObjectLink name={selectCol.optionLookup[value]}
                           onClick={() => openModal?.('REGISTER_REPORT', value)}/>
      },
    } as SelectColumn,
    invoiceNumber: {
      name: 'invoiceNumber',
      label: 'Invoice Number',
      type: 'TEXT'
    } as Column,
    grossAmount: {
      name: 'grossAmount',
      label: 'Gross Amount',
      type: 'NUMBER',
      render: (record, column) => formatAmount(record[column.name]),
    } as Column,
    currency: {
      name: 'currency',
      label: 'Currency',
      type: 'SELECT',
      options: [
        {label: 'EUR', value: 'EUR'},
        {label: 'USD', value: 'USD'},
        {label: 'GBP', value: 'GBP'},
      ],
      optionLookup: {},
    } as SelectColumn,
    issueDate: {
      name: 'issueDate',
      label: 'Issue Date',
      type: 'DATE',
      render: (record, column) => formatDate(record[column.name]),
    } as Column,
    dueDate: {
      name: 'dueDate',
      label: 'Due Date',
      type: 'DATE',
      render: (record, column) => formatDate(record[column.name]),
    } as Column,
  }

  const columns = invoiceType === 'INCOMING_INVOICE'
    ? [
      columnMap['id'],
      columnMap['bookingStatus'],
      columnMap['bookingDate'],
      columnMap['supplier'],
      columnMap['invoiceNumber'],
      columnMap['grossAmount'],
      columnMap['currency'],
      columnMap['dueDate'],
    ] as Column[]
    : [
      columnMap['id'],
      columnMap['bookingStatus'],
      columnMap['bookingDate'],
      columnMap['register'],
      columnMap['grossAmount'],
      columnMap['currency'],
    ] as Column[];


  return <>
    <Flex direction="row" align="center">
      <IconReceipt size={35} stroke={1.5}/>
      <Title size={24}>
        {titleMap[invoiceType]}
      </Title>
    </Flex>

    <DataTable objectType={invoiceType}
               columns={columns}
               fetchRecords={(filter: TableFilter) => {
                 const filterRequest = {
                   pageNum: filter.pageNum,
                   pageSize: filter.pageSize,
                 } as ApiInvoiceFilterRequest;
                 const filterMap = createFilterMap(filter.columnFilters, columns);
                 Object.entries(filterMap)
                   .forEach(([key, value]) => {
                     filterRequest[key] = value
                   });
                 return ApiClient.getInvoicesByFilter(organisationId, invoiceType, filterRequest);
               }}/>

  </>;
}