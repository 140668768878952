import {SortOrder} from "./types";
import {Select} from "@mantine/core";
import React from "react";

interface Props {
  value: SortOrder | null
  onChange: (value: SortOrder | null) => void
}

export const SortOrderSelect = ({value, onChange}: Props) => {
  return <Select size="sm"
                 data={[
                   {label: 'A ⇒ Z | 0 ⇒ 9', value: 'ASC'},
                   {label: 'Z ⇒ A | 9 ⇒ 0', value: 'DESC'},
                 ]}
                 styles={{
                   input: {
                     minHeight: '24px',
                     maxHeight: '24px',
                   }
                 }}
                 clearable={true}
                 onClick={e => {
                   e.preventDefault();
                   e.stopPropagation();
                 }}
                 checkIconPosition="right"
                 comboboxProps={{withinPortal: false}}
                 value={value}
                 onChange={v => onChange(v as SortOrder)}/>
}