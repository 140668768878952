import {CellValue, Column, InternalCell} from "./types";
import React, {memo, MouseEvent} from "react";
import {DataSheetTextInput} from "./input/DataSheetTextInput";
import {DataSheetNumberInput} from "./input/DataSheetNumberInput";
import {DataSheetSelect} from "./input/DataSheetSelect";
import {formatAmount, formatNumber} from "../../utils/formatUtils";
import {HoverCard, List, ListItem} from "@mantine/core";
import {IconAlertCircle} from "@tabler/icons-react";

export interface DataSheetCellProps {
  cellValue: any | null,
  internalCell: InternalCell
  rowId: number,
  colId: number
  column: Column
  onCellMouseDown: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseMove: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseUp: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseOver: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseOut: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellDoubleClick: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellRightClick: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellChange: (value: any, rowId: number, colId: number) => void
}

function renderCellInput(cellValue: CellValue,
                         internalCell: InternalCell,
                         column: Column,
                         onChange: (value: any) => void) {
  switch (column.type) {
    case 'TEXT':
      const stringValue = internalCell.editStartKey !== null
        ? internalCell.editStartKey
        : cellValue;
      return <DataSheetTextInput value={stringValue} onChange={onChange}/>
    case 'NUMBER':
    case 'AMOUNT':
      const numberValue = internalCell.editStartKey !== null
        ? Number(internalCell.editStartKey)
        : cellValue;
      return <DataSheetNumberInput value={numberValue} onChange={onChange}/>
    case 'SELECT':
      const searchValue = internalCell.editStartKey !== null
        ? (internalCell.editStartKey as string)
        : '';
      return <DataSheetSelect value={cellValue ?? ''}
                              options={column.selectOptions}
                              defaultInputValue={searchValue}
                              onChange={option => onChange(option?.value)}
                              modalRef={column.modalRef}/>
  }
}

export function renderCellTextValue(cellValue: CellValue, column: Column) {
  switch (column.type) {
    case 'NUMBER':
      return cellValue && !Number.isNaN(cellValue) ? formatNumber(cellValue) : cellValue?.toString();
    case 'AMOUNT':
      return cellValue && !Number.isNaN(cellValue) ? formatAmount(cellValue) : cellValue?.toString();
    case 'SELECT':
      return column.selectOptions?.find(o => o.value === cellValue)?.label ?? '';
    default:
      return cellValue;
  }
}

function renderCellValue(cellValue: CellValue, internalCell: InternalCell, column: Column) {
  const hasErrors = internalCell.errors?.length > 0;

  if (!hasErrors) {
    return renderCellTextValue(cellValue, column);
  }

  return <HoverCard openDelay={500}>
    <HoverCard.Target>
      <div>{renderCellTextValue(cellValue, column)}</div>
    </HoverCard.Target>
    <HoverCard.Dropdown>
      <List size="sm" spacing="xs" center={true} icon={<IconAlertCircle size="1.5rem" stroke={1.5} color="red"/>}>
        {(internalCell?.errors ?? []).map((error, idx) => (
          <ListItem key={idx}>
            {error}
          </ListItem>
        ))}
      </List>
    </HoverCard.Dropdown>
  </HoverCard>
}

export const DataSheetCell = memo(({
                                     cellValue,
                                     internalCell,
                                     rowId,
                                     colId,
                                     column,
                                     onCellMouseDown,
                                     onCellMouseMove,
                                     onCellMouseUp,
                                     onCellMouseOver,
                                     onCellMouseOut,
                                     onCellDoubleClick,
                                     onCellRightClick,
                                     onCellChange,
                                   }: DataSheetCellProps) => {
  const classes = [];
  classes.push(internalCell.selected ? 'selected' : '');
  classes.push(internalCell.editing ? 'editing' : '');
  classes.push(column.readOnly ? 'readOnly' : '');
  classes.push(!internalCell.selected && internalCell.errors?.length > 0 ? 'error' : '');
  const className = classes.join(' ');

  // console.log(`Cell render: ${rowId}, ${colId}`);

  return <td className={className}
             style={{
               textAlign: column.textAlign ?? 'center',
               maxWidth: column.width + 'px',
             }}
             onMouseDown={e => onCellMouseDown(e, rowId, colId)}
             onMouseMove={e => onCellMouseMove(e, rowId, colId)}
             onMouseUp={e => onCellMouseUp(e, rowId, colId)}
             onMouseOver={e => onCellMouseOver(e, rowId, colId)}
             onMouseOut={e => onCellMouseOut(e, rowId, colId)}
             onDoubleClick={e => onCellDoubleClick(e, rowId, colId)}
             onContextMenu={e => onCellRightClick(e, rowId, colId)}>
    {internalCell.editing
      ? renderCellInput(cellValue, internalCell, column, (value) => onCellChange(value, rowId, colId))
      : renderCellValue(cellValue, internalCell, column)}
  </td>
});