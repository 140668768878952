import {ColumnFilter, SortOrder} from "./types";
import {Column, SelectColumn} from "../DataTable";
import {Flex, Pill} from "@mantine/core";
import {formatDate} from "../../../utils/date/dateUtils";
import {formatAmount, formatNumber} from "../../../utils/formatUtils";
import {IconSortAscending} from "@tabler/icons-react";
import React from "react";

interface Props {
  filter: ColumnFilter
  column: Column
  onChange: (filter: ColumnFilter) => void
}

const ValueBadge = ({value, onRemove}: { value: any, onRemove: () => void, key?: string | number }) => {
  return <span>
    <Pill variant="default"
          withRemoveButton={true}
          style={{background: 'var(--mantine-color-blue-1'}}
          onRemove={onRemove}>
      {value}
    </Pill>
  </span>
}

const SortBadge = ({value, onRemove}: { value: SortOrder, onRemove: () => void }) => {
  return <span>
    <Pill variant="default"
          withRemoveButton={true}
          style={{background: 'var(--mantine-color-blue-1'}}
          onRemove={onRemove}
    >
      Sort:
      {value === 'ASC' &&
          <span> (A ⇒ Z)</span>}
      {value === 'DESC' &&
          <span>(Z ⇒ A)</span>}
  </Pill>
  </span>
}

export const FilterValuePills = ({filter, column, onChange}: Props) => {
  return <>
    <Flex direction="column" gap={2}>
      {column.type === 'TEXT' &&
          <>
            {filter.operands[0] &&
                <ValueBadge value={`Contains: ${filter.operands[0]}`}
                            onRemove={() => onChange({...filter, operands: [null]})}/>}
          </>}

      {column.type === 'NUMBER' &&
          <>
            {filter.operands[0] &&
                <ValueBadge value={`X ≥ ${formatAmount(filter.operands[0])}`}
                            onRemove={() => onChange({
                              ...filter,
                              operands: [null, filter.operands[1]]
                            })}/>}
            {filter.operands[1] &&
                <ValueBadge value={`X ≤ ${formatAmount(filter.operands[1])}`}
                            onRemove={() => onChange({
                              ...filter,
                              operands: [filter.operands[0], null]
                            })}/>}
          </>}

      {column.type === 'DATE' &&
          <>
            {filter.operands[0] &&
                <ValueBadge value={`From: ${formatDate(filter.operands[0])}`}
                            onRemove={() => onChange({
                              ...filter,
                              operands: [null, filter.operands[1]]
                            })}/>}
            {filter.operands[1] &&
                <ValueBadge value={`To: ${formatDate(filter.operands[1])}`}
                            onRemove={() => onChange({
                              ...filter,
                              operands: [filter.operands[0], null]
                            })}/>}
          </>}

      {column.type === 'SELECT' &&
          <>
            {filter.operands.map((operand, idx) => (
              <ValueBadge key={idx}
                          value={(column as SelectColumn).optionLookup[operand]}
                          onRemove={() => onChange({
                            ...filter,
                            operands: filter.operands.filter(o => o !== operand)
                          })}/>))}
          </>}

      {filter.sortOrder &&
          <SortBadge value={filter.sortOrder}
                     onRemove={() => onChange({...filter, sortOrder: null})}/>}
    </Flex>
  </>
}