import {ApiClient, ApiComment, ApiUpsertCommentRequest, ApiUser, ObjectType} from "../../utils/http/apiClient";
import React, {useEffect, useReducer, useRef} from "react";
import {createIdMap} from "../../utils/objectUtils";
import {Button, Divider, Flex, List, LoadingOverlay, Text} from "@mantine/core";
import {Comment} from "./Comment";
import {CommentContextMenu} from "./CommentContextMenu";
import {notifyError} from "../../utils/notificationUtils";
import {IconDeviceFloppy} from "@tabler/icons-react";
import {TextAreaInput} from "../TextAreaInput";

interface Props {
  organisationId: string
  objectType: ObjectType
  objectId: string | null
}

interface State {
  organisationId: string
  objectType: ObjectType
  objectId: string | null
  loading: boolean
  comments: ApiComment[]
  usersById: Record<string, ApiUser>
  editingCommentId: string | null
  newCommentText: string
}

type ActionType =
  | 'SET_LOADING'
  | 'PROPS_UPDATE'
  | 'FETCH_ENTITIES_SUCCESS'
  | 'EDIT_COMMENT'
  | 'UPDATE_COMMENT_TEXT'
  | 'UPDATE_NEW_COMMENT_TEXT';

type ActionBase = { type: ActionType };

type Action = ActionBase & ({
  type: 'SET_LOADING'
  loading: boolean
}) | ({
  type: 'PROPS_UPDATE'
  organisationId: string
  objectType: ObjectType
  objectId: string | null
}) | ({
  type: 'FETCH_ENTITIES_SUCCESS'
  comments: ApiComment[]
  users: ApiUser[]
}) | ({
  type: 'EDIT_COMMENT'
  commentId: string
}) | ({
  type: 'CANCEL_EDIT_COMMENT'
}) | ({
  type: 'UPDATE_COMMENT_TEXT'
  commentId: string
  text: string
}) | ({
  type: 'UPDATE_NEW_COMMENT_TEXT'
  text: string
});

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_LOADING': {
      return {...state, loading: action.loading} as State;
    }
    case 'PROPS_UPDATE': {
      return {
        ...state,
        organisationId: action.organisationId,
        objectType: action.objectType,
        objectId: action.objectId,
        editingCommentId: null,
        newCommentText: '',
      } as State;
    }
    case 'FETCH_ENTITIES_SUCCESS': {
      return {
        ...state,
        comments: action.comments,
        usersById: createIdMap(action.users),
        editingCommentId: null,
        newCommentText: '',
      } as State;
    }
    case 'EDIT_COMMENT': {
      return {...state, editingCommentId: action.commentId,} as State;
    }
    case 'CANCEL_EDIT_COMMENT': {
      return {...state, editingCommentId: null,} as State;
    }
    case 'UPDATE_COMMENT_TEXT': {
      return {
        ...state,
        comments: state.comments
          .map(c => c.id === action.commentId
            ? {...c, text: action.text}
            : c)
      } as State;
    }
    case 'UPDATE_NEW_COMMENT_TEXT': {
      return {...state, newCommentText: action.text} as State;
    }
  }
}


export const Comments = ({organisationId, objectType, objectId}: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    organisationId: '',
    objectType: "FILE",
    objectId: null,
    comments: [],
    usersById: {},
    editingCommentId: null,
    newCommentText: '',
  } as State);
  const contextMenuRef = useRef<any>();

  const fetchEntities = () => {
    if (state.objectId) {
      dispatch({type: 'SET_LOADING', loading: true});
      Promise
        .all([
          ApiClient.getComments(state.organisationId, state.objectType, state.objectId).then(r => r.data),
          ApiClient.getUsersByOrganisation(organisationId).then(r => r.data),
        ])
        .then(([comments, users]) => {
          dispatch({type: 'FETCH_ENTITIES_SUCCESS', comments, users});
        })
        .finally(() => dispatch({type: 'SET_LOADING', loading: false}));
    }
  }

  useEffect(() => {
    fetchEntities();
  }, [state.organisationId, state.objectType, state.objectId]);

  useEffect(() => {
    dispatch({type: 'PROPS_UPDATE', organisationId, objectType, objectId})
  }, [organisationId, objectType, objectId]);

  const handleContextMenuOption = (commentId: string, option: string) => {
    switch (option) {
      case 'DELETE':
        if (objectId) {
          dispatch({type: 'SET_LOADING', loading: true});
          ApiClient.deleteComment(organisationId, commentId)
            .then(fetchEntities)
            .catch((err) => {
              notifyError(err);
              dispatch({type: 'SET_LOADING', loading: false});
            });
        }
        break;
      case 'EDIT':
        dispatch({type: 'EDIT_COMMENT', commentId});
        break;
    }
  }

  const handleSaveEdit = (commentId: string, text: string) => {
    if (objectId) {
      dispatch({type: 'SET_LOADING', loading: true});
      const request = {organisationId, objectId, objectType, text} as ApiUpsertCommentRequest;
      ApiClient.updateComment(organisationId, commentId, request)
        .then(fetchEntities)
        .catch((err) => {
          notifyError(err);
          dispatch({type: 'SET_LOADING', loading: false});
        });
    }
  }

  const handleSaveNewComment = (text: string) => {
    if (objectId && text.length > 0) {
      dispatch({type: 'SET_LOADING', loading: true});
      const request = {organisationId, objectId, objectType, text} as ApiUpsertCommentRequest;
      ApiClient.createComment(organisationId, request)
        .then(fetchEntities)
        .catch((err) => {
          notifyError(err);
          dispatch({type: 'SET_LOADING', loading: false});
        });
    }
  }

  const handleCancelEdit = () => {
    dispatch({type: 'CANCEL_EDIT_COMMENT'});
  }

  const handleUpdateCommentText = (commentId: string, text: string) => {
    dispatch({type: 'UPDATE_COMMENT_TEXT', commentId, text});
  }

  const handleUpdateNewCommentText = (text: string) => {
    dispatch({type: 'UPDATE_NEW_COMMENT_TEXT', text});
  }

  return <div style={{position: 'relative', display: 'flex', justifyContent: 'center'}}>
    <LoadingOverlay visible={state.loading} zIndex={1000}/>
    <Flex direction="column" style={{width: '500px'}} gap="xs">
      <Flex direction="column" gap={0} align={'center'}>
        <Text c="dimmed" size="sm">Comments</Text>

        <List size="sm" listStyleType="none" spacing="md" style={{padding: 0, margin: 0}}>
          {state.comments.map(comment => (
            <List.Item key={comment.id} style={{padding: 0, margin: 0}}>
              <Comment comment={comment}
                       editing={state.editingCommentId === comment.id}
                       usersById={state.usersById}
                       openContextMenu={(e) => contextMenuRef.current.openMenu(e.clientX, e.clientY, comment.id, comment.author)}
                       onTextChange={(text) => handleUpdateCommentText(comment.id ?? '', text)}
                       onSaveEdit={() => handleSaveEdit(comment?.id ?? '', comment.text ?? '')}
                       onCancelEdit={handleCancelEdit}
              />
            </List.Item>))}
        </List>
      </Flex>
      <Divider/>
      {state.objectId
        ? <>
          <TextAreaInput value={state.newCommentText}
                         onChange={handleUpdateNewCommentText}
                         disabled={state.editingCommentId !== null}/>
          <div>
            <Button size="xs" leftSection={<IconDeviceFloppy/>}
                    onClick={() => handleSaveNewComment(state.newCommentText)}
                    disabled={state.editingCommentId !== null}>
              Save
            </Button>
          </div>
        </>
        : <Text size="sm">Comment can be added once the object has been created/saved</Text>}
    </Flex>
    <CommentContextMenu ref={contextMenuRef} onOptionClick={handleContextMenuOption}/>
  </div>
};
