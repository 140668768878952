import {ApiInvoice, ApiInvoiceLineItem, ApiItem, ApiTax, ApiUnit} from "../../../../../utils/http/apiClient";
import {DataSheet} from "../../../../../common/datasheet/DataSheet";
import {isNumericCheck, isUnitCheck, mapInvoiceLineItems} from "../../files/extraction/extractionsUtils";
import React, {useEffect, useMemo, useRef} from "react";
import {Option} from "../../../../../common/datasheet/input/DataSheetSelect";
import {Column} from "../../../../../common/datasheet/types";
import {createIdMap, roundToX} from "../../../../../utils/objectUtils";
import {checkNetPlusTaxEqualGross} from "../utils";
import {ScrollArea, Space} from "@mantine/core";

interface Props {
  lineItems: ApiInvoiceLineItem[]
  onLineItemsChange: (lineItems: ApiInvoiceLineItem[]) => void
  items: ApiItem[]
  taxes: ApiTax[]
  units: ApiUnit[]
  itemModalRef?: React.MutableRefObject<any>
  internalView: boolean
}

export const InvoiceLineItems = ({
                                   lineItems,
                                   onLineItemsChange,
                                   taxes,
                                   items,
                                   units,
                                   itemModalRef,
                                   internalView
                                 }: Props) => {
  const unitsById = useMemo(() =>
      createIdMap(units),
    [units]);

  const itemOptions = useMemo(() =>
      items.map(i => {
        return {
          value: i.id,
          label: `${i.name} [${unitsById[i.unitId].shortName}]`
        } as Option;
      }),
    [items, unitsById]);

  const taxOptions = useMemo(() =>
      taxes.map(t => ({
        value: t.id,
        label: `${t.rate * 100}%`
      } as Option)),
    [taxes]);

  const allColumns = useMemo(() =>
      [
        {
          name: 'text',
          displayName: 'Text',
          type: 'TEXT',
          textAlign: 'left'
        },
        {
          name: 'extQuantity1',
          displayName: 'Qty1',
          type: 'TEXT',
          textAlign: 'right',
          checkErrors: value => isNumericCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        {
          name: 'extUnit1',
          displayName: 'Unit1',
          type: 'TEXT',
          textAlign: 'center',
          checkErrors: value => isUnitCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        {
          name: 'extQuantity2',
          displayName: 'Qty2',
          type: 'TEXT',
          textAlign: 'right',
          checkErrors: value => isNumericCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        {
          name: 'extUnit2',
          displayName: 'Unit2',
          type: 'TEXT',
          textAlign: 'right',
          checkErrors: value => isUnitCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        {
          name: 'extQuantity3',
          displayName: 'Qty3',
          type: 'TEXT',
          textAlign: 'right',
          checkErrors: value => isNumericCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        // {
        //   name: 'unit',
        //   displayName: 'Unit',
        //   type: 'TEXT',
        //   textAlign: 'right',
        //   background: 'var(--mantine-color-teal-6)',
        //   readOnly: true,
        // },
        {
          name: 'extDiscount',
          displayName: 'Discount',
          type: 'TEXT',
          textAlign: 'right',
          checkErrors: value => isNumericCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        {
          name: 'extTaxRate',
          displayName: 'Tax',
          type: 'TEXT',
          textAlign: 'right',
          checkErrors: value => isNumericCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        {
          name: 'extAmount',
          displayName: 'Amount',
          type: 'TEXT',
          textAlign: 'right',
          checkErrors: value => isNumericCheck(value),
          background: 'var(--mantine-color-teal-6)',
        },
        {
          name: 'itemId',
          displayName: 'Item',
          type: 'SELECT',
          textAlign: 'left',
          selectOptions: itemOptions,
          modalRef: itemModalRef,
        },
        {
          name: 'unitPrice',
          displayName: 'Unit Price',
          type: 'NUMBER',
          textAlign: 'right',
          readOnly: true,
          wordWrap: true,
        },
        {
          name: 'quantity',
          displayName: 'Quantity',
          type: 'NUMBER',
          textAlign: 'right'
        },
        {
          name: 'taxId',
          displayName: 'Tax',
          type: 'SELECT',
          textAlign: 'right',
          selectOptions: taxOptions,
          // modalRef: modalRefs.tax,
        },
        {
          name: 'netAmount',
          displayName: 'Net Amount',
          type: 'NUMBER',
          textAlign: 'right',
          wordWrap: true,
        },
        {
          name: 'taxAmount',
          displayName: 'Tax Amount',
          type: 'NUMBER',
          textAlign: 'right',
          wordWrap: true,
        },
        {
          name: 'grossAmount',
          displayName: 'Gross Amount',
          type: 'NUMBER',
          textAlign: 'right',
          wordWrap: true,
        },
      ] as Column[]
    , [itemOptions, taxOptions]);

  const columns = useMemo(() => {
    const internalColumns = new Set([
      'extQuantity1',
      'extUnit1',
      'extQuantity2',
      'extUnit2',
      'extQuantity3',
      'extDiscount',
      'extTaxRate',
      'extAmount'
    ]);

    return internalView
      ? allColumns
      : allColumns.filter(c => !internalColumns.has(c.name));

  }, [allColumns, internalView]);

  return <ScrollArea w={1050}>
    <DataSheet
      caption="Line Items"
      showLineNumbers={true}
      columns={columns}
      rows={lineItems}
      onRowsChanged={rows => onLineItemsChange(rows as ApiInvoiceLineItem[])}
      rowErrorChecks={[
        checkNetPlusTaxEqualGross
      ]}/>
    <Space h={10} />
  </ScrollArea>
}