import {InvoiceModal} from "../../pages/private/organisation/invoices/modal/InvoiceModal";
import React, {useImperativeHandle, useRef} from "react";
import {ObjectType} from "../../utils/http/apiClient";
import {FileModal} from "../../pages/private/organisation/files/FileModal";
import {ContactModal} from "../../pages/private/organisation/contacts/ContactModal";
import {RegisterModal} from "../../pages/private/organisation/register/RegisterModal";
import {ItemModal} from "../../pages/private/organisation/items/ItemModal";

interface Props {
  onSuccess: () => void
}

export const Modals = React.forwardRef(({onSuccess}: Props, ref) => {
  const modalRefs = {
    'INCOMING_INVOICE': useRef<any>(),
    'REGISTER_REPORT': useRef<any>(),
    'WORK_ORDER': useRef<any>(),
    'FILE': useRef<any>(),
    'CONTACT': useRef<any>(),
    'REGISTER': useRef<any>(),
    'ITEM': useRef<any>(),
  }

  const openModal = (objectType: ObjectType, objectId: string | undefined) => {
    switch (objectType) {
      case 'INCOMING_INVOICE':
      case 'REGISTER_REPORT':
        modalRefs['INCOMING_INVOICE'].current?.openModal(objectType, objectId);
        break;
      case "TRANSACTION":
        // No Modal for transactions
        break;
      default:
        modalRefs[objectType].current?.openModal(objectId);
    }
  }

  useImperativeHandle(ref, () => ({openModal}));

  return <>
    <InvoiceModal ref={modalRefs['INCOMING_INVOICE']}
                  onSuccess={onSuccess}/>

    <FileModal ref={modalRefs['FILE']}
               onSuccess={onSuccess}
               onClose={() => null}/>

    <ContactModal ref={modalRefs['CONTACT']}
                  onSuccess={onSuccess}
                  onClose={() => null}/>

    <RegisterModal ref={modalRefs['REGISTER']}
                   onSuccess={onSuccess}
                   onClose={() => null}/>

    <ItemModal ref={modalRefs['ITEM']}
                   onSuccess={onSuccess}
                   onClose={() => null}/>
  </>;
});