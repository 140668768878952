import {GroupBase, OptionsOrGroups} from "react-select";
import React, {ReactElement, useEffect, useReducer, useRef} from "react";
import {AxiosResponse} from "axios";
import {notifyError} from "../../utils/notificationUtils";
import {ActionIcon, Badge, Button, Flex, Loader, LoadingOverlay, Space, Text, Tooltip} from "@mantine/core";
import {createValueLookup} from "../../utils/objectUtils";
import {FilterTextButton} from "./filter/FilterTextButton";
import {FilterNumberButton} from "./filter/FilterNumberButton";
import {FilterDateButton} from "./filter/FilterDateButton";
import {FilterMultiSelectButton} from "./filter/FilterMultiSelectButton";
import {ColumnFilter, TableFilter} from "./filter/types";
import {IconFilterX, IconPlus} from "@tabler/icons-react";
import {FilterValuePills} from "./filter/FilterValuePills";
import {ApiFilterResponse, ObjectType} from "../../utils/http/apiClient";
import {Modals} from "./Modals";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export type ColumnType = 'TEXT' | 'NUMBER' | 'SELECT' | 'DATE';

const COLUMN_CSS_CLASS = {
  'TEXT': 'datatable-column-text',
  'NUMBER': 'datatable-column-number',
  'SELECT': 'datatable-column-select',
  'DATE': 'datatable-column-date',
} as Record<ColumnType, string>;

export type SelectOptions = OptionsOrGroups<any, GroupBase<any>>;

export type Column = {
  name: string
  label?: string
  type: ColumnType
  render?: (row: Row,
            column: Column,
            openModal?: (objectType: ObjectType, objectId: string | undefined) => void
  ) => ReactElement | string;
  filterDisabled?: boolean
}

export type SelectColumn = Column & ({
  type: 'SELECT',
  options: SelectOptions
  optionLookup: Record<string, string>
  fetchOptions?: () => Promise<SelectOptions>
});

export type Row = {
  [key: string]: any
}

export type ButtonPromise = () => Promise<AxiosResponse> | null
export type ButtonCallback = (buttonPromise: ButtonPromise) => void

export interface DataTableProps {
  objectType: ObjectType
  columns: Column[]
  fetchRecords: (filter: TableFilter) => Promise<AxiosResponse<ApiFilterResponse<Row>>>
  customNewButton?: (callback: ButtonCallback) => ReactElement
  loadingOverlay?: boolean
}

type ActionType =
  | 'SET_LOADING'
  | 'FETCH_ENTITIES_SUCCESS'
  | 'FETCH_ENTITIES_ERROR'
  | 'FILTER_CHANGED'
  | 'COLUMN_FILTER_CHANGED'
  | 'CLEAR_FILTERS'
  | 'ROW_CLICKED'
  | 'TRIGGER_FETCH'

type ActionBase = { type: ActionType };

type Action = ActionBase & ({
  type: 'SET_LOADING'
  loading: boolean
} | {
  type: 'FETCH_ENTITIES_SUCCESS',
  rows: Row[],
  rowCount?: number
  columnOptions: { column: string, options: SelectOptions }[]
} | {
  type: 'FETCH_ENTITIES_ERROR'
} | {
  type: 'FILTER_CHANGED'
  filter: TableFilter
} | {
  type: 'COLUMN_FILTER_CHANGED'
  columnFilter: ColumnFilter
} | {
  type: 'CLEAR_FILTERS'
} | {
  type: 'ROW_CLICKED'
  rowIdx: number
} | {
  type: 'TRIGGER_FETCH'
});

type State = {
  objectType: ObjectType
  columns: Column[]
  rows: Row[]
  rowCount: number
  filter: TableFilter
  loading: boolean
  lastClickedRow?: number
}

function calculatePageCount(records: number, pageSize: number) {
  return Math.floor(records / pageSize) + (records % pageSize > 0 ? 1 : 0);
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_LOADING': {
      return {...state, loading: action.loading};
    }
    case 'FETCH_ENTITIES_SUCCESS': {
      const selColsOptions = action.columnOptions.reduce(
        (acc, curr) => ({...acc, [curr.column]: curr.options}),
        {} as Record<string, SelectOptions>);

      const isSelCol = (c: Column): c is SelectColumn => c.type === 'SELECT';
      const rowCount = action.rowCount ?? action.rows.length;
      const pageCount = calculatePageCount(rowCount, state.filter.pageSize);
      const resetFilterPage = pageCount > 0 && pageCount < state.filter.pageNum + 1;

      return {
        ...state,
        loading: false,
        rows: action.rows,
        rowCount: rowCount,
        columns: state.columns.map(c => {
          return isSelCol(c)
            ? {
              ...c,
              options: selColsOptions[c.name],
              optionLookup: createValueLookup(selColsOptions[c.name])
            }
            : c;
        }),
        filter: resetFilterPage
          ? {...state.filter, pageNum: 0}
          : state.filter
      }
    }
    case 'FETCH_ENTITIES_ERROR': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'FILTER_CHANGED': {
      return {
        ...state,
        filter: action.filter,
        lastClickedRow: undefined
      };
    }
    case 'COLUMN_FILTER_CHANGED': {
      const newFilters = action.columnFilter.sortOrder
        ? state.filter.columnFilters.map(f => ({...f, sortOrder: null}))
        : [...state.filter.columnFilters];

      const filterIndex = newFilters.findIndex(f => f.column === action.columnFilter.column);
      newFilters[filterIndex] = action.columnFilter;

      return {
        ...state,
        filter: {...state.filter, columnFilters: newFilters},
        lastClickedRow: undefined
      };
    }
    case 'CLEAR_FILTERS': {
      const newFilters = state.filter.columnFilters.map(f => ({...f, operands: [], sortOrder: null}));
      return {
        ...state,
        filter: {...state.filter, columnFilters: newFilters},
        lastClickedRow: undefined
      };
    }
    case 'ROW_CLICKED': {
      return {...state, lastClickedRow: action.rowIdx};
    }
    case 'TRIGGER_FETCH': {
      return {...state, filter: {...state.filter}};
    }
  }
  return state;
}

export const DataTable = ({
                            objectType,
                            columns,
                            fetchRecords,
                            customNewButton,
                            loadingOverlay,
                          }: DataTableProps) => {
  const [state, dispatch] = useReducer(reducer,
    {
      objectType: objectType,
      columns: columns,
      rows: [],
      rowCount: 0,
      filter: {
        pageNum: 0,
        pageSize: 50,
        columnFilters: columns.map(col => ({
          column: col.name,
          operands: [],
          operator: null,
          sortOrder: null,
        } as ColumnFilter)),
      },
      loading: true,
    } as State);

  const modalsRef = useRef<any>();

  useEffect(() => {
    dispatch({type: 'SET_LOADING', loading: true});
    Promise.all(
      [
        fetchRecords(state.filter).then(resp => resp.data),
        ...columns
          .filter(c => c.type === 'SELECT')
          .map(c => c as SelectColumn)
          .map(c => {
            return c.fetchOptions?.().then(opts => ({
              column: c.name,
              options: opts,
            })) ?? ({
              column: c.name,
              options: c.options,
            })
          })])
      .then(results => {
        const [filterResponse, ...options] = results;
        dispatch({
          type: 'FETCH_ENTITIES_SUCCESS',
          rows: filterResponse.results,
          rowCount: filterResponse.resultCount,
          columnOptions: options,
        })
      }).catch(error => {
      dispatch({type: 'FETCH_ENTITIES_ERROR'});
      notifyError(error);
    });
  }, [state.filter]);

  const anyActiveFilter = (filters: ColumnFilter[]) => {
    return filters
      .filter(f =>
        f.sortOrder !== null ||
        f.operands.filter(o => o !== null && o !== undefined).length > 0)
      .length > 0;
  }

  const pageCount = calculatePageCount(state.rowCount, state.filter.pageSize);

  const handlePageChange = (pageNum: number) => {
    dispatch({type: 'FILTER_CHANGED', filter: {...state.filter, pageNum: pageNum}});
  }

  const handleRowClick = (row: Row, rowIdx: number) => {
    dispatch({type: 'ROW_CLICKED', rowIdx: rowIdx});
    modalsRef.current?.openModal(objectType, row.id);
  }

  return <div style={{width: 'fit-content'}}>
    {loadingOverlay &&
        <LoadingOverlay visible={state.loading}/>}
    <Flex direction="row" justify="space-between" align="center">
      <Flex direction="row" gap={2}>
        <Text size="sm">Pages:</Text>
        {[...Array(pageCount)].map((_, idx) => (
          <Badge key={idx}
                 onClick={() => handlePageChange(idx)}
                 size="md"
                 variant={idx === state.filter.pageNum ? 'filled' : 'default'}
                 style={{cursor: 'pointer'}}
          >
            {idx + 1}
          </Badge>
        ))}

        <Text size="sm" style={{paddingLeft: 10}}>
          Showing {state.rows.length}/{state.rowCount} records
        </Text>

        {state.loading && <Loader style={{paddingLeft: 10}} size={16}/>}
      </Flex>

      {customNewButton
        ? customNewButton((promise) => {
          dispatch({type: 'SET_LOADING', loading: true});
          promise()
            ?.then(() => dispatch({type: 'TRIGGER_FETCH'}))
            .catch(notifyError)
            .finally(() => dispatch({type: 'SET_LOADING', loading: false}));
        })
        : <Button size="xs"
                  onClick={() => modalsRef.current?.openModal(objectType, undefined)}
                  leftSection={<IconPlus size={20}/>}>
          New
        </Button>
      }
    </Flex>

    <Space h={10}/>

    <table className={"datatable"}>
      <thead>
      <tr>
        <th>#</th>
        {state.columns.map((col, colIdx) => (
          <th key={col.name}>
            <Flex justify="space-between" align="center" gap={2}>
              <span/>
              {col.label ?? col.name}
              {col.type === 'TEXT' &&
                  <FilterTextButton filter={state.filter.columnFilters[colIdx]}
                                    onChange={(filter) => dispatch({
                                      type: 'COLUMN_FILTER_CHANGED',
                                      columnFilter: filter
                                    })}
                                    disabled={state.loading || col.filterDisabled}/>}
              {col.type === 'NUMBER' &&
                  <FilterNumberButton filter={state.filter.columnFilters[colIdx]}
                                      onChange={(filter) => dispatch({
                                        type: 'COLUMN_FILTER_CHANGED',
                                        columnFilter: filter
                                      })}
                                      disabled={state.loading || col.filterDisabled}/>}
              {col.type === 'DATE' &&
                  <FilterDateButton filter={state.filter.columnFilters[colIdx]}
                                    onChange={(filter) => dispatch({
                                      type: 'COLUMN_FILTER_CHANGED',
                                      columnFilter: filter
                                    })}
                                    disabled={state.loading || col.filterDisabled}/>}
              {col.type === 'SELECT' &&
                  <FilterMultiSelectButton filter={state.filter.columnFilters[colIdx]}
                                           onChange={(filter) => dispatch({
                                             type: 'COLUMN_FILTER_CHANGED',
                                             columnFilter: filter
                                           })}
                                           options={(col as SelectColumn).options}
                                           disabled={state.loading || col.filterDisabled}/>}
            </Flex>
          </th>
        ))}
      </tr>
      </thead>

      <tbody>
      {anyActiveFilter(state.filter.columnFilters) &&
          <tr>
              <td>
                  <Flex>
                      <Tooltip label="Clear all filters">
                          <ActionIcon size="sm" variant="subtle"
                                      onClick={() => dispatch({type: 'CLEAR_FILTERS'})}
                                      disabled={state.loading}>
                              <IconFilterX size={16} stroke={1.5}/>
                          </ActionIcon>
                      </Tooltip>
                  </Flex>
              </td>
            {state.filter.columnFilters.map((filter, idx) =>
              <td key={idx}>
                <FilterValuePills filter={filter}
                                  column={state.columns[idx]}
                                  onChange={(filter) => dispatch({
                                    type: 'COLUMN_FILTER_CHANGED',
                                    columnFilter: filter
                                  })}/>
              </td>
            )}
          </tr>}
      {state.rows.map((row, idx) => (
        <tr key={idx}
            onClick={() => handleRowClick(row, idx)}
            className={idx === state.lastClickedRow ? 'datatable-last-row-clicked' : ''}
        >
          <td>{idx + 1}</td>
          {state.columns.map(col => (
            <td key={col.name} className={COLUMN_CSS_CLASS[col.type]}>
              {col.render
                ? col.render(row, col, (type, id) => modalsRef.current?.openModal(type, id))
                : row[col.name]}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>

    <Modals ref={modalsRef} onSuccess={() => dispatch({type: 'TRIGGER_FETCH'})}/>
  </div>
}