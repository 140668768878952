import {Button, Center, Grid} from "@mantine/core";
import {ApiInvoice} from "../../../../../utils/http/apiClient";
import {IconArrowLeft} from "@tabler/icons-react";
import React from "react";
import {DataSheet} from "../../../../../common/datasheet/DataSheet";
import {checkNetPlusTaxEqualGross, computeTotals} from "../utils";

interface Props {
  invoice: ApiInvoice
  setInvoice: (invoice: ApiInvoice) => void
}

interface Totals {
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export const InvoiceTotals = ({invoice, setInvoice}: Props) => {
  const computedTotals = computeTotals(invoice.lineItems);

  const totals = [{
    name: 'Total',
    netAmount: invoice.netAmount,
    taxAmount: invoice.taxAmount,
    grossAmount: invoice.grossAmount,
  } as Totals];

  const copyCalculatedTotals = () => {
    setInvoice({
      ...invoice,
      netAmount: computedTotals[0].netAmount,
      taxAmount: computedTotals[0].taxAmount,
      grossAmount: computedTotals[0].grossAmount,
    })
  }

  return <>
    <Grid align="center" justify="space-between">
      <Grid.Col span={5}>
        <Center>
          <DataSheet
            caption="Totals"
            showLineNumbers={false}
            columns={[
              {name: 'name', displayName: 'Name', type: 'TEXT', textAlign: 'right', readOnly: true},
              {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', textAlign: 'right'},
              {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', textAlign: 'right'},
              {name: 'grossAmount', displayName: 'Gross Amount', type: 'AMOUNT', textAlign: 'right'},
            ]}
            rows={totals}
            onRowsChanged={rows =>
              setInvoice({
                ...invoice,
                netAmount: rows[0]?.netAmount ? Number(rows[0].netAmount) : null,
                taxAmount: rows[0]?.taxAmount ? Number(rows[0].taxAmount) : null,
                grossAmount: rows[0]?.grossAmount ? Number(rows[0].grossAmount) : null
              })}
            rowErrorChecks={[
              checkNetPlusTaxEqualGross
            ]}
          />
        </Center>
      </Grid.Col>

      <Grid.Col span={2}>
        <Center>
          <Button variant={"light"} size={"xs"} leftSection={<IconArrowLeft/>} onClick={copyCalculatedTotals}>
            Copy
          </Button>
        </Center>
      </Grid.Col>

      <Grid.Col span={5}>
        <Center>
          <DataSheet
            caption="Calculated Totals"
            showLineNumbers={false}
            columns={[
              {name: 'name', displayName: 'Name', type: 'TEXT', textAlign: 'right', readOnly: true},
              {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', textAlign: 'right', readOnly: true},
              {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', textAlign: 'right', readOnly: true},
              {
                name: 'grossAmount',
                displayName: 'Gross Amount',
                type: 'AMOUNT',
                textAlign: 'right',
                readOnly: true
              },
            ]}
            rows={computedTotals}
            onRowsChanged={() => null}
            rowErrorChecks={[]}
          />
        </Center>
      </Grid.Col>
    </Grid>
  </>;
}