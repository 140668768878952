import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React from "react";
import {ApiClient, ApiTransactionFilterRequest, ObjectType} from "../../../../utils/http/apiClient";
import {Flex, Title} from "@mantine/core";
import {IconAbacus} from "@tabler/icons-react";
import {DocumentLink} from "../../../../common/DocumentLink";
import {Column, DataTable, SelectColumn} from "../../../../common/datatable/DataTable";
import {createFilterMap, TableFilter} from "../../../../common/datatable/filter/types";
import {formatDate} from "../../../../utils/date/dateUtils";
import {StatusBadge} from "../../../../common/StatusBadge";
import {formatAmount} from "../../../../utils/formatUtils";

export const TransactionListV2 = () => {
  const organisationId = useOrganisationId() ?? '';

  const columns = [
    {
      name: 'id',
      label: 'ID',
      type: 'TEXT',
      render: (record, column) =>
        (<DocumentLink type="TRANSACTION" id={record[column.name]}/>),
      filterDisabled: true
    },
    {
      name: 'bookingType',
      label: 'Booking Type',
      type: 'SELECT',
      options: [
        {label: 'FULL', value: 'FULL'},
        {label: 'PARTIAL', value: 'PARTIAL'},
      ],
      optionLookup: {},
      render: (record, column) =>
        <div style={{textAlign: 'center', justifyContent: 'center', display: 'flex'}}>
          <StatusBadge status={record[column.name]}/>
        </div>
    } as SelectColumn,
    {
      name: 'bookingDate',
      label: 'Booking Date',
      type: 'DATE',
      render: (record, column) => formatDate(record[column.name]),
    } as Column,
    {
      name: 'type',
      label: 'Type',
      type: 'SELECT',
      options: [
        {label: 'STARTING_BALANCE', value: 'STARTING_BALANCE'},
        {label: 'INCOMING_INVOICE', value: 'INCOMING_INVOICE'},
        {label: 'REGISTER_REPORT', value: 'REGISTER_REPORT'},
        {label: 'WORK_ORDER', value: 'WORK_ORDER'},
      ],
      optionLookup: {},
    } as SelectColumn,
    {
      name: 'documentId',
      label: 'Document',
      type: 'TEXT',
      render: (record, column, openModal) => {
        const value = record[column.name];
        const docType = record['type'] as ObjectType;
        return <DocumentLink type={'INCOMING_INVOICE'}
                             id={record[column.name]}
                             onClick={() => openModal?.(docType, value)}/>
      },
      filterDisabled: true,
    } as Column,
    {
      name: 'itemId',
      label: 'Item',
      type: 'SELECT',
      options: [],
      optionLookup: {},
      fetchOptions: () =>
        ApiClient.getItems(organisationId)
          .then(resp => resp.data.map(c => ({label: c.name, value: c.id}))),
      render: (record, column, openModal) => {
        const selectCol = column as SelectColumn;
        const value = record[column.name];
        return selectCol.optionLookup[value];
      },
    } as SelectColumn,
    {
      name: 'quantity',
      label: 'Quantity',
      type: 'NUMBER',
      render: (record, column) => formatAmount(record[column.name]),
    } as Column,
    {
      name: 'amount',
      label: 'Amount',
      type: 'NUMBER',
      render: (record, column) => formatAmount(record[column.name]),
    } as Column,
  ] as Column[];

  return <>
    <Flex direction="row" align="center">
      <IconAbacus size={35} stroke={1.5}/>
      <Title size={24}>
        Transactions
      </Title>
    </Flex>

    <DataTable objectType={'TRANSACTION'}
               columns={columns}
               fetchRecords={(filter: TableFilter) => {
                 const filterRequest = {
                   pageNum: filter.pageNum,
                   pageSize: filter.pageSize,
                 } as ApiTransactionFilterRequest;
                 const filterMap = createFilterMap(filter.columnFilters, columns);
                 Object.entries(filterMap)
                   .forEach(([key, value]) => {
                     filterRequest[key] = value
                   });
                 return ApiClient.getTransactionsByFilter(organisationId, filterRequest);
               }}
               customNewButton={() => <></>}
    />
  </>
}