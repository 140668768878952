import {ApiLink} from "../../../../utils/http/apiClient";
import {Flex, Table} from "@mantine/core";
import React from "react";
import {ImageActionButton} from "../../../../common/ImageActionButton";
import {IconLink, IconRefresh, IconTrashX, IconUnlink} from "@tabler/icons-react";
import {DocumentLink} from "../../../../common/DocumentLink";

interface Props {
  links: ApiLink[]
  handleUnlinkAndDeleteInvoice: (invoiceId: string) => void
  handleUnlink: (invoiceId: string) => void
  openModal: (objectType: string, objectId: string) => void
}

export const FileLinks = ({
                            links,
                            handleUnlink,
                            handleUnlinkAndDeleteInvoice,
                            openModal
                          }: Props) => {
  return <>
    <Table verticalSpacing={1}
           withTableBorder
           withColumnBorders
           highlightOnHover={true}
           captionSide="top"
           className={'datatable'}>
      <Table.Caption>
        Links
      </Table.Caption>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Object</Table.Th>
          <Table.Th>Type</Table.Th>
          <Table.Th>
            <Flex direction="row" align="center" justify="space-between">
              Actions
              <ImageActionButton toolTip={`Link to existing object`}
                                 icon={<IconLink size="1.5rem" stroke={1.5}/>}
                                 onClick={() => null}/>
            </Flex>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {links.length > 0
          ? links.map(link => (
            <Table.Tr>
              <Table.Td>
                <DocumentLink type={"INCOMING_INVOICE"} id={link.object2Id}
                              onClick={() => openModal(link.object2Type, link.object2Id)}/>
              </Table.Td>
              <Table.Td>
                {link.object2Type}
              </Table.Td>
              <Table.Td style={{align: 'center'}}>
                <ImageActionButton toolTip={`Unlink ${link.object2Type}`}
                                   icon={<IconUnlink size="1.5rem" stroke={1.5}/>}
                                   onClick={() => handleUnlink(link.object2Id)}
                                   confirmation={`Are you sure you want to unlink this ${link.object2Type}?`}/>
                <ImageActionButton toolTip={`Unlink and delete ${link.object2Type}`}
                                   icon={<IconTrashX size="1.5rem" stroke={1.5}/>}
                                   onClick={() => handleUnlinkAndDeleteInvoice(link.object2Id)}
                                   confirmation={`Are you sure you want to unlink and delete this ${link.object2Type}?`}/>
              </Table.Td>
            </Table.Tr>
          ))
          : <Table.Tr>
            <Table.Td colSpan={3} style={{textAlign: 'center'}}>
              Nothing is linked currently to this file.
            </Table.Td>
          </Table.Tr>}
      </Table.Tbody>
    </Table>
  </>
}