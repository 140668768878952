import {ApiExtractionLineItem, ApiInvoiceLineItem, ApiTax} from "../../../../../utils/http/apiClient";
import {emptyObject, nonEmptyObject, roundAmount, roundToX} from "../../../../../utils/objectUtils";

function multiplyExceptNaN(n1: number, n2: number, n3: number) {
  const nonNans = [n1, n2, n3].filter(n => !Number.isNaN(n));
  if (nonNans.length == 0) {
    return NaN;
  } else {
    return nonNans.reduce((curr, agg) => curr * agg, 1);
  }
}

export function mapInvoiceLineItems(lineItems: ApiInvoiceLineItem[],
                                    taxes: ApiTax[],
                                    discountInPercentage?: boolean,
                                    discountIncludedInAmounts?: boolean,
                                    amountIsNet?: boolean): ApiInvoiceLineItem[] {
  return lineItems.map(lineItem => {
    const li = {...lineItem};

    const qty1 = getNumberValueOrDefault(li.extQuantity1 ?? '', NaN);
    const qty2 = getNumberValueOrDefault(li.extQuantity2 ?? '', NaN);
    const qty3 = getNumberValueOrDefault(li.extQuantity3 ?? '', NaN);

    const mappedQty = li.extUnit1 && li.extUnit1 !== li.extUnit2
      ? roundToX(multiplyExceptNaN(qty1, qty2, qty3), 4)
      : roundToX(multiplyExceptNaN(qty2, qty3, NaN), 4);
    li.quantity = Number(mappedQty.toString());

    const taxRate = getNumberValueOrDefault(li.extTaxRate ?? '', Number.NaN);
    const tax = taxes.filter(t => t.rate * 100 === taxRate)[0];
    li.taxId = tax?.id;

    const amount = getNumberValueOrDefault(li.extAmount ?? '', Number.NaN);
    const discount = !discountIncludedInAmounts
      ? getNumberValueOrDefault(li.extDiscount ?? '', 0)
      : 0;

    if (amountIsNet) {
      const mappedNetAmount = discountInPercentage
        ? roundAmount(amount * (100 - discount) / 100)
        : roundAmount(amount - discount);
      const mappedGrossAmount = roundToX(mappedNetAmount * (100 + taxRate) / 100, 4);
      const mappedUnitPrice = roundToX(mappedNetAmount / mappedQty, 4);
      li.netAmount = Number(mappedNetAmount.toString());
      li.grossAmount = Number(mappedGrossAmount.toString());
      li.taxAmount = li.grossAmount - li.netAmount;
      li.unitPrice = Number(mappedUnitPrice.toString());
    } else {
      const mappedGrossAmount = discountInPercentage
        ? roundAmount(amount * (100 - discount) / 100)
        : roundAmount(amount - discount);
      const mappedNetAmount = roundToX(mappedGrossAmount / (100 + taxRate) * 100, 4);
      const mappedUnitPrice = roundToX(mappedNetAmount / mappedQty, 4);

      li.netAmount = Number(mappedNetAmount.toString());
      li.grossAmount = Number(mappedGrossAmount.toString());
      li.taxAmount = li.grossAmount - li.netAmount;
      li.unitPrice = Number(mappedUnitPrice.toString());
    }

    return li;
  });
}

function getNumberValueOrDefault(text: string, defaultValue: number): number {
  if (!text || text.trim() === "" || !isNumeric(text)) {
    return defaultValue;
  } else {
    return Number(cleanNumber(text)) ?? defaultValue;
  }
}

export const deriveUnitAndQuantity = (lineText: string) => {
  const qtyPattern = /(?<qty>[0-9]+([.,][0-9]+)?)/;
  const suffixPattern = /([^A-Z]+|$)/;
  const patterns = [
    {
      regex: new RegExp(qtyPattern.source + /\s*G/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*GR/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*GRAMA?/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*KG/.source + suffixPattern.source),
      unit: 'KG',
      multiplier: 1
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*L/.source + suffixPattern.source),
      unit: 'L',
      multiplier: 1
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*ML/.source + suffixPattern.source),
      unit: 'L',
      multiplier: 0.001
    },
    {
      regex: new RegExp(qtyPattern.source + /\sKOM/.source + suffixPattern.source),
      unit: 'KOM',
      multiplier: 1
    },
    {
      regex: new RegExp(qtyPattern.source + /\s*BOCA/.source + suffixPattern.source),
      unit: 'KOM',
      multiplier: 1
    },
  ];

  for (const pattern of patterns) {
    const match = pattern.regex.exec(lineText?.toUpperCase() ?? '');
    const group = match?.groups?.['qty'];
    if (group) {
      return {
        quantity: (Number(cleanNumber(group)) * pattern.multiplier).toString(),
        unit: pattern.unit
      }
    }
  }
  return {quantity: null, unit: null}
}

export const cleanNumber = (text: string) => {
  text = (text ?? '').toString().replaceAll(',', '.');
  const lastDot = text?.lastIndexOf('.');
  if (lastDot >= 0) {
    const leftPart = text.substring(0, lastDot).replaceAll('.', '');
    const rightPart = text.substring(lastDot);
    text = leftPart + (rightPart.length > 1 ? rightPart : '');
  }
  return text?.trim();
}

export const isNumericCheck = (text: string) => {
  return !isNumeric(text) ? `'${text}' is not a valid number` : null;
}

export const isNumeric = (text: string) => {
  text = cleanNumber(text);
  return !text || isFinite(Number(text));
}

export const isUnitCheck = (text: any) => {
  return !isUnit(text) ? `'${text}' is not a valid unit` : null;
}

export const isUnit = (text: any) => {
  const units = new Set(['', 'KO', 'KOM', 'KG', 'L']);
  return !text || (typeof (text) === 'string' && units.has(text?.toUpperCase() ?? ''));
}

export const isValidTaxLineRowCheck = (row: { [key: string]: string }) => {
  if (emptyObject(row)) {
    return null;
  }

  const rate = Number(cleanNumber(row['rate'])) / 100;
  const netAmount = Number(cleanNumber(row['netAmount']));
  const taxAmount = Number(cleanNumber(row['taxAmount']));
  const grossAmount = Number(cleanNumber(row['grossAmount']));

  const taxAmountCalc = roundAmount(rate * netAmount);
  const grossAmountCalc = roundAmount(netAmount + taxAmount);

  if (taxAmountCalc !== taxAmount) {
    return `Rate * NetAmount ≠ TaxAmount [${rate} * ${netAmount} = ${taxAmountCalc} ≠ ${taxAmount}; diff: ${roundToX(taxAmountCalc - taxAmount, 4)}]`;
  }

  if (grossAmountCalc !== grossAmount) {
    return `NetAmount + TaxAmount ≠ GrossAmount [${netAmount} + ${taxAmount} = ${grossAmountCalc} ≠ ${grossAmount}]`;
  }

  return null;
}

export const isValidTotalsRowCheck = (row: { [key: string]: string }) => {
  if (emptyObject(row)) {
    return null;
  }

  const netAmount = roundAmount(Number(cleanNumber(row['netAmount'])));
  const taxAmount = roundAmount(Number(cleanNumber(row['taxAmount'])));
  const grossAmount = roundAmount(Number(cleanNumber(row['grossAmount'])));

  const grossAmountCalc = roundAmount(netAmount + taxAmount);

  if (grossAmountCalc !== grossAmount) {
    return `NetAmount + TaxAmount ≠ GrossAmount [${netAmount} + ${taxAmount} = ${grossAmountCalc} ≠ ${grossAmount}; diff: ${grossAmountCalc - grossAmount}]`;
  }

  return null;
}


export const nonEmptyLineItemRequest = (item: ApiExtractionLineItem) => {
  const duplicate = {...item};
  Object.keys(duplicate).filter(key => key.startsWith("mapped")).forEach(key => delete duplicate[key]);
  return nonEmptyObject(duplicate);
}