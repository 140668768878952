import {httpClient} from "./httpClient";
import {AxiosResponse} from "axios";
import {getEnvironmentConfig} from "../../config";

export function getApiUrl(urlPath: string) {
  return getEnvironmentConfig().apiUrl + urlPath;
}

export function getJson<RESP>(urlPath: string): Promise<AxiosResponse<RESP>> {
  return httpClient.request({
    method: 'get',
    headers: {'Content-Type': 'application/json'},
    url: getApiUrl(urlPath),
    withCredentials: true
  });
}

export function getText<RESP>(urlPath: string): Promise<AxiosResponse<RESP>> {
  return httpClient.request({
    method: 'get',
    headers: {'Content-Type': 'text/plain'},
    url: getApiUrl(urlPath),
    withCredentials: true
  });
}

export function getPng<RESP>(urlPath: string): Promise<AxiosResponse<RESP>> {
  return httpClient.request({
    method: 'get',
    responseType: 'blob',
    headers: {'Content-Type': 'application/png'},
    url: getApiUrl(urlPath),
    withCredentials: true
  });
}

export function postJson<REQ, RESP>(urlPath: string, obj?: REQ): Promise<AxiosResponse<RESP>> {
  return httpClient.request({
    method: 'post',
    url: getApiUrl(urlPath),
    headers: {'Content-Type': 'application/json'},
    data: obj ? JSON.stringify(obj) : undefined,
    withCredentials: true
  });
}

export function postFile<RESP>(urlPath: string, file: File): Promise<AxiosResponse<RESP>> {
  const formData = new FormData();
  formData.append("file", file);
  return httpClient.request({
    method: 'post',
    url: getApiUrl(urlPath),
    headers: {'Content-Type': 'multipart/form-data'},
    data: formData,
    withCredentials: true
  });
}

export function deleteJson<REQ, RESP>(urlPath: string, obj?: REQ): Promise<AxiosResponse<RESP>> {
  return httpClient.request({
    method: 'delete',
    url: getApiUrl(urlPath),
    headers: {'Content-Type': 'application/json'},
    data: obj ? JSON.stringify(obj) : undefined,
    withCredentials: true
  });
}
