import {ApiClient, ApiLink, ObjectType} from "../utils/http/apiClient";
import {Flex, LoadingOverlay, Table} from "@mantine/core";
import {ImageActionButton} from "./ImageActionButton";
import {IconLink, IconUnlink} from "@tabler/icons-react";
import React, {useEffect, useRef, useState} from "react";
import {DocumentLink} from "./DocumentLink";
import {useOrganisationId} from "../hooks/useOrganisationId";
import {notifyError, notifySavedChanges} from "../utils/notificationUtils";
import {FileModal} from "../pages/private/organisation/files/FileModal";

interface Props {
  objectId?: string
  objectType?: string
  onLinkClick?: (objectId: string, objectType: ObjectType) => void
}

export const Links = ({objectId, objectType, onLinkClick}: Props) => {
  const organisationId = useOrganisationId();
  const [links, setLinks] = useState<ApiLink[]>([]);
  const [loading, setLoading] = useState(false);

  const modalRef = {
    file: useRef<any>(),
    incomingInvoice: useRef<any>(),
  };

  const fetchEntities = () => {
    if (organisationId && objectId && objectType) {
      setLoading(true);
      ApiClient.getLinks(organisationId, objectType, objectId)
        .then(resp => setLinks(resp.data))
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchEntities();
  }, [objectId]);

  const handleUnlink = (object2Id: string, object2Type: ObjectType) => {
    if (organisationId && objectId && objectType) {
      setLoading(true)
      const link = {
        organisationId: organisationId,
        object1Id: objectId,
        object1Type: objectType,
        object2Id: object2Id,
        object2Type: object2Type
      } as ApiLink;
      ApiClient.deleteLink(organisationId, link)
        .then(notifySavedChanges)
        .then(fetchEntities)
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  };

  const openModal = (objectType: ObjectType, objectId: string) => {
    switch (objectType) {
      case "FILE":
        modalRef.file.current?.openModal(objectId);
        break;
      case "INCOMING_INVOICE":
        modalRef.incomingInvoice.current?.openModal(objectId);
        break;
    }
  }

  return <>
    <LoadingOverlay visible={loading} />
    <Table verticalSpacing={1}
           withTableBorder
           withColumnBorders
           highlightOnHover={true}
           captionSide="top"
           className={'datatable'}
           style={{width: '100%'}}>
      <Table.Caption>
        Links
      </Table.Caption>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Object</Table.Th>
          <Table.Th>Type</Table.Th>
          <Table.Th>
            <Flex direction="row" align="center" justify="space-between">
              Actions
              {/*<ImageActionButton toolTip={`Link to existing object`}*/}
              {/*                   icon={<IconLink size="1.5rem" stroke={1.5}/>}*/}
              {/*                   onClick={() => null}/>*/}
            </Flex>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {links.length > 0
          ? links.map((link, idx) => (
            <Table.Tr key={idx} onClick={() => onLinkClick?.(link.object2Id, link.object2Type)}>
              <Table.Td>
                <DocumentLink type={link.object2Type} id={link.object2Id}
                              onClick={() => openModal(link.object2Type, link.object2Id)}
                />
              </Table.Td>
              <Table.Td>
                {link.object2Type}
              </Table.Td>
              <Table.Td style={{align: 'center'}}>
                <ImageActionButton toolTip={`Unlink ${link.object2Type}`}
                                   icon={<IconUnlink size="1.5rem" stroke={1.5}/>}
                                   onClick={() => handleUnlink(link.object2Id, link.object2Type)}
                                   confirmation={`Are you sure you want to unlink this ${link.object2Type}?`}/>
              </Table.Td>
            </Table.Tr>
          ))
          : <Table.Tr>
            <Table.Td colSpan={3} style={{textAlign: 'center'}}>
              Nothing is linked currently to this file.
            </Table.Td>
          </Table.Tr>}
      </Table.Tbody>
    </Table>

    <FileModal ref={modalRef.file} />
  </>;
};