import {Textarea} from "@mantine/core";
import React, {useEffect, useState} from "react";

interface Props {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

export const TextAreaInput = ({
                                value,
                                onChange,
                                disabled
                              }: Props) => {
  const [internalValue, setInternalValue] = useState<string>(value ?? '');

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return <Textarea size="sm" description="New comment" autosize={true}
                   disabled={disabled}
                   value={internalValue}
                   onChange={e => setInternalValue(e.target.value)}
                   onBlur={e => onChange(e.target.value)}/>;
}