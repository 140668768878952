import {deleteJson, getJson, getText, postFile, postJson} from "./httpUtils";
import {Currency} from "../currency";
import {SortOrder} from "../../common/datatable/filter/types";

// Auth
export interface LoginRequest {
  email: string
  password: string
}

export interface LoginResponse {
  accessToken: string
}

type UserRole = "USER" | "ADMIN";

// Users
export interface ApiUser {
  id: string
  organisationId: string
  displayName: string
  email: string
  active: boolean
  roles: UserRole[]
  createdAt: Date
  updatedAt: Date
}

export interface ApiUserSlim {
  id: string
  organisationId: string
  displayName: string
}

// Organisation
export interface ApiOrganisation {
  id: string
  name: string
  settings: ApiOrganisationSetting
}

export interface ApiOrganisationSetting {
  registrationNumber: string | null
  baseCurrency: string | null
  address: ApiAddress | null
  email: string | null
  phoneNumber: string | null
}

export interface ApiAddress {
  line1: string | null
  line2: string | null
  postalCode: string | null
  city: string | null
  country: string | null
}

// Taxes
export interface ApiTax {
  id: string
  organisationId: string
  name: string
  rate: number
  active: boolean
  createTime: Date
  updateTime: Date
}

export interface UpsertTaxRequest {
  name: string
  rate: number
  active: boolean
}

// Units
export interface ApiUnit {
  id: string
  organisationId: string
  name: string
  shortName: string
  active: boolean
}

export interface UpsertUnitRequest {
  name: string
  shortName: string
  active: boolean
}

// Items
export interface ApiItem {
  id: string
  categoryId: string
  organisationId: string
  name: string
  unitId: string
  sold: boolean
  stocked: boolean
  produced: boolean
  active: boolean
  bomItems: ApiBomItem[]
}

export interface UpsertItemRequest {
  name: string
  categoryId: string
  unitId: string
  sold: boolean
  stocked: boolean
  produced: boolean
  active: boolean
  bomItems: UpsertBomItemRequest[]
}

// Item Categories
export interface ApiItemCategory {
  id: string
  organisationId: string
  name: string
  active: boolean
}

export interface UpsertItemCategoryRequest {
  name: string
  active: boolean
}

// Bom Items
export interface ApiBomItem {
  id: string
  organisationId: string
  productId: string
  partId: string
  quantity: number
}

export interface UpsertBomItemRequest {
  partId: string
  quantity: number
}

// Contacts
export interface ApiContact {
  id: string
  organisationId: string
  name: string | null
  registrationNumber: string | null
  address: ApiAddress | null
  iban: string | null
  email: string | null
  phoneNumber: string | null
  supplier: boolean | null
  customer: boolean | null
  active: boolean | null
  createTime: Date
  updateTime: Date
  actorId: string
}

export interface UpsertContactRequest {
  name: string | null
  registrationNumber: string | null
  address: string | null
  iban: string | null
  email: string | null
  phoneNumber: string | null
  supplier: boolean | null
  customer: boolean | null
  active: boolean | null
}

// Incoming invoices
export interface ApiIncomingInvoice {
  id: string
  organisationId: string
  supplierId: string | null
  invoiceNumber: string | null
  currency: Currency | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  bookingDate: string | null
  bookingStatus: BookingStatus
  lineItems: ApiIncomingInvoiceLineItem[]
  taxItems: ApiIncomingInvoiceTaxItem[]
}

export interface ApiIncomingInvoiceLineItem {
  id: string
  organisationId: string
  incomingInvoiceId: string
  itemId: string | undefined
  taxId: string | undefined
  text: string | undefined
  unitPrice: number | undefined
  quantity: number | undefined
  netAmount: number | undefined
  taxAmount: number | undefined
  grossAmount: number | undefined
}

export interface ApiIncomingInvoiceTaxItem {
  id: string
  organisationId: string
  incomingInvoiceId: string
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

export interface ApiUpsertIncomingInvoiceRequest {
  supplierId: string | null
  invoiceNumber: string | null
  currency: string | null
  netAmount: string | null
  taxAmount: string | null
  grossAmount: string | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  lineItems: ApiUpsertIncomingInvoiceLineItemRequest[]
  taxItems: ApiUpsertIncomingInvoiceTaxItemRequest[]
  bookingDate: string | null
  bookingStatus: BookingStatus
}

export interface ApiUpsertIncomingInvoiceLineItemRequest {
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | undefined
  taxAmount: number | undefined
  grossAmount: number | undefined
}

export interface ApiUpsertIncomingInvoiceTaxItemRequest {
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

export interface ApiIncomingInvoiceFindSimilarRequest {
  supplierId: string | null
  invoiceNumber: string | null
  issueDate: string | null
}

// Registers
export interface ApiRegister {
  id: string
  organisationId: string
  name: string | null
  active: boolean | null
  createTime: Date | null
  updateTime: Date | null
}

export interface ApiRegisterReport {
  id: string
  organisationId: string
  registerId: string | null
  date: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  bookingDate: string | null
  bookingStatus: BookingStatus
  lineItems: ApiRegisterReportLineItem[]
  taxItems: ApiRegisterReportTaxItem[]

  [key: string]: any
}

export interface ApiRegisterReportLineItem {
  id: string
  organisationId: string
  registerReportId: string
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export interface ApiRegisterReportTaxItem {
  id: string
  organisationId: string
  registerReportId: string
  taxId: string | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export interface ApiUpsertRegisterReportRequest {
  registerId: string | null
  date: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  lineItems: ApiUpsertRegisterReportLineItemRequest[]
  taxItems: ApiUpsertRegisterReportTaxItemRequest[]
  bookingDate: string | null
  bookingStatus: BookingStatus
}

export interface ApiUpsertRegisterReportLineItemRequest {
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

export interface ApiUpsertRegisterReportTaxItemRequest {
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

// Files
export interface ApiFile {
  id: string
  organisationId: string
  name: string
  type: string
  size: number
  status: FileStatus
  ocrResponse: ApiOcrResponse
}

export interface ApiOcrResponse {
  pages: ApiPage[]
}

export interface ApiPage {
  boxes: ApiTextBox[]
  width: number
  height: number
}

export interface ApiTextBox {
  text: string
  box: ApiBox
}

export interface ApiBox {
  l: number
  t: number
  w: number
  h: number
}

export type ObjectType =
  | 'INCOMING_INVOICE'
  | 'REGISTER_REPORT'
  | 'WORK_ORDER'
  | 'FILE'
  | 'CONTACT'
  | 'TRANSACTION'
  | 'ITEM';
export type DocumentType = 'INCOMING_INVOICE' | 'REGISTER_REPORT';
export type InvoiceType = 'INCOMING_INVOICE' | 'REGISTER_REPORT';
export type FileStatus = 'CREATING' | 'NEW' | 'BLOCKED' | 'LINKED';
export type InvoiceStatus = 'CREATING' | 'DRAFT' | 'BOOKED' | 'PARTIAL';
export type BookingStatus = 'DRAFT' | 'BOOKED' | 'PARTIAL';
export type BookingType = 'PARTIAL' | 'FULL';
export type BalancePeriod = 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR';
export type ImageAction =
  | "ROTATE_90_CW"
  | "ROTATE_90_CCW"
  | "ROTATE_1_CW"
  | "ROTATE_1_CCW"
  | "FLIP_HORIZONTAL"
  | "FLIP_VERTICAL";

export interface ApiExtraction {
  extractionType: DocumentType | null
  supplierId: string | null
  invoiceNumber: string | null
  netAmount: string | null
  taxAmount: string | null
  grossAmount: string | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  lineItems: ApiExtractionLineItem[]
  taxItems: ApiExtractionTaxItem[]
  discountInPercentage: boolean
  discountIncludedInAmounts: boolean
}

export interface ApiExtractionLineItem {
  // Directly extracted fields
  text: string
  quantity1: string
  quantity2: string
  quantity3: string
  unit1: string
  unit2: string
  discount: string
  tax: string
  netAmount: string // qty * price * disc.
  grossAmount: string // qty * price * disc * (1 + tax)
  // Mapped fields
  mappedItemId: string
  mappedQuantity: string
  mappedUnit: string
  mappedUnitPrice: string
  mappedNetAmount: string
  mappedGrossAmount: string

  [key: string]: string
}

export interface ApiExtractionTaxItem {
  rate: string
  netAmount: string
  taxAmount: string
  grossAmount: string

  [key: string]: string
}

export interface ApiBalances {
  items: Record<string, ApiBalance[]>
  balancePeriod: BalancePeriod
}

export interface ApiBalance {
  organisationId: string
  itemId: string
  date: string
  amount: number
  quantity: number
}

export interface ApiTransaction {
  id: string
  type: DocumentType
  organisationId: string
  documentId: string
  itemId: string
  amount: number
  quantity: number
  bookingDate: string
  bookingType: BookingType
}

// Invoices
// Incoming invoices
export interface ApiInvoice {
  id: string
  organisationId: string
  type: InvoiceType
  objectId: string | null
  invoiceNumber: string | null
  currency: Currency | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  bookingDate: string | null
  bookingStatus: BookingStatus
  lineItems: ApiInvoiceLineItem[]
  taxItems: ApiInvoiceTaxItem[]
  files: ApiFile[]
  extAmountsAreNet: boolean
  extDiscountInPercentage: boolean
  extDiscountIncludedInAmounts: boolean
}

export interface ApiInvoiceLineItem {
  id: string
  organisationId: string
  invoiceId: string
  itemId: string | undefined
  taxId: string | undefined
  text: string | undefined
  unitPrice: number | undefined // Used only as a derived column
  quantity: number | undefined
  netAmount: number | undefined
  taxAmount: number | undefined
  grossAmount: number | undefined
  // Extraction fields
  extQuantity1: string | undefined;
  extQuantity2: string | undefined;
  extQuantity3: string | undefined;
  extUnit1: string | undefined;
  extUnit2: string | undefined;
  extDiscount: string | undefined;
  extTaxRate: string | undefined;
  extAmount: string | undefined;

  [key: string]: any
}

export interface ApiInvoiceTaxItem {
  id: string
  organisationId: string
  invoiceId: string
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export interface ApiUpsertInvoiceRequest {
  objectId: string | null
  invoiceNumber: string | null
  currency: string | null
  netAmount: string | null
  taxAmount: string | null
  grossAmount: string | null
  issueDate: string | null
  dueDate: string | null
  deliveryDate: string | null
  lineItems: ApiUpsertInvoiceLineItemRequest[]
  taxItems: ApiUpsertInvoiceTaxItemRequest[]
  bookingDate: string | null
  bookingStatus: BookingStatus
  extAmountsAreNet: boolean
  extDiscountInPercentage: boolean
  extDiscountIncludedInAmounts: boolean
}

export interface ApiUpsertInvoiceLineItemRequest {
  itemId: string | null
  taxId: string | null
  text: string | null
  quantity: number | null
  netAmount: number | undefined
  taxAmount: number | undefined
  grossAmount: number | undefined
  extQuantity1: string | undefined;
  extQuantity2: string | undefined;
  extQuantity3: string | undefined;
  extUnit1: string | undefined;
  extUnit2: string | undefined;
  extDiscount: string | undefined;
  extTaxRate: string | undefined;
  extAmount: string | undefined;
}

export interface ApiUpsertInvoiceTaxItemRequest {
  taxId: string | null
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null
}

export interface ApiInvoiceFindSimilarRequest {
  supplierId: string | null
  invoiceNumber: string | null
  issueDate: string | null
}

export interface ApiLink {
  organisationId: string
  object1Id: string
  object1Type: ObjectType
  object2Id: string
  object2Type: ObjectType
}

export interface ApiUpsertCommentRequest {
  objectId: string | null
  objectType: string | null
  text: string | null
}

export interface ApiComment {
  id: string | null
  organisationId: string | null
  objectId: string | null
  objectType: string | null
  author: string | null
  text: string | null
  createTime: Date
  updateTime: Date
}

export type ApiSelectFilter = {
  values: any[]
}

export type ApiTextFilter = {
  text: string
  sortOrder: SortOrder
}

export type ApiRangeFilter = {
  from: any | null
  to: any | null
  sortOrder: SortOrder
}

export type ApiFilter = ApiSelectFilter | ApiTextFilter | ApiRangeFilter

export interface ApiInvoiceFilterRequest {
  bookingStatus: ApiFilter | null
  bookingDate: ApiFilter | null
  objectId: ApiFilter | null
  issueDate: ApiFilter | null
  invoiceNumber: ApiFilter | null
  grossAmount: ApiFilter | null

  pageNum: number
  pageSize: number

  [key: string]: any
}

export interface ApiFileFilterRequest {
  status: ApiFilter | null
  name: ApiFilter | null
  type: ApiFilter | null
  size: ApiFilter | null

  pageNum: number
  pageSize: number

  [key: string]: any
}

export interface ApiTransactionFilterRequest {
  type: ApiFilter | null
  bookingType: ApiFilter | null
  bookingDate: ApiFilter | null
  documentId: ApiFilter | null
  itemId: ApiFilter | null
  quantity: ApiFilter | null
  amount: ApiFilter | null

  pageNum: number
  pageSize: number

  [key: string]: any
}

export interface ApiFilterResponse<T> {
  results: T[]
  resultCount: number
  pageNum: number
  pageSize: number
}

export class ApiClient {
  // Auth
  static doLogin(email: String, password: String) {
    const request = {email, password} as LoginRequest;
    return postJson<LoginRequest, LoginResponse>('/auth/login', request);
  }

  static doRefresh() {
    return getJson<LoginResponse>('/auth/refresh');
  }

  static doLogout() {
    return deleteJson<void, void>('/auth/login');
  }

  // Users
  static getCurrentUser() {
    return getJson<ApiUser>(`/users/current`);
  }

  static getUsersByOrganisation(organisationId: string) {
    return getJson<ApiUser[]>(`/users/by-organisation/${organisationId}`);
  }

  // Organisation
  static getOrganisations() {
    return getJson<ApiOrganisation[]>(`/organisations`);
  }

  static getOrganisation(organisationId: string) {
    return getJson<ApiOrganisation>(`/organisations/${organisationId}`);
  }

  static updateOrganisationSettings(organisationId: string, request: ApiOrganisationSetting) {
    return postJson<ApiOrganisationSetting, ApiOrganisation>(`/organisations/${organisationId}/settings`, request);
  }

  // Taxes
  static getTaxes(organisationId: string) {
    return getJson<ApiTax[]>(`/organisations/${organisationId}/taxes`);
  }

  static createTax(organisationId: string, request: UpsertTaxRequest) {
    return postJson<UpsertTaxRequest, ApiTax>(`/organisations/${organisationId}/taxes`, request);
  }

  static updateTax(organisationId: string, taxId: string, request: UpsertTaxRequest) {
    return postJson<UpsertTaxRequest, ApiTax>(`/organisations/${organisationId}/taxes/${taxId}`, request);
  }

  // Units
  static getUnits(organisationId: string) {
    return getJson<ApiUnit[]>(`/organisations/${organisationId}/units`);
  }

  static createUnit(organisationId: string, request: UpsertUnitRequest) {
    return postJson<UpsertUnitRequest, ApiUnit>(`/organisations/${organisationId}/units`, request);
  }

  static updateUnit(organisationId: string, taxId: string, request: UpsertUnitRequest) {
    return postJson<UpsertUnitRequest, ApiUnit>(`/organisations/${organisationId}/units/${taxId}`, request);
  }

  // Items
  static getItems(organisationId: string) {
    return getJson<ApiItem[]>(`/organisations/${organisationId}/items`);
  }

  static createItem(organisationId: string, request: UpsertItemRequest) {
    return postJson<UpsertItemRequest, ApiItem>(`/organisations/${organisationId}/items`, request);
  }

  static updateItem(organisationId: string, itemId: string, request: UpsertItemRequest) {
    return postJson<UpsertItemRequest, ApiItem>(`/organisations/${organisationId}/items/${itemId}`, request);
  }

  // Item Categories
  static getItemCategories(organisationId: string) {
    return getJson<ApiItemCategory[]>(`/organisations/${organisationId}/categories`);
  }

  static createItemCategory(organisationId: string, request: UpsertItemCategoryRequest) {
    return postJson<UpsertItemCategoryRequest, ApiItemCategory>(`/organisations/${organisationId}/categories`, request);
  }

  static updateItemCategory(organisationId: string, categoryId: string, request: UpsertItemCategoryRequest) {
    return postJson<UpsertItemCategoryRequest, ApiItemCategory>(`/organisations/${organisationId}/categories/${categoryId}`, request);
  }

  // BOM Items
  static getBomItems(organisationId: string, itemId: string) {
    return getJson<ApiBomItem[]>(`/organisations/${organisationId}/items/${itemId}/bom-items`);
  }

  // Contacts
  static getContacts(organisationId: string) {
    return getJson<ApiContact[]>(`/organisations/${organisationId}/contacts`);
  }

  static getContact(organisationId: string, contactId: string) {
    return getJson<ApiContact>(`/organisations/${organisationId}/contacts/${contactId}`);
  }

  static createContact(organisationId: string, request: UpsertContactRequest) {
    return postJson<UpsertContactRequest, ApiContact>(`/organisations/${organisationId}/contacts`, request);
  }

  static updateContact(organisationId: string, partyId: string, request: UpsertContactRequest) {
    return postJson<UpsertContactRequest, ApiContact>(`/organisations/${organisationId}/contacts/${partyId}`, request);
  }

  // Incoming Invoices
  static getIncomingInvoices(organisationId: string) {
    return getJson<ApiIncomingInvoice[]>(`/organisations/${organisationId}/incoming-invoices`);
  }

  static getIncomingInvoice(organisationId: string, incomingInvoiceId: string) {
    return getJson<ApiIncomingInvoice>(`/organisations/${organisationId}/incoming-invoices/${incomingInvoiceId}`);
  }

  static findSimilarIncomingInvoices(organisationId: string, request: ApiIncomingInvoiceFindSimilarRequest) {
    return postJson<ApiIncomingInvoiceFindSimilarRequest, ApiIncomingInvoice[]>(`/organisations/${organisationId}/incoming-invoices/find-similar`, request);
  }

  static createIncomingInvoice(organisationId: string, request: ApiUpsertIncomingInvoiceRequest) {
    return postJson<ApiUpsertIncomingInvoiceRequest, ApiIncomingInvoice>(`/organisations/${organisationId}/incoming-invoices`, request);
  }

  static updateIncomingInvoice(organisationId: string, incomingInvoiceId: string, request: ApiUpsertIncomingInvoiceRequest) {
    return postJson<ApiUpsertIncomingInvoiceRequest, ApiIncomingInvoice>(`/organisations/${organisationId}/incoming-invoices/${incomingInvoiceId}`, request);
  }

  // Invoices
  static getInvoices(organisationId: string, invoiceType: InvoiceType) {
    return getJson<ApiInvoice[]>(`/organisations/${organisationId}/invoices/${invoiceType}`);
  }

  static getInvoice(organisationId: string, invoiceType: InvoiceType, invoiceId: string) {
    return getJson<ApiInvoice>(`/organisations/${organisationId}/invoices/${invoiceType}/${invoiceId}`);
  }

  static findSimilarInvoices(organisationId: string, invoiceType: InvoiceType, request: ApiInvoiceFindSimilarRequest) {
    return postJson<ApiInvoiceFindSimilarRequest, ApiInvoice[]>(`/organisations/${organisationId}/invoices/{invoiceType}/find-similar`, request);
  }

  static getInvoicesByFilter(organisationId: string, invoiceType: InvoiceType, request: ApiInvoiceFilterRequest) {
    return postJson<ApiInvoiceFilterRequest, ApiFilterResponse<ApiInvoice>>(`/organisations/${organisationId}/invoices/${invoiceType}/filter`, request);
  }

  static createInvoice(organisationId: string, invoiceType: InvoiceType, request: ApiUpsertInvoiceRequest) {
    return postJson<ApiUpsertInvoiceRequest, ApiInvoice>(`/organisations/${organisationId}/invoices/${invoiceType}`, request);
  }

  static updateInvoice(organisationId: string, invoiceType: InvoiceType, invoiceId: string, request: ApiUpsertInvoiceRequest) {
    return postJson<ApiUpsertInvoiceRequest, ApiInvoice>(`/organisations/${organisationId}/invoices/${invoiceType}/${invoiceId}`, request);
  }

  static deleteInvoice(organisationId: string, invoiceType: InvoiceType, invoiceId: string,) {
    return deleteJson<void, void>(`/organisations/${organisationId}/invoices/${invoiceType}/${invoiceId}`);
  }


  // Registers
  static getRegister(organisationId: string, registerId: string) {
    return getJson<ApiRegister>(`/organisations/${organisationId}/registers/${registerId}`);
  }

  static createRegister(organisationId: string, register: ApiRegister) {
    return postJson<ApiRegister, ApiRegister>(`/organisations/${organisationId}/registers`, register);
  }

  static updateRegister(organisationId: string, registerId: string, request: ApiRegister) {
    return postJson<ApiRegister, ApiRegister>(`/organisations/${organisationId}/registers/${registerId}`, request);
  }

  static getRegisters(organisationId: string) {
    return getJson<ApiRegister[]>(`/organisations/${organisationId}/registers`);
  }

  static getRegisterReports(organisationId: string) {
    return getJson<ApiRegisterReport[]>(`/organisations/${organisationId}/register-reports`);
  }

  static getRegisterReport(organisationId: string, registerReportId: string) {
    return getJson<ApiRegisterReport>(`/organisations/${organisationId}/register-reports/${registerReportId}`);
  }

  static createRegisterReport(organisationId: string, request: ApiUpsertRegisterReportRequest) {
    return postJson<ApiUpsertRegisterReportRequest, ApiRegisterReport>(`/organisations/${organisationId}/register-reports`, request);
  }

  static updateRegisterReport(organisationId: string, registerReportId: string, request: ApiUpsertRegisterReportRequest) {
    return postJson<ApiUpsertRegisterReportRequest, ApiRegisterReport>(`/organisations/${organisationId}/register-reports/${registerReportId}`, request);
  }

  // Links
  static getLinks(organisationId: string, objectType: string, objectId: string) {
    return getJson<ApiLink[]>(`/organisations/${organisationId}/links/${objectType}/${objectId}`);
  }

  static deleteLink(organisationId: string, request: ApiLink) {
    return deleteJson<ApiLink, void>(`/organisations/${organisationId}/links`, request);
  }


  // Comment
  static getComments(organisationId: string, objectType: string, objectId: string) {
    return getJson<ApiComment[]>(`/organisations/${organisationId}/comments/find-by-object/${objectType}/${objectId}`);
  }

  static createComment(organisationId: string, request: ApiUpsertCommentRequest) {
    return postJson<ApiUpsertCommentRequest, ApiComment>(`/organisations/${organisationId}/comments`, request);
  }

  static updateComment(organisationId: string, commentId: string, request: ApiUpsertCommentRequest) {
    return postJson<ApiUpsertCommentRequest, ApiComment>(`/organisations/${organisationId}/comments/${commentId}`, request);
  }

  static deleteComment(organisationId: string, commentId: string) {
    return deleteJson<void, void>(`/organisations/${organisationId}/comments/${commentId}`);
  }

  // Files
  static getFile(organisationId: string, fileId: string) {
    return getJson<ApiFile>(`/organisations/${organisationId}/files/${fileId}`);
  }

  static getFiles(organisationId: string) {
    return getJson<ApiFile[]>(`/organisations/${organisationId}/files`);
  }

  static getFilesByFilter(organisationId: string, request: ApiFileFilterRequest) {
    return postJson<ApiFileFilterRequest, ApiFilterResponse<ApiInvoice>>(`/organisations/${organisationId}/files/filter`, request);
  }

  static getFilePageImage(organisationId: string, fileId: string, pageNum: number) {
    return getText<string>(`/organisations/${organisationId}/files/${fileId}/pages/${pageNum}`);
  }

  static editFilePageImage(organisationId: string, fileId: string, pageNum: number, action: ImageAction) {
    return postJson<void, void>(`/organisations/${organisationId}/files/${fileId}/pages/${pageNum}/edit/${action}`);
  }

  static uploadFile(organisationId: string, file: File) {
    return postFile<ApiFile>(`/organisations/${organisationId}/files`, file);
  }

  static updateFileStatus(organisationId: string, fileId: string, status: FileStatus) {
    return postJson(`/organisations/${organisationId}/files/${fileId}/status/${status}`);
  }

  static createIncomingInvoiceFromFile(organisationId: string, fileId: string) {
    return postJson<void, void>(`/organisations/${organisationId}/files/${fileId}/incoming-invoice/create`);
  }

  static unlinkInvoiceFromFile(organisationId: string, fileId: string, invoiceId: string) {
    return postJson<void, void>(`/organisations/${organisationId}/files/${fileId}/incoming-invoice/${invoiceId}/unlink`);
  }

  static unlinkAndDeleteInvoiceFromFile(organisationId: string, fileId: string, invoiceId: string) {
    return postJson<void, void>(`/organisations/${organisationId}/files/${fileId}/incoming-invoice/${invoiceId}/unlink-and-delete`);
  }

  static deleteFile(organisationId: string, fileId: string) {
    return deleteJson<void, void>(`/organisations/${organisationId}/files/${fileId}`);
  }

  // Balances
  static getBalances(organisationId: string, startDate: string | null, endDate: string, bookingTypes: BookingType[]) {
    const requestVars =
      `bookingTypes=${bookingTypes.join(',')}` +
      `&endDate=${endDate}` +
      (startDate !== null
        ? `&startDate=${startDate}`
        : `&startDate=${endDate}`);
    return getJson<ApiBalances>(`/organisations/${organisationId}/balances?${requestVars}`);
  }

  // Transactions
  static getTransactions(organisationId: string, startDate: string | null, endDate: string, bookingTypes: BookingType[]) {
    const requestVars =
      `bookingTypes=${bookingTypes.join(',')}` +
      `&endDate=${endDate}` +
      (startDate !== null
        ? `&startDate=${startDate}`
        : `&startDate=${endDate}`);
    return getJson<ApiTransaction[]>(`/organisations/${organisationId}/transactions?${requestVars}`);
  }

  static getTransactionsByFilter(organisationId: string, request: ApiTransactionFilterRequest) {
    return postJson<ApiTransactionFilterRequest, ApiFilterResponse<ApiTransaction>>(`/organisations/${organisationId}/transactions/filter`, request);
  }

}