import {ActionIcon, Tooltip} from "@mantine/core";
import React from "react";

interface Props {
  toolTip: string,
  icon: React.ReactNode
  onClick?: () => void
  confirmation?: string
  disabled?: boolean
}

export const ImageActionButton = ({toolTip, icon, onClick, confirmation, disabled}: Props) => {
  const handleClick = () => {
    if (confirmation && window.confirm(confirmation)) {
      onClick?.();
    } else {
      onClick?.();
    }
  }

  return <Tooltip label={toolTip}>
    <ActionIcon variant="subtle" tabIndex={-1} onClick={handleClick} disabled={disabled}>
      {icon}
    </ActionIcon>
  </Tooltip>;
}