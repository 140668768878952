import {NumberInput as MantineNumberInput} from "@mantine/core";
import React, {memo, useEffect, useRef, useState} from "react";

interface PositiveAmountInputProps {
  value: number | null
  onChange: (value: number | null) => void;
  label?: string
  placeholder?: string
  disabled?: boolean
  nonNegative?: boolean
}

export const NumberInput = memo(({
                                   label = "",
                                   placeholder = "12.345",
                                   value,
                                   disabled = false,
                                   onChange,
                                   nonNegative
                                 }: PositiveAmountInputProps) => {
  const [internalValue, setInternalValue] = useState<number | string>(value ?? '');
  const ref = useRef<any>();

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  const handleBlur = (newValue: string | number) => {
    setInternalValue(newValue);
    if (typeof newValue === 'number') {
      onChange(newValue);
    } else {
      onChange(null);
    }
  }

  const handleChange = (newValue: string | number) => {
    setInternalValue(newValue);
    if (ref.current) {
      // ref.current.style.width = Math.max(newValue.toString().length + 5, 5) + "ch";
      // ref.current.style.width = "30ch";
    }
  }

  return <MantineNumberInput label={label}
                             placeholder={placeholder}
                             min={nonNegative ? 0 : -1_000_000_000}
                             max={1_000_000_000}
                             disabled={disabled}
                             thousandSeparator=","
                             hideControls
                             value={internalValue}
    // decimalScale={4}
    // fixedDecimalScale
                             onChange={handleChange}
                             onBlur={() => handleBlur(internalValue)}  // Performance trick, only update onBlur
                             ref={ref}
                             styles={{
                               input: {
                                 minHeight: '24px',
                                 maxHeight: '24px',
                               }
                             }}
  />
});