import {ActionIcon, Button, Checkbox, Dialog, Flex, Grid, Modal, NavLink, Popover, Select, Text} from "@mantine/core";
import {IconFilterCog} from "@tabler/icons-react";
import React, {useState, MouseEvent as ReactMouseEvent, useEffect, useRef} from "react";
import {TextInput} from "../../TextInput";
import {ColumnFilter, SortOrder} from "./types";
import {SortOrderSelect} from "./SortOrderSelect";

interface FilterButtonProps {
  filter: ColumnFilter
  onChange: (filter: ColumnFilter) => void
  disabled?: boolean
}

export const FilterTextButton = ({
                                   filter,
                                   onChange,
                                   disabled
                                 }: FilterButtonProps) => {

  const [internalFilter, setInternalFilter] = useState<ColumnFilter>(filter);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setInternalFilter(filter);
  }, [filter]);

  const handleOnClose = () => {
    setInternalFilter(filter); // Reset if not 'Applied'
  }

  const handleApply = () => {
    onChange(internalFilter);
    setOpened(false);
  }

  const handleClear = () => {
    onChange({
      ...filter,
      operator: 'CONTAINS',
      operands: [],
      sortOrder: null
    });
    setOpened(false);
  }

  return <Popover width={300}
                  trapFocus={true}
                  position="bottom-start"
                  opened={opened}
                  onChange={setOpened}
                  onClose={handleOnClose}>
    <Popover.Target>
      <ActionIcon size="sm" variant="subtle" onClick={() => setOpened(true)} disabled={disabled}>
        <IconFilterCog size={24} stroke={1.5}/>
      </ActionIcon>
    </Popover.Target>

    <Popover.Dropdown className="filter-popover">
      <Grid align="center">
        <Grid.Col span={2}>
          <Text size="sm">Text:</Text>
        </Grid.Col>
        <Grid.Col span={10}>
          <TextInput value={internalFilter.operands[0]}
                     onChange={value =>
                       setInternalFilter({
                         ...internalFilter,
                         operands: [value]
                       })}
                     autofocus={true}/>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm">Sort:</Text>
        </Grid.Col>
        <Grid.Col span={10}>
          <SortOrderSelect value={internalFilter.sortOrder}
                           onChange={v => setInternalFilter({...internalFilter, sortOrder: v})}/>
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex direction="row" justify="space-around">
            <Button size="xs" onClick={handleApply}>
              Apply
            </Button>
            <Button size="xs" variant="outline" onClick={handleClear}>
              Clear
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Popover.Dropdown>
  </Popover>
}