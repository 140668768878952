import {Button, Center, Grid} from "@mantine/core";
import {ApiInvoiceLineItem, ApiInvoiceTaxItem, ApiTax} from "../../../../../utils/http/apiClient";
import {IconArrowLeft} from "@tabler/icons-react";
import React from "react";
import {Option} from "../../../../../common/datasheet/input/DataSheetSelect";
import {DataSheet} from "../../../../../common/datasheet/DataSheet";
import {checkNetPlusTaxEqualGross, computeTaxItems} from "../utils";

interface Props {
  vatItems: ApiInvoiceTaxItem[]
  lineItems: ApiInvoiceLineItem[]
  onVatItemsChange: (vatItems: ApiInvoiceTaxItem[]) => void
  taxes: ApiTax[]
}

export const InvoiceVatItems = ({vatItems, onVatItemsChange, lineItems, taxes}: Props) => {
  const taxItems = vatItems;
  const computedTaxItems = computeTaxItems(lineItems);
  const taxOptions = taxes.map(t => ({value: t.id, label: `${t.rate * 100}%`} as Option));

  const copyCalculatedTaxItems = () => {
    const copiedTaxItems = computedTaxItems.map(taxItem => ({
      taxId: taxItem.taxId,
      netAmount: taxItem.netAmount,
      taxAmount: taxItem.taxAmount,
      grossAmount: taxItem.grossAmount,
    } as ApiInvoiceTaxItem));
    onVatItemsChange(copiedTaxItems);
  }

  return <>
    <Grid align="center" justify="space-between">
      <Grid.Col span={5}>
        <Center>
          <DataSheet
            caption="VAT Items"
            showLineNumbers={false}
            columns={[
              {
                name: 'taxId',
                displayName: 'Tax',
                type: 'SELECT',
                textAlign: 'right',
                selectOptions: taxOptions,
              },
              {
                name: 'netAmount',
                displayName: 'Net Amount',
                type: 'AMOUNT',
                textAlign: 'right',
              },
              {
                name: 'taxAmount',
                displayName: 'Tax Amount',
                type: 'AMOUNT',
                textAlign: 'right',
              },
              {
                name: 'grossAmount',
                displayName: 'Gross Amount',
                type: 'AMOUNT',
                textAlign: 'right',
              },
            ]}
            rows={taxItems}
            onRowsChanged={(rows) => onVatItemsChange(rows as ApiInvoiceTaxItem[])}
            rowErrorChecks={[
              checkNetPlusTaxEqualGross
            ]}/>
        </Center>
      </Grid.Col>

      <Grid.Col span={2}>
        <Center>
          <Button variant={"light"} size={"xs"} leftSection={<IconArrowLeft/>}
                  onClick={copyCalculatedTaxItems}>
            Copy
          </Button>
        </Center>
      </Grid.Col>

      <Grid.Col span={5}>
        <Center>
          <DataSheet
            caption="Calculated VAT Items"
            showLineNumbers={false}
            columns={[
              {
                name: 'taxId',
                displayName: 'Tax',
                type: 'SELECT',
                textAlign: 'right',
                selectOptions: taxOptions,
                readOnly: true
              },
              {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', textAlign: 'right', readOnly: true},
              {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', textAlign: 'right', readOnly: true},
              {
                name: 'grossAmount',
                displayName: 'Gross Amount',
                type: 'AMOUNT',
                textAlign: 'right',
                readOnly: true
              },
            ]}
            rows={computedTaxItems}
            onRowsChanged={() => null}
            rowErrorChecks={[]}
          />
        </Center>
      </Grid.Col>
    </Grid>
  </>;
}