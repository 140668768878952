import {GroupBase, OptionsOrGroups, SingleValue} from "react-select";
import React from "react";
import {FilterOptionOption} from "react-select/dist/declarations/src/filters";
import CreatableSelect from "react-select/creatable";

export type Option = SingleValue<{ value: string, label: string }>;


interface Props {
  value: string
  options?: OptionsOrGroups<any, GroupBase<any>>
  onChange: (option: Option) => void
  defaultInputValue?: string
  modalRef?: React.MutableRefObject<any>
}

export const DataSheetSelect = ({
                                  value,
                                  options,
                                  onChange,
                                  defaultInputValue,
                                  modalRef
                                }: Props) => {

  const opts = options?.map(o => ({value: o.value, label: o.label})) ?? [];

  const handleOnChange = (option: Option) => {
    if (option?.value === 'CREATE') {
      modalRef?.current?.openModal(undefined);
    } else {
      onChange(option);
    }
  }

  const handleCreateNew = (label: string) => {
    console.log("handleCreateNew", modalRef?.current);
    modalRef?.current?.openModal(undefined);
  }

  const defaultValue = (options ?? []).find(o => o.value === defaultInputValue)?.label ?? defaultInputValue;

  const filterOption = (option: FilterOptionOption<any>, inputValue: string) => {
    return option.label.toLowerCase().trim().includes(inputValue.toLowerCase().trim()) || option.value === 'CREATE';
  }

  return <>
    <CreatableSelect options={opts}
                     value={(options ?? []).find(o => o.value === value)}
                     defaultInputValue={defaultValue ?? ''}
                     menuPortalTarget={document.body}
                     filterOption={filterOption}
                     onKeyDown={(e) => {
                       if (e.key === 'Enter' || e.key === 'Tab') {
                         e.stopPropagation();
                       }
                       if (e.key === 'Escape') {
                         e.stopPropagation();
                         onChange((options ?? []).find(o => o.value === value));
                       }
                     }}
                     onChange={handleOnChange}
                     onCreateOption={handleCreateNew}
                     openMenuOnFocus={true}
                     closeMenuOnSelect={true}
                     blurInputOnSelect={true}
                     tabSelectsValue={true}
                     menuShouldScrollIntoView={true}
                     placeholder={""}
                     autoFocus
                     styles={{
                       control: (prevStyle, state) => ({
                         ...prevStyle,
                         padding: 0,
                         border: "none",
                         borderRadius: 0,
                         borderColor: 'blueviolet',
                         boxShadow: 'none',
                         background: "white",
                         height: '22px',
                         minHeight: '22px',
                         "&:hover": {
                           borderColor: 'blueviolet',
                           background: "white",
                           border: "none",
                         },
                       }),
                       placeholder: (prevStyle, state) => ({
                         padding: 0,
                       }),
                       valueContainer: (prevStyle, state) => ({
                         ...prevStyle,
                         padding: 0,
                         // border: '1 px solid blueviolet',
                         border: 'none',
                         boxShadow: 'none',
                         height: '22px',
                       }),
                       menu: (prevStyle, state) => ({
                         ...prevStyle,
                         minWidth: '200px'
                       }),
                       menuPortal: (prevStyle, state) => ({
                         ...prevStyle,
                         fontSize: 14,
                         zIndex: 9999,
                       }),
                       input: (prevStyle, state) => ({
                         ...prevStyle,
                         background: "transparent",
                         padding: 0,
                         border: '1 px solid blueviolet',
                         height: '22px',
                         "&:hover": {
                           background: "transparent",
                           border: '1 px solid blueviolet',
                         },
                       }),
                       indicatorsContainer: (prevStyle, state) => ({
                         ...prevStyle,
                         display: 'none'
                       })
                     }}
    />
  </>
}