import React, {ForwardedRef, useRef} from "react";
import {Dialog, Divider, NavLink} from "@mantine/core";
import {IconClipboardText, IconCopy, IconCut, IconPlus, IconReplace, IconTrash} from "@tabler/icons-react";
import {ContextMenuResponse, ContextMenuState} from "./types";
import {formatNumber} from "../../utils/formatUtils";
import {DataSheetSearchAndReplaceModal} from "./DataSheetSearchAndReplaceModal";

export interface DataSheetContextMenuProps {
  state: ContextMenuState
  onResponse: (response: ContextMenuResponse) => void
}

export const DataSheetContextMenu = React.forwardRef(({
                                                        state,
                                                        onResponse,
                                                      }: DataSheetContextMenuProps, ref: ForwardedRef<HTMLDivElement>) => {
  const searchAndReplaceModal = useRef<any>();

  const handleClick = (selectedMenuItem: string) => {
    onResponse({selectedMenuItem: selectedMenuItem} as ContextMenuResponse);
  }

  const handleSearchAndReplaceClick = (searchValue: string, replaceValue: string) => {
    onResponse({
      selectedMenuItem: 'SEARCH_AND_REPLACE',
      searchValue: searchValue,
      replaceValue: replaceValue,
    } as ContextMenuResponse);
  };

  const left = (state.left ?? 0);
  const top = (state.top ?? 0);
  const dialogWidth = 340;
  const dialogHeight = 300;
  const position = {
    left: left + dialogWidth <= window.innerWidth ? left : undefined,
    right: left + dialogWidth <= window.innerWidth ? undefined : window.innerWidth - left,
    top: top + dialogHeight <= window.innerHeight ? top : undefined,
    bottom: top + dialogHeight <= window.innerHeight ? undefined : window.innerHeight - top,
  };

  return <>
    <Dialog ref={ref}
            opened={state.open}
            position={position}
            transitionProps={{transition: 'pop', duration: 0}}
            className={"datasheet-menu"}
    >
      <NavLink label="Cut" onClick={() => handleClick("CUT")} disabled={state.rowId === -1}
               leftSection={<IconCut size="1rem" stroke={1.5}/>}/>
      <NavLink label="Copy" onClick={() => handleClick("COPY")} disabled={state.rowId === -1}
               leftSection={<IconCopy size="1rem" stroke={1.5}/>}/>
      <NavLink label={`Copy sum: ${formatNumber(state?.selectedSum)}`} onClick={() => handleClick("COPY_SELECTED_SUM")}
               leftSection={<IconCopy size="1rem" stroke={1.5}/>}/>
      <NavLink label="Paste" onClick={() => handleClick("PASTE")} disabled={state.rowId === -1}
               leftSection={<IconClipboardText size="1rem" stroke={1.5}/>}/>
      <Divider/>
      <NavLink label="Search & Replace" onClick={() => searchAndReplaceModal.current?.openModal()}
               disabled={state.rowId === -1}
               leftSection={<IconReplace size="1rem" stroke={1.5}/>}/>
      <Divider/>
      <NavLink label="Insert Row above" onClick={() => handleClick("INSERT_ROW_ABOVE")} disabled={state.rowId === -1}
               leftSection={<IconPlus size="1rem" stroke={1.5}/>}/>
      <NavLink label="Insert Row below" onClick={() => handleClick("INSERT_ROW_BELOW")}
               leftSection={<IconPlus size="1rem" stroke={1.5}/>}/>
      <NavLink label="Delete row(s)" onClick={() => handleClick("DELETE_ROW")} disabled={state.rowId === -1}
               leftSection={<IconTrash size="1rem" stroke={1.5}/>}/>
    </Dialog>

    <DataSheetSearchAndReplaceModal ref={searchAndReplaceModal} onSuccess={handleSearchAndReplaceClick}/>
  </>;
});