import {BookingType, FileStatus, InvoiceStatus} from "../utils/http/apiClient";
import {Badge, DefaultMantineColor} from "@mantine/core";
import React from "react";

interface Props {
  status?: FileStatus | InvoiceStatus | BookingType
}

export const StatusBadge = ({status}: Props) => {
  let color: DefaultMantineColor = 'gray';
  switch (status ?? 'CREATIN') {
    case 'CREATING':
      color = 'gray';
      break;
    case 'DRAFT':
    case 'NEW':
      color = 'yellow';
      break;
    case 'BOOKED':
    case "FULL":
    case "LINKED":
      color = 'blue';
      break;
    case 'PARTIAL':
      color = 'grape';
      break;
    case 'BLOCKED':
      color = 'red';
      break;
  }
  // style={{display: 'block', width: 'fit-content'}}>
  return <Badge color={color} opacity={0.7} style={{display: 'flex'}}>
    {status ?? 'CREATING'}
  </Badge>
}