import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React from "react";
import {ApiClient, ApiFileFilterRequest} from "../../../../utils/http/apiClient";
import {DocumentLink} from "../../../../common/DocumentLink";
import {Column, DataTable} from "../../../../common/datatable/DataTable";
import {createFilterMap, TableFilter} from "../../../../common/datatable/filter/types";
import {FileUploadButton} from "./FileUploadButton";
import {roundToX} from "../../../../utils/objectUtils";

export const FileList = () => {
  const organisationId = useOrganisationId() ?? '';
  const objectType = 'FILE';

  const columns = [
    {
      name: 'id',
      label: 'ID',
      type: 'TEXT',
      render: (record, column, openModal) => {
        return <DocumentLink type={objectType} id={record[column.name]}
                             onClick={() => openModal?.(objectType, record[column.name])}/>
      },
      filterDisabled: true
    },
    {
      name: 'name',
      label: 'Name',
      type: 'TEXT',
    },
    {
      name: 'size',
      label: 'Size',
      type: 'NUMBER',
      render: (record, column) => {
        const bytes = record[column.name];
        const kiloBytes = roundToX(bytes / 1000, 1);
        return kiloBytes + " kB";
      },
    },
  ] as Column[];

  return <>
    <DataTable objectType="FILE"
               loadingOverlay={true}
               columns={columns}
               fetchRecords={(filter: TableFilter) => {
                 const filterRequest = {
                   pageNum: filter.pageNum,
                   pageSize: filter.pageSize,
                 } as ApiFileFilterRequest;
                 const filterMap = createFilterMap(filter.columnFilters, columns);
                 Object.entries(filterMap)
                   .forEach(([key, value]) => {
                     filterRequest[key] = value
                   });
                 return ApiClient.getFilesByFilter(organisationId, filterRequest);
               }}
               customNewButton={(callback) =>
                 <FileUploadButton onFileChange={(files: FileList | null) => {
                   if (!files || files.length !== 1) {
                     return;
                   }
                   const file = files.item(0);
                   if (organisationId && file) {
                     callback(() => ApiClient.uploadFile(organisationId, file));
                   }
                 }}/>
               }/>
  </>;
}
