import {
  ActionIcon,
  Button,
  Checkbox,
  Dialog,
  Flex,
  Grid,
  Modal,
  MultiSelect,
  NavLink,
  Popover,
  Select,
  Text
} from "@mantine/core";
import {IconFilterCog} from "@tabler/icons-react";
import React, {useState, MouseEvent as ReactMouseEvent, useEffect, useRef} from "react";
import {TextInput} from "../../TextInput";
import {ColumnFilter} from "./types";
import {SelectOptions} from "../DataTable";


interface FilterMultiSelectButton {
  filter: ColumnFilter
  onChange: (filter: ColumnFilter) => void
  options: SelectOptions
  disabled?: boolean
}

export const FilterMultiSelectButton = ({
                                          filter,
                                          onChange,
                                          options,
                                          disabled
                                        }: FilterMultiSelectButton) => {

  const [internalFilter, setInternalFilter] = useState<ColumnFilter>(filter);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setInternalFilter(filter);
  }, [filter, options]);

  const handleOnClose = () => {
    setInternalFilter(filter); // Reset if not 'Applied'
  }

  const handleApply = () => {
    onChange(internalFilter);
    setOpened(false);
  }

  const handleClear = () => {
    onChange({
      ...filter,
      operator: 'EQ',
      operands: [],
      sortOrder: null
    });
    setOpened(false);
  }

  return <Popover width={300}
                  trapFocus={false}
                  position="bottom-start"
                  opened={opened}
                  onChange={setOpened}
                  onClose={handleOnClose}>
    <Popover.Target>
      <ActionIcon size="sm" variant="subtle" onClick={() => setOpened(true)} disabled={disabled}>
        <IconFilterCog size={24} stroke={1.5}/>
      </ActionIcon>
    </Popover.Target>

    <Popover.Dropdown className="filter-popover">
      <Grid align="center">
        <Grid.Col span={12}>
          <MultiSelect label={"Select option"}
                       value={internalFilter.operands}
                       data={options}
                       searchable={true}
                       comboboxProps={{withinPortal: false}}
                       size={"sm"}
                       onChange={values => setInternalFilter({...internalFilter, operands: values})}/>
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex direction="row" justify="space-around">
            <Button size="xs" onClick={handleApply}>
              Apply
            </Button>
            <Button size="xs" variant="outline" onClick={handleClear}>
              Clear
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Popover.Dropdown>
  </Popover>
}