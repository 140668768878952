import React from "react";
import {Space} from "@mantine/core";
import {Column, DataTable, SelectColumn} from "../../../common/datatable/DataTable";
import {ApiClient, ApiInvoiceFilterRequest} from "../../../utils/http/apiClient";
import {useOrganisationId} from "../../../hooks/useOrganisationId";
import {formatDate} from "../../../utils/date/dateUtils";
import {formatAmount} from "../../../utils/formatUtils";
import {StatusBadge} from "../../../common/StatusBadge";
import {ObjectLink} from "../../../common/ObjectLink";
import {createFilterMap, TableFilter} from "../../../common/datatable/filter/types";
import {DocumentLink} from "../../../common/DocumentLink";

export const OrgDashboard = () => {
  const orgId = useOrganisationId() ?? '';
  const columns = [
    {
      name: 'id',
      label: 'ID',
      type: 'TEXT',
      render: (record, column, openModal) => {
        return <DocumentLink type="INCOMING_INVOICE" id={record[column.name]}
                             onClick={() => openModal?.('INCOMING_INVOICE', record[column.name])}/>
      },
      filterDisabled: true
    },
    {
      name: 'bookingStatus',
      label: 'Status',
      type: 'SELECT',
      options: [
        {label: 'DRAFT', value: 'DRAFT'},
        {label: 'BOOKED', value: 'BOOKED'},
        {label: 'PARTIAL', value: 'PARTIAL'},
      ],
      optionLookup: {},
      render: (record, column) =>
        <div style={{textAlign: 'center', justifyContent: 'center', display: 'flex'}}>
          <StatusBadge status={record[column.name]}/>
        </div>
    } as SelectColumn,
    {
      name: 'bookingDate',
      label: 'Booking Date',
      type: 'DATE',
      render: (record, column) => formatDate(record[column.name]),
    } as Column,
    {
      name: 'objectId',
      label: 'Supplier',
      type: 'SELECT',
      options: [],
      optionLookup: {},
      fetchOptions: () =>
        ApiClient.getContacts(orgId)
          .then(resp => resp.data.map(c => ({label: c.name, value: c.id}))),
      render: (record, column, openModal) => {
        const selectCol = column as SelectColumn;
        const value = record[column.name];
        return <ObjectLink name={selectCol.optionLookup[value]}
                           onClick={() => openModal?.('CONTACT', value)}/>
      },
    } as SelectColumn,
    {
      name: 'invoiceNumber',
      label: 'Invoice Number',
      type: 'TEXT'
    } as Column,
    {
      name: 'grossAmount',
      label: 'Gross Amount',
      type: 'NUMBER',
      render: (record, column) => formatAmount(record[column.name]),
    } as Column,
    {
      name: 'currency',
      label: 'Currency',
      type: 'SELECT',
      options: [
        {label: 'EUR', value: 'EUR'},
        {label: 'USD', value: 'USD'},
        {label: 'GBP', value: 'GBP'},
      ],
      optionLookup: {},
    } as SelectColumn,
    {
      name: 'issueDate',
      label: 'Issue Date',
      type: 'DATE',
      render: (record, column) => formatDate(record[column.name]),
    } as Column,
    {
      name: 'dueDate',
      label: 'Due Date',
      type: 'DATE',
      render: (record, column) => formatDate(record[column.name]),
    } as Column,
  ] as Column[];

  return <>
    Coming soon...

    <Space h={20}/>
    <DataTable objectType={'INCOMING_INVOICE'}
               columns={columns}
               fetchRecords={(filter: TableFilter) => {
                 const filterRequest = {
                   pageNum: filter.pageNum,
                   pageSize: filter.pageSize,
                 } as ApiInvoiceFilterRequest;
                 const filterMap = createFilterMap(filter.columnFilters, columns);
                 Object.entries(filterMap)
                   .forEach(([key, value]) => {
                     filterRequest[key] = value
                   });
                 return ApiClient.getInvoicesByFilter(orgId, 'INCOMING_INVOICE', filterRequest);
               }}/>
  </>;
}