import {PageControl} from "../PageControl";
import {FileCanvas} from "./FileCanvas";
import React, {useCallback, useEffect, useState, MouseEvent as ReactMouseEvent} from "react";
import {ApiClient, ApiFile, ImageAction} from "../../../../../utils/http/apiClient";
import {Box, mapToBox} from "./canvas";
import {LoadingOverlay} from "@mantine/core";
import {FileCanvasReadonly} from "./FileCanvasReadonly";

interface Props {
  organisationId: string | null | undefined
  file: ApiFile | null
  readonly?: boolean
}

export const FileView = ({organisationId, file, readonly}: Props) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [page, setPage] = useState(0);
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPageImage = () => {
    if (!organisationId || !file) {
      setImage(null);
      return;
    }
    ApiClient.getFilePageImage(organisationId, file.id, page)
      .then((fileResp) => {
        const img = new Image();
        img.onload = () => setImage(img);
        img.src = 'data:image/png;base64,' + fileResp.data;
      })
      .then(() => {
        setBoxes(file.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
      });
  }

  useEffect(() => {
    fetchPageImage();
  }, [file?.id, page]);

  const performImageAction = useCallback((action: ImageAction) => {
    if (organisationId && file?.id) {
      setLoading(true);
      ApiClient.editFilePageImage(organisationId, file?.id, page, action)
        .then(() => fetchPageImage())
        .finally(() => setLoading(false));
    }
  }, [organisationId, file, page]);

  return <>
    <PageControl fileName={file?.name}
                 pageCount={file?.ocrResponse?.pages?.length ?? 0}
                 currentPage={page}
                 onPageChange={(page) => setPage(page)}
                 disabled={!file}
                 onImageAction={performImageAction}
    />
    <LoadingOverlay visible={loading}/>
    <div>
      {readonly
        ? <FileCanvasReadonly image={image}
                              boxes={boxes}
                              width={600}
                              height={window.innerHeight - 220}/>
        : <FileCanvas image={image}
                      boxes={boxes}
                      width={600}
                      height={window.innerHeight - 150}/>}
    </div>
  </>

}