import {TextInput} from "@mantine/core";
import React, {KeyboardEvent as ReactKeyboardEvent, useEffect, useState} from "react";
import {ChangeCause, mapChangeCause} from "../DataSheet";

interface Props {
  value: string | null
  onChange: (value: string | null, cause: ChangeCause) => void
}

export const DataSheetTextInput = ({
                                     value,
                                     onChange,
                                   }: Props) => {
  const [internalValue, setInternalValue] = useState<string>(value ?? '');

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  const handleSubmit = (cause: ChangeCause) => {
    onChange(internalValue, cause);
  }

  const handleKeyDown = (event: ReactKeyboardEvent<HTMLInputElement>) => {
    const cause = mapChangeCause(event.key);
    if (cause !== 'Unknown') {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event.key as ChangeCause);
    }
  }

  return <TextInput autoFocus
                    value={internalValue}
                    defaultValue={"TEST"}
                    onClick={e => e.stopPropagation()}
                    onMouseDown={e => e.stopPropagation()}
                    onKeyDown={handleKeyDown}
                    onChange={e => setInternalValue(e.target.value)}
  />
}