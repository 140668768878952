import {Button} from "@mantine/core";
import {IconUpload} from "@tabler/icons-react";
import React, {useRef} from "react";

interface Props {
  onFileChange: (fileList: FileList | null) => void
}

export const FileUploadButton = ({onFileChange}: Props) => {
  const ref = useRef<any>();

  return <>
    <input type="file" hidden ref={ref} onChange={e => onFileChange(e.target.files)}/>
    <Button size="xs"
            leftSection={<IconUpload size={20}/>}
            onClick={() => ref.current?.click()}>
      Upload
    </Button>
  </>

}