import {Column, InternalRow, Row} from "./types";
import {DataSheetCell} from "./DataSheetCell";
import {memo, MouseEvent, useEffect} from "react";

export interface DataSheetRowProps {
  row: Row
  internalRow: InternalRow
  rowId: number
  columns: Column[]
  showLineNumbers: boolean
  onCellMouseDown: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseMove: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseUp: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseOver: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellMouseOut: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellDoubleClick: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellRightClick: (event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => void
  onCellChange: (value: any, rowId: number, colId: number) => void
}

export const DataSheetRow = memo(({
                                    row,
                                    internalRow,
                                    rowId,
                                    columns,
                                    showLineNumbers,
                                    onCellMouseDown,
                                    onCellMouseMove,
                                    onCellMouseUp,
                                    onCellMouseOver,
                                    onCellMouseOut,
                                    onCellDoubleClick,
                                    onCellRightClick,
                                    onCellChange,
                                  }: DataSheetRowProps) => {
  // console.log("row render: ", rowId);

  return <tr>
    {showLineNumbers
      ? <td style={{"textAlign": "center"}}>{rowId + 1}</td>
      : <></>}
    {columns.map((col, colId) => (
      <DataSheetCell key={colId}
                     rowId={rowId}
                     colId={colId}
                     cellValue={row[col.name]}
                     internalCell={internalRow.cells[colId]}
                     column={col}
                     onCellMouseDown={onCellMouseDown}
                     onCellMouseMove={onCellMouseMove}
                     onCellMouseUp={onCellMouseUp}
                     onCellMouseOver={onCellMouseOver}
                     onCellMouseOut={onCellMouseOut}
                     onCellDoubleClick={onCellDoubleClick}
                     onCellRightClick={onCellRightClick}
                     onCellChange={onCellChange}
      />
    ))}
  </tr>
});