import React, {useCallback, useImperativeHandle, useReducer, useRef} from "react";
import {useOrganisationId} from "../../../../../hooks/useOrganisationId";
import {
  ApiClient,
  ApiContact,
  ApiInvoice,
  ApiInvoiceLineItem,
  ApiInvoiceTaxItem,
  ApiItem,
  ApiRegister,
  ApiTax,
  ApiUnit,
  ApiUpsertInvoiceLineItemRequest,
  ApiUpsertInvoiceRequest,
  BookingStatus,
  InvoiceType
} from "../../../../../utils/http/apiClient";
import {notifyError, notifySavedChanges} from "../../../../../utils/notificationUtils";
import {Box, Button, Checkbox, Flex, LoadingOverlay, Modal, Space} from "@mantine/core";
import {ModalTitle} from "../../../../../common/ModalTitle";
import {InvoiceLineItems} from "./InvoiceLineItems";
import {InvoiceVatItems} from "./InvoiceVatItems";
import {InvoiceTotals} from "./InvoiceTotals";
import {deriveUnitAndQuantity, mapInvoiceLineItems} from "../../files/extraction/extractionsUtils";
import {FileView} from "../../files/canvas/FileView";
import {nonEmptyObject} from "../../../../../utils/objectUtils";
import {InvoiceSaveCancelButtons} from "./InvoiceSaveCancelButtons";
import {IncomingInvoiceFieldGrid} from "../fieldGrid/IncomingInvoiceFieldGrid";
import {ItemModal} from "../../items/ItemModal";
import {Comments} from "../../../../../common/comments/Comments";
import {RegisterReportFieldGrid} from "../fieldGrid/RegisterReportFieldGrid";
import {isInternalUser} from "../../../../../utils/token/tokenCache";

interface Props {
  onSuccess: () => void
}

export interface State {
  organisationId: string
  invoiceType: InvoiceType
  invoice: ApiInvoice
  opened: boolean
  loading: boolean
  deriveFromExtraction: boolean
  taxes: ApiTax[]
  items: ApiItem[]
  contacts: ApiContact[]
  registers: ApiRegister[]
  units: ApiUnit[]
  internalView?: boolean
}

type ActionType =
  | 'OPEN_MODAL'
  | 'CLOSE_MODAL'
  | 'FETCH_ENTITIES_SUCCESS'
  | 'FETCH_ENTITIES_ERROR'
  | 'UPDATE_LINE_ITEMS'
  | 'UPDATE_TAX_ITEMS'
  | 'UPDATE_INVOICE'
  | 'SET_DERIVE_FROM_EXTRACTION'
  | 'DERIVE_UNITS_AND_QUANTITIES'
  | 'SET_LOADING'
  | 'ADD_NEW_ITEM_OPTION'
  | 'FETCH_ITEMS'
  | 'FETCH_ITEMS_SUCCESS';

type ActionBase = { type: ActionType };

type Action = ActionBase & ({
  type: 'OPEN_MODAL',
  invoiceType: InvoiceType,
  invoiceId: string | null,
} | {
  type: 'CLOSE_MODAL'
} | {
  type: 'FETCH_ENTITIES_SUCCESS'
  invoice: ApiInvoice
  taxes: ApiTax[]
  items: ApiItem[]
  contacts: ApiContact[]
  registers: ApiRegister[]
  units: ApiUnit[]
} | {
  type: 'FETCH_ENTITIES_ERROR'
} | {
  type: 'UPDATE_LINE_ITEMS'
  lineItems: ApiInvoiceLineItem[]
} | {
  type: 'UPDATE_TAX_ITEMS'
  taxItems: ApiInvoiceTaxItem[]
} | {
  type: 'UPDATE_INVOICE'
  invoice: ApiInvoice
} | {
  type: 'SET_DERIVE_FROM_EXTRACTION'
  deriveFromExtraction: boolean
} | {
  type: 'DERIVE_UNITS_AND_QUANTITIES'
} | {
  type: 'SET_LOADING'
  loading: boolean
} | {
  type: 'FETCH_ITEMS'
} | {
  type: 'FETCH_ITEMS_SUCCESS'
  items: ApiItem[]
} | {
  type: 'ADD_NEW_ITEM_OPTION'
  newItem: ApiItem
});

function deriveColumns(state: State) {
  let hasChanges = false;
  const lineItems = [...state.invoice.lineItems]
    .map(lineItem => {
      const unitPrice = (lineItem.netAmount && lineItem.quantity)
        ? lineItem.netAmount / lineItem.quantity
        : lineItem.unitPrice;

      if (unitPrice !== lineItem.unitPrice) {
        lineItem = {...lineItem, unitPrice: unitPrice};
        hasChanges = true;
      }

      // const unit = (lineItem.extUnit1 === lineItem.extUnit2)
      //   ? lineItem.extUnit1
      //   : lineItem.extUnit2;
      //
      // if (unit !== lineItem.unit) {
      //   lineItem = {...lineItem, unit: unit};
      //   hasChanges = true;
      // }

      return lineItem;
    });

  return hasChanges
    ? {...state, invoice: {...state.invoice, lineItems: lineItems}}
    : state;
}

function adjustLineItems(state: State) {
  const mappedLineItems = mapInvoiceLineItems(
    state.invoice.lineItems,
    state.taxes,
    state.invoice.extDiscountInPercentage,
    state.invoice.extDiscountIncludedInAmounts,
    state.invoice.extAmountsAreNet);
  const newState = state.deriveFromExtraction
    ? {...state, invoice: {...state.invoice, lineItems: mappedLineItems}}
    : state;
  return deriveColumns(newState);
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "OPEN_MODAL": {
      return {
        ...state,
        opened: true,
        loading: true,
        invoiceType: action.invoiceType,
        invoiceId: action.invoiceId,
      } as State;
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        opened: false,
        invoiceId: null,
        invoice: emptyInvoice(),
      } as State;
    }
    case "FETCH_ENTITIES_SUCCESS": {
      const newState = {
        ...state,
        loading: false,
        invoice: action.invoice,
        taxes: action.taxes,
        items: action.items,
        contacts: action.contacts,
        registers: action.registers,
        units: action.units,
      } as State;
      return deriveColumns(newState);
    }
    case "FETCH_ENTITIES_ERROR": {
      return {
        ...state,
        loading: false,
        invoice: emptyInvoice(),
        taxes: [],
        items: [],
        contacts: [],
        registers: [],
        units: [],
      };
    }
    case "UPDATE_LINE_ITEMS": {
      const newState = {
        ...state,
        invoice: {
          ...state.invoice,
          lineItems: action.lineItems
        }
      };
      return adjustLineItems(newState);
    }
    case "UPDATE_TAX_ITEMS": {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          taxItems: action.taxItems,
        }
      };
    }
    case "UPDATE_INVOICE": {
      const newState = {...state, invoice: action.invoice};
      return adjustLineItems(newState);
    }
    case "SET_DERIVE_FROM_EXTRACTION": {
      const newState = {...state, deriveFromExtraction: action.deriveFromExtraction};
      return adjustLineItems(newState);
    }
    case "DERIVE_UNITS_AND_QUANTITIES": {
      let lineItems = state.invoice.lineItems.map(lineItem => {
        const unitAndQuantity = deriveUnitAndQuantity(lineItem.text ?? '');
        return {
          ...lineItem,
          extUnit1: unitAndQuantity.unit,
          extQuantity1: unitAndQuantity.quantity
        } as ApiInvoiceLineItem
      });
      const newState = {...state, invoice: {...state.invoice, lineItems}};
      return adjustLineItems(newState);
    }
    case "ADD_NEW_ITEM_OPTION": {
      return {
        ...state,
        items: [...state.items, action.newItem]
      } as State;
    }
    case "FETCH_ITEMS_SUCCESS": {
      return {
        ...state,
        items: action.items
      } as State;
    }
  }
  return state;
}

const emptyInvoice = () => {
  return {
    extAmountsAreNet: true,
    extDiscountInPercentage: true,
    extDiscountIncludedInAmounts: true,
    lineItems: [...Array(3)].map(_ => ({} as ApiInvoiceLineItem)),
    taxItems: [...Array(3)].map(_ => ({} as ApiInvoiceTaxItem)),
  } as ApiInvoice;
}

export const InvoiceModal = React.forwardRef(({onSuccess}: Props, ref) => {
  const [state, dispatch] = useReducer(reducer, {
    organisationId: useOrganisationId() ?? '',
    invoiceType: 'INCOMING_INVOICE', // Default, will change on open
    invoice: emptyInvoice(),
    loading: false,
    opened: false,
    deriveFromExtraction: false,
    taxes: [],
    items: [],
    contacts: [],
    registers: [],
    units: [],
    internalView: isInternalUser()
  } as State);
  const itemModalRef = useRef<any>();

  const openModal = useCallback((invoiceType: InvoiceType, invoiceId?: string) => {
    dispatch({type: 'OPEN_MODAL', invoiceType: invoiceType, invoiceId: invoiceId ?? null});
    Promise.all([
      invoiceId
        ? ApiClient.getInvoice(state.organisationId, invoiceType, invoiceId).then(resp => resp.data)
        : Promise.resolve(emptyInvoice()),
      ApiClient.getTaxes(state.organisationId).then(resp => resp.data),
      ApiClient.getItems(state.organisationId).then(resp => resp.data),
      ApiClient.getContacts(state.organisationId).then(resp => resp.data),
      ApiClient.getRegisters(state.organisationId).then(resp => resp.data),
      ApiClient.getUnits(state.organisationId).then(resp => resp.data),
    ])
      .then(([invoice, taxes, items, contacts, registers, units]) => {
        dispatch({
          type: 'FETCH_ENTITIES_SUCCESS',
          invoice, taxes, items, contacts, registers, units
        })
      })
      .catch(error => {
        dispatch({type: 'FETCH_ENTITIES_ERROR'});
        notifyError(error);
      });
  }, []);

  const refreshEntities = () => {
    Promise.all([
      ApiClient.getTaxes(state.organisationId).then(resp => resp.data),
      ApiClient.getItems(state.organisationId).then(resp => resp.data),
      ApiClient.getContacts(state.organisationId).then(resp => resp.data),
      ApiClient.getRegisters(state.organisationId).then(resp => resp.data),
      ApiClient.getUnits(state.organisationId).then(resp => resp.data),
    ])
      .then(([taxes, items, contacts, registers, units]) => {
        dispatch({
          type: 'FETCH_ENTITIES_SUCCESS',
          invoice: state.invoice, taxes, items, contacts, registers, units
        })
      })
      .catch(error => {
        dispatch({type: 'FETCH_ENTITIES_ERROR'});
        notifyError(error);
      });
  }

  const closeModal = useCallback((success: boolean) => {
    if (success) {
      onSuccess();
    }
    dispatch({type: 'CLOSE_MODAL'});
  }, []);

  useImperativeHandle(ref, () => ({openModal}));

  const handleLineItemsChanged = useCallback((lineItems: ApiInvoiceLineItem[]) => {
    dispatch({type: 'UPDATE_LINE_ITEMS', lineItems: lineItems});
  }, []);

  const handleTaxItemsChanged = useCallback((taxItems: ApiInvoiceTaxItem[]) => {
    dispatch({type: 'UPDATE_TAX_ITEMS', taxItems: taxItems});
  }, []);

  const handleUpdateInvoice = useCallback((invoice: ApiInvoice) => {
    dispatch({type: 'UPDATE_INVOICE', invoice: invoice});
  }, []);

  const handleSetDeriveFromExtraction = useCallback((deriveFromExtraction: boolean) => {
    dispatch({type: 'SET_DERIVE_FROM_EXTRACTION', deriveFromExtraction: deriveFromExtraction});
  }, []);

  const deriveUnitsAndQuantities = useCallback(() => {
    dispatch({type: 'DERIVE_UNITS_AND_QUANTITIES'});
  }, []);

  const setLoading = useCallback((loading: boolean) => {
    dispatch({type: 'SET_LOADING', loading});
  }, []);

  const handleNewItem = useCallback((newItem: ApiItem) => {
    dispatch({type: 'SET_LOADING', loading: true});
    ApiClient.getItems(state.organisationId)
      .then(resp => dispatch({type: 'FETCH_ITEMS_SUCCESS', items: resp.data}))
      .catch(error => {
        dispatch({type: 'SET_LOADING', loading: false});
        notifyError(error);
      });
  }, []);

  const mapToLineItemRequest = (lineItem: ApiInvoiceLineItem) => {
    return lineItem as ApiUpsertInvoiceLineItemRequest;
  }
  const mapToTaxItemRequest = (taxItem: ApiInvoiceTaxItem) => {
    return {
      taxId: taxItem.taxId,
      netAmount: taxItem.netAmount,
      taxAmount: taxItem.taxAmount,
      grossAmount: taxItem.grossAmount,
    } as ApiInvoiceTaxItem;
  }

  const handleSave = (bookingStatus: BookingStatus) => {
    setLoading(true);
    const request = {
      ...state.invoice,
      bookingStatus: bookingStatus,
      lineItems: state.invoice.lineItems.map(mapToLineItemRequest).filter(nonEmptyObject),
      taxItems: state.invoice.taxItems.map(mapToTaxItemRequest).filter(nonEmptyObject),
    } as ApiUpsertInvoiceRequest;
    const organisationId = state.organisationId;
    (state.invoice.id
        ? ApiClient.updateInvoice(organisationId, state.invoiceType, state.invoice.id, request)
        : ApiClient.createInvoice(organisationId, state.invoiceType, request)
    )
      .then(() => closeModal(true))
      .then(notifySavedChanges)
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  const handleDelete = () => {
    setLoading(true);
    ApiClient.deleteInvoice(state.organisationId, state.invoiceType, state.invoice.id)
      .then(() => closeModal(true))
      .then(notifySavedChanges)
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  return <>
    <Modal opened={state.opened}
           onClose={() => closeModal(false)}
           title={<ModalTitle name={'INCOMING_INVOICE'} id={state.invoice?.id}/>}
           closeOnClickOutside={false}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           fullScreen={state.internalView}
           size="100%"
           closeOnEscape={false}
    >
      <LoadingOverlay visible={state.loading}/>
      <Flex direction="row" gap="md" justify="center">
        <Flex direction="column"
              style={{
                position: 'sticky',
                top: 60,
                height: '100%',
              }}>
          <FileView organisationId={state.organisationId}
                    file={state.invoice?.files?.[0] ?? null}
                    readonly={!state.internalView}/>
        </Flex>

        <Flex direction="column">

          {state.invoiceType === 'INCOMING_INVOICE'
            ? <IncomingInvoiceFieldGrid invoice={state.invoice}
                                        setInvoice={handleUpdateInvoice}
                                        contacts={state.contacts}
                                        fetchEntities={refreshEntities}/>
            : <RegisterReportFieldGrid invoice={state.invoice}
                                       setInvoice={handleUpdateInvoice}
                                       registers={state.registers}
                                       fetchEntities={refreshEntities}/>}

          <Space h="20"/>

          <InvoiceLineItems lineItems={state.invoice.lineItems ?? []}
                            onLineItemsChange={handleLineItemsChanged}
                            items={state.items}
                            taxes={state.taxes}
                            units={state.units}
                            itemModalRef={itemModalRef}
                            internalView={state.internalView ?? false}/>

          <Space h="20"/>

          {state.internalView && <>
              <Flex direction={"row"} gap={10} justify={"right"} align={"center"}>
                  <Button size="xs" onClick={deriveUnitsAndQuantities}>
                      Derive units and quantities
                  </Button>
                  <Checkbox checked={state.deriveFromExtraction}
                            onChange={e => handleSetDeriveFromExtraction(e.target.checked)}
                            label={"Map extraction automatically"}/>
                  <Checkbox checked={state.invoice.extAmountsAreNet}
                            onChange={e => handleUpdateInvoice({
                              ...state.invoice,
                              extAmountsAreNet: e.target.checked
                            })}
                            label={"Amount are Net"}/>
                  <Checkbox checked={state.invoice.extDiscountInPercentage}
                            onChange={e => handleUpdateInvoice({
                              ...state.invoice,
                              extDiscountInPercentage: e.target.checked
                            })}
                            label={"Discount in percentage"}/>
                  <Checkbox checked={state.invoice.extDiscountIncludedInAmounts}
                            onChange={e => handleUpdateInvoice({
                              ...state.invoice,
                              extDiscountIncludedInAmounts: e.target.checked
                            })}
                            label={"Discount already included in amounts"}/>
              </Flex>

              <Space h="20"/>
          </>}

          <InvoiceVatItems vatItems={state.invoice.taxItems ?? []}
                           onVatItemsChange={handleTaxItemsChanged}
                           lineItems={state.invoice.lineItems ?? []}
                           taxes={state.taxes}/>

          <Space h="20"/>

          <InvoiceTotals invoice={state.invoice}
                         setInvoice={invoice => handleUpdateInvoice(invoice)}/>

          <InvoiceSaveCancelButtons handleSave={(bookingStatus) => handleSave(bookingStatus)}
                                    handleCancel={() => closeModal(false)}
                                    handleDelete={() => handleDelete()}
                                    invoiceBookingStatus={state.invoice.bookingStatus}
                                    loading={state.loading}/>
          <Box style={{paddingTop: 20, paddingBottom: 100}}>
            <Comments organisationId={state.organisationId}
                      objectType={'INCOMING_INVOICE'}
                      objectId={state.invoice.id}/>
          </Box>
        </Flex>
      </Flex>
    </Modal>

    <ItemModal ref={itemModalRef} onSuccess={handleNewItem}/>
  </>
});