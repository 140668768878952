import React, {useCallback, useEffect, useImperativeHandle, useState, MouseEvent} from "react";
import {Dialog, NavLink} from "@mantine/core";
import {IconPencil, IconTrashX} from "@tabler/icons-react";
import {useSet} from "@mantine/hooks";
import {useUserId} from "../../hooks/useUserId";

interface Props {
  onOptionClick: (commentId: string, option: string) => void
}

export const CommentContextMenu = React.forwardRef(({onOptionClick}: Props, ref) => {
  const [open, setOpen] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [authorId, setAuthorId] = useState('');
  const [commentId, setCommentId] = useState('');
  const userId = useUserId();

  const openMenu = useCallback((left: number, top: number, commentId: string, authorId: string) => {
    setLeft(left);
    setTop(top);
    setOpen(true);
    setAuthorId(authorId);
    setCommentId(commentId);
  }, [open]);

  useImperativeHandle(ref, () => ({openMenu}));

  useEffect(() => {
    const handleClick = () => setOpen(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleOptionClick = (option: string, event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    setOpen(false);
    onOptionClick(commentId, option);
  }

  return <Dialog opened={open}
                 position={{top, left}} transitionProps={{transition: 'pop', duration: 0}}
                 className={"comment-context-menu"}
                 size="sm">
    <NavLink label="Edit"
             onClick={(e) => handleOptionClick("EDIT", e)}
             leftSection={<IconPencil size="1rem" stroke={1.5}/>}
             disabled={authorId !== userId}/>
    <NavLink label="Delete"
             onClick={(e) => handleOptionClick("DELETE", e)}
             leftSection={<IconTrashX size="1rem" stroke={1.5} color="red"/>}
             disabled={authorId !== userId}/>
  </Dialog>;
});