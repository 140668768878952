import {Row} from "../../../../common/datasheet/types";
import {roundToX} from "../../../../utils/objectUtils";

import {ApiInvoiceLineItem, ApiInvoiceTaxItem} from "../../../../utils/http/apiClient";
import {roundAmount} from "../../../../utils/objectUtils";

export interface Totals {
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

export function computeTaxItems(lineItems: ApiInvoiceLineItem[]) {
  const netAmounts: Record<string, number> = {};
  const taxAmounts: Record<string, number> = {};
  const grossAmounts: Record<string, number> = {};

  lineItems.forEach(li => {
    const taxId = li.taxId ?? 'NaN';
    netAmounts[taxId] = (netAmounts[taxId] ?? 0) + Number(li.netAmount);
    taxAmounts[taxId] = (taxAmounts[taxId] ?? 0) + Number(li.taxAmount);
    grossAmounts[taxId] = (grossAmounts[taxId] ?? 0) + Number(li.grossAmount);
  });

  const taxIds = Object.keys(netAmounts);
  return Array.from(taxIds)
    .map(taxId => ({
      taxId: taxId,
      netAmount: roundAmount(netAmounts[taxId]),
      taxAmount: roundAmount(taxAmounts[taxId]),
      grossAmount: roundAmount(grossAmounts[taxId]),
    } as ApiInvoiceTaxItem));
}

export function computeTotals(lineItems: ApiInvoiceLineItem[]): Totals[] {
  const taxItems = computeTaxItems(lineItems);
  return [{
    name: 'Total',
    netAmount: roundAmount(taxItems.map(ti => ti.netAmount ?? 0).reduce((acc, curr) => acc + curr, 0)),
    taxAmount: roundAmount(taxItems.map(ti => ti.taxAmount ?? 0).reduce((acc, curr) => acc + curr, 0)),
    grossAmount: roundAmount(taxItems.map(ti => ti.grossAmount ?? 0).reduce((acc, curr) => acc + curr, 0)),
  } as Totals];
}


// Check:  Net + Tax = Gross
export function checkNetPlusTaxEqualGross(row: Row) {
  const shouldCheck = [row.netAmount, row.taxAmount, row.grossAmount]
    .filter(a => a !== undefined && !Number.isNaN(a))
    .length > 0;

  if (shouldCheck) {
    const diff = roundToX(row.netAmount + row.taxAmount - row.grossAmount, 4);
    if (diff !== 0) {
      return ` Gross - Net - Tax ≠ 0 [${row.grossAmount} - ${row.netAmount} - ${row.taxAmount} = ${diff}]`;
    }
  }
  return null;
}

//TODO: Add  Check: TaxRate * Net = Tax
