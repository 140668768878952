import {ApiFilter, ApiRangeFilter, ApiSelectFilter, ApiTextFilter} from "../../../utils/http/apiClient";
import {Column, ColumnType} from "../DataTable";

export type FilterOperator =
  | 'LTE'
  | 'GTE'
  | 'EQ'
  | 'CONTAINS';

export type SortOrder = 'ASC' | 'DESC';

export type ColumnFilter = {
  column: string
  operator: FilterOperator | null
  operands: any[]
  sortOrder: SortOrder | null
}

export type TableFilter = {
  columnFilters: ColumnFilter[]
  pageNum: number
  pageSize: number
}

export function createFilterMap(filters: ColumnFilter[], columns: Column[]) {
  const filterMap: Record<string, ApiFilter> = {};
  filters.forEach(filter => {
    const column = columns.filter(c => c.name === filter.column)[0];
    filterMap[column.name] = toApiFilter(filter, column.type);
  });
  return filterMap;
}

export function toApiFilter(filter: ColumnFilter, type: ColumnType) {
  switch (type) {
    case "TEXT":
      return toApiTextFilter(filter);
    case "SELECT":
      return toApiSelectFilter(filter);
    case "NUMBER":
    case "DATE":
      return toApiRangeFilter(filter);
  }
}

export function toApiSelectFilter(filter: ColumnFilter) {
  return {values: filter.operands} as ApiSelectFilter;
}

export function toApiTextFilter(filter: ColumnFilter) {
  return {
    text: filter.operands[0],
    sortOrder: filter.sortOrder
  } as ApiTextFilter;
}

export function toApiRangeFilter(filter: ColumnFilter) {
  return {
    from: filter.operands[0],
    to: filter.operands[1],
    sortOrder: filter.sortOrder
  } as ApiRangeFilter;
}