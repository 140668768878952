import {GroupBase, OptionsOrGroups} from "react-select";

export function createIdMap<T extends { id: string }>(objects: T[]) {
  return objects.reduce((acc, curr) => {
    return {...acc, [curr.id]: curr};
  }, {} as Record<string, T>);
}

export function createValueLookup(options: OptionsOrGroups<any, GroupBase<any>>) {
  return options.reduce((acc, curr) => {
    return {...acc, [curr.value]: curr.label}
  }, {} as Record<string, any>);
}

export const roundAmount = (num: number) => roundToX(num, 2);

export const roundToX = (num = 0, decimals = 2) => Math.round(num * 10 ** decimals) / 10 ** decimals;

export const nonEmptyObjects = <T>(objs: T[]) => {
  return objs.filter(obj => nonEmptyObject(obj));
}

export const emptyObject = (obj: any) => {
  return !nonEmptyObject(obj);
}

export const nonEmptyObject = (obj: any) => {
  return Object.entries(obj)
    .filter(([key, value]) => !key.toLowerCase().endsWith('id') && nonEmptyValue(value))
    .length > 0;
}

const nonEmptyValue = (value: any) => {
  return value !== null && value !== undefined && value !== '';
}

export const addEmptyDefaultElemsIfEmpty = <T>(array: T[] | null | undefined) => {
  return array && array.length > 0
    ? array
    : [...Array(3)].map(_ => ({} as T));
}

export const initIfEmpty = <T>(obj: T | undefined | null) => {
  return obj ?? {} as T;
}