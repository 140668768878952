import {ApiComment, ApiUser} from "../../utils/http/apiClient";
import {ActionIcon, Avatar, Button, Divider, Flex, Text, Textarea} from "@mantine/core";
import {formatDateTime} from "../../utils/date/dateUtils";
import {IconCircleX, IconDeviceFloppy, IconDotsVertical} from "@tabler/icons-react";
import React, {MouseEvent, useEffect, useState} from "react";
import {TextAreaInput} from "../TextAreaInput";

interface Props {
  editing: boolean
  comment: ApiComment
  usersById: Record<string, ApiUser>
  openContextMenu: (event: MouseEvent<HTMLButtonElement>) => void
  onTextChange: (text: string) => void
  onSaveEdit: () => void
  onCancelEdit: () => void
}

export const Comment = ({
                          editing,
                          comment,
                          usersById,
                          openContextMenu,
                          onTextChange,
                          onSaveEdit,
                          onCancelEdit
                        }: Props) => {

  return <Flex direction="column" gap={10} style={{padding: 0, width: '500px'}}>
    <Divider/>
    <Flex direction="row" gap="md" justify="space-between">
      <Flex direction="row" gap="md" align="center">
        <Avatar key={comment.author} name={usersById[comment.author ?? '']?.displayName} size="md"/>
        <Flex direction="column" gap={0}>
          <Text size="sm" fw={600}>{usersById[comment.author ?? '']?.displayName}</Text>
          <Text size="sm">{formatDateTime(comment.createTime)}</Text>
        </Flex>
      </Flex>
      <ActionIcon variant="subtle" radius="lg" onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        openContextMenu(e);
      }}>
        <IconDotsVertical size={24} stroke={1.5}/>
      </ActionIcon>
    </Flex>

    <span style={{whiteSpace: 'pre-line'}}>
      {editing
        ? <Flex direction="column" gap="xs">
          <TextAreaInput value={comment.text ?? ''}
                         onChange={onTextChange}/>
          <Flex direction="row" gap="md">
            <Button size="xs" leftSection={<IconDeviceFloppy/>}
                    onClick={() => onSaveEdit()}>
              Save
            </Button>
            <Button size="xs" variant="outline" leftSection={<IconCircleX/>}
                    onClick={onCancelEdit}>
              Cancel
            </Button>
          </Flex>
        </Flex>
        : comment.text}
    </span>
  </Flex>
}