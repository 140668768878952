import {Button, Checkbox, Flex, Grid, Modal, Switch, Tabs, Text} from "@mantine/core";
import React, {FormEvent, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {
  ApiBomItem,
  ApiClient,
  ApiItem,
  ApiItemCategory,
  ApiUnit,
  UpsertBomItemRequest,
  UpsertItemRequest
} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX} from "@tabler/icons-react";
import {UnitSelect} from "../../../../common/select/UnitSelect";
import {createIdMap, nonEmptyObject} from "../../../../utils/objectUtils";
import {TextInput} from "../../../../common/TextInput";
import {Option} from "../../../../common/datasheet/input/DataSheetSelect";
import {CategorySelect} from "../../../../common/select/CategorySelect";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";
import {ModalTitle} from "../../../../common/ModalTitle";
import {DataSheet} from "../../../../common/datasheet/DataSheet";

interface Props {
  onSuccess?: (apiItem: ApiItem) => void
  onClose?: () => void
}

export interface Item {
  id?: string
  name: string
  categoryId: string | null
  unitId: string | null
  sold: boolean
  stocked: boolean
  produced: boolean
  active: boolean
  bomItems: BomItem[]

  [key: string]: any
}

export interface BomItem {
  id: string
  partId: string
  quantity: number
  unitId: string

  [key: string]: any
}

function createEmptyItem() {
  return {
    name: '',
    categoryId: '',
    unitId:  '',
    sold: true,
    stocked: true,
    produced: true,
    active: true,
    bomItems: [...Array(3)].map(_ => createEmptyBomItem())
  } as Item;
}

const mapApiItem = (apiItem: ApiItem, itemsById: Record<string, ApiItem>) => {
  return {
    id: apiItem?.id,
    name: apiItem ? apiItem.name : '',
    categoryId: apiItem ? apiItem.categoryId : '',
    unitId: apiItem ? apiItem.unitId : '',
    sold: apiItem ? apiItem.sold : true,
    stocked: apiItem ? apiItem.stocked : true,
    produced: apiItem ? apiItem.produced : true,
    active: apiItem ? apiItem.active : true,
    bomItems: apiItem
      ? apiItem.bomItems.map(bi => mapApiBomItem(bi, itemsById))
      : [...Array(3)].map(_ => createEmptyBomItem())
  } as Item;
}

function createEmptyBomItem () {
  return {
    id: crypto.randomUUID(),
  } as BomItem;
}

const mapApiBomItem = (apiBomItem: ApiBomItem, itemsById: Record<string, ApiItem>) => {
  return {
    id: apiBomItem?.id ?? crypto.randomUUID(),
    partId: apiBomItem?.partId,
    quantity: apiBomItem?.quantity,
    unitId: itemsById[apiBomItem.partId]?.unitId
  } as BomItem
}

export const ItemModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [item, setItem] = useState<Item>(createEmptyItem());
  const [categories, setCategories] = useState<ApiItemCategory[]>([]);
  const [itemsById, setItemsById] = useState<Record<string, ApiItem>>({});
  const [itemOptions, setItemOptions] = useState<Option[]>([]);


  const itemModalRef = useRef<any>();

  const fetchEntities = useCallback(() => {
    if (organisationId && opened) {
      setLoading(true);
      Promise.all([
        ApiClient.getUnits(organisationId).then(resp => resp.data),
        ApiClient.getItems(organisationId).then(resp => resp.data),
        ApiClient.getItemCategories(organisationId).then(resp => resp.data)
      ])
        .then(([units, items, categories]) => {
          const unitsById = createIdMap(units);
          setItemsById(createIdMap(items));
          setItemOptions(items.map(i => ({
            value: i.id,
            label: `${i.name} [${unitsById[i.unitId].shortName}]`
          } as Option)));
          setCategories(categories);
        }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId, opened]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const openModal = useCallback((apiItem?: ApiItem) => {
    if (apiItem) {
      setItem(mapApiItem(apiItem, itemsById));
    } else {
      setItem(createEmptyItem());
    }
    open()
  }, [open]);

  const closeModal = useCallback(() => {
    setItem(createEmptyItem());
    onClose?.();
    close();
  }, [setItem, close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoading(true);
    const request = {
      name: item.name,
      categoryId: item.categoryId,
      unitId: item.unitId,
      sold: item.sold,
      stocked: item.stocked,
      produced: item.produced,
      active: item.active,
      bomItems: item.bomItems
        .filter(nonEmptyObject)
        .map(bi => ({partId: bi.partId, quantity: bi.quantity} as UpsertBomItemRequest))
    } as UpsertItemRequest;
    (item.id
        ? ApiClient.updateItem(organisationId ?? '', item.id, request)
        : ApiClient.createItem(organisationId ?? '', request)
    )
      .then((resp) => {
        notifySavedChanges();
        onSuccess?.(resp.data);
        closeModal();
      })
      .catch(err => {
        notifyError(err);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }

  const addEmptyBomItems = () => {
    setItem({...item, bomItems: [{} as BomItem, {} as BomItem, {} as BomItem]});
  }

  const gridColSpan = [1.5, 8, 2.5];

  return <>
    <Modal opened={opened}
           id="item_modal"
           onClose={closeModal}
           title={<ModalTitle name="Item" id={item?.id}/>}
           closeOnClickOutside={false}
           returnFocus={true}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size={"600px"}
    >
      <Flex align="center" direction="column" gap="sm">
        <form style={{width: "100%"}} onSubmit={handleSave}>
          <Grid style={{width: '35rem'}} gutter="sm" align="center">

            <Grid.Col span={gridColSpan[0]}>
              <Text size="sm">Name: </Text>
            </Grid.Col>
            <Grid.Col span={gridColSpan[1]}>
              <TextInput value={item.name}
                         disabled={loading}
                         onChange={value => setItem({...item, name: value ?? ''})}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[2]}>
              <Checkbox label="Stocked" checked={item.stocked}
                        onChange={e => setItem({...item, stocked: (e.target.checked)})}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[0]}>
              <Text size="sm">Category: </Text>
            </Grid.Col>
            <Grid.Col span={gridColSpan[1]}>
              <CategorySelect categoryId={item.categoryId ?? undefined}
                              categories={categories}
                              onChange={categoryId => setItem({...item, categoryId: categoryId ?? null})}
                              onNewCreated={newCategory => {
                                setItem({...item, categoryId: newCategory.id});
                                fetchEntities();
                              }}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[2]}>
              <Checkbox label="Sold" checked={item.sold} onChange={e => setItem({...item, sold: (e.target.checked)})}/>
            </Grid.Col>

            <Grid.Col span={gridColSpan[0]}>
            </Grid.Col>

            <Grid.Col span={gridColSpan[1]}>
            </Grid.Col>

            <Grid.Col span={gridColSpan[2]}>
              <Checkbox label="Produced" checked={item.produced}
                        onChange={e => setItem({...item, produced: (e.target.checked)})}/>
            </Grid.Col>

          </Grid>

          <Flex direction="row" justify="right" style={{width: "100%", paddingTop: '30px'}}>
            <Switch size={"sm"}
                    labelPosition="left"
                    label="Active"
                    style={{paddingTop: '5px'}}
                    checked={item.active}
                    onChange={e => setItem({...item, active: e.target.checked})}
            />
          </Flex>

          <Tabs defaultValue="bom" variant={"outline"} style={{marginTop: '10px'}}>
            <Tabs.List grow>
              <Tabs.Tab value="bom">
                Bill Of Materials
              </Tabs.Tab>
              <Tabs.Tab value="usedIn">
                Used in
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="bom">
              <Flex justify="center">
                {item.bomItems.length == 0
                  ? <Button size="xs" style={{marginTop: 20}} onClick={addEmptyBomItems}>Add bom items</Button>
                  : <DataSheet
                    showLineNumbers={true}
                    // noWrap={true}
                    columns={[
                      {
                        name: 'partId',
                        displayName: 'Part (item)',
                        type: 'SELECT',
                        textAlign: 'left',
                        width: 500,
                        selectOptions: itemOptions,
                        modalRef: itemModalRef,
                      },
                      {
                        name: 'quantity',
                        displayName: 'Quantity',
                        type: 'NUMBER',
                        textAlign: 'right',
                      },
                    ]}
                    rows={item.bomItems}
                    onRowsChanged={rows => setItem({...item, bomItems: rows as BomItem[]})}
                    rowErrorChecks={[]}
                  />}
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="usedIn">
              Coming soon...
            </Tabs.Panel>
          </Tabs>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <Button type="submit" rightSection={<IconCheck/>} loading={loading}>
              Save
            </Button>
            <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
    {/*<ItemModal ref={itemModalRef} onSuccess={fetchEntities} />*/}
  </>;
});