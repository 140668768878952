import {JwtTokenUtils} from "./jwtToken";

export enum TokenType {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
}

export const TOKEN_CHANGED_EVENT = new Event("tokenChanged");

export function storeAccessToken(token: string) {
  storeToken(token, TokenType.ACCESS_TOKEN);
}

export function storeRefreshToken(token: string) {
  storeToken(token, TokenType.REFRESH_TOKEN);
}

export function getAccessToken() {
  return getToken(TokenType.ACCESS_TOKEN);
}

export function getRefreshToken() {
  return getToken(TokenType.REFRESH_TOKEN);
}

export function clearAccessToken() {
  clearToken(TokenType.ACCESS_TOKEN);
}

export function clearRefreshToken() {
  clearToken(TokenType.REFRESH_TOKEN);
}

export function clearAllTokens() {
  clearToken(TokenType.ACCESS_TOKEN);
  clearToken(TokenType.REFRESH_TOKEN);
}

export function getToken(tokenType: TokenType) {
  return localStorage.getItem(tokenType);
}

export function storeToken(token: string, tokenType: TokenType) {
  localStorage.setItem(tokenType, token);
  window.dispatchEvent(TOKEN_CHANGED_EVENT);
}

export function clearToken(tokenType: TokenType) {
  localStorage.removeItem(tokenType);
  window.dispatchEvent(TOKEN_CHANGED_EVENT);
}

export function isInternalUser(): boolean {
  const accessToken = getAccessToken();
  if (accessToken) {
    const roles = JwtTokenUtils.decodeJwtToken(accessToken).rol;
    return roles.filter(r => r === 'INTERNAL_USER').length > 0;
  }
  return false;
}