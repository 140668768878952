import {Anchor, Text} from "@mantine/core";
import {DocumentType, ObjectType} from "../utils/http/apiClient";
import React from "react";

function shortDocumentName(type: ObjectType | DocumentType) {
  if (type === 'INCOMING_INVOICE') {
    return 'II'
  } else if (type === 'REGISTER_REPORT') {
    return 'RR'
  } else if (type === 'WORK_ORDER') {
    return 'WO'
  } else if (type === 'FILE') {
    return 'FILE'
  } else if (type === 'TRANSACTION') {
    return 'TR'
  } else {
    return 'UNKNOWN';
  }
}

interface Props {
  type: ObjectType | DocumentType
  id: string | null,
  onClick?: () => void
}

export const DocumentLink = ({type, id, onClick}: Props) => {
  if (id === null) {
    return <></>
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    onClick?.();
  }

  const label = `${shortDocumentName(type)}-${id.substring(0, 6).toUpperCase()}`;

  return onClick
    ? <Anchor size="sm"
              style={{fontFamily: 'Monospace', fontSize: '12px'}}
              onClick={e => handleClick(e)}>
      {label}
    </Anchor>
    : <Text size="sm" style={{fontFamily: 'Monospace', fontSize: '12px'}}>
      {label}
    </Text>
}