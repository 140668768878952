import {
  Action,
  CellPosition,
  CellRange,
  CellValue,
  Column,
  InternalCell,
  InternalRow,
  Row,
  RowErrorCheck,
  State
} from "./types";
import {cleanNumber} from "../../pages/private/organisation/files/extraction/extractionsUtils";
import {renderCellTextValue} from "./DataSheetCell";

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'PROPS_UPDATE': {
      if (action.rows === state.rows && state.columns === action.columns) {
        return state;
      }
      let newInternalRows: InternalRow[] = [];
      newInternalRows = adjustInternalRows(action.rows, state.internalRows, action.columns);
      newInternalRows = checkAndSetErrors(action.rows, newInternalRows, action.columns, state.rowErrorChecks);
      const newColumns = adjustColumnWidths(action.columns, action.rows);
      return {
        ...state,
        columns: newColumns,
        rows: action.rows,
        rowsToUpdate: null,
        internalRows: newInternalRows,
        editing: false,
      };
    }

    case 'ROWS_UPDATE_COMPLETED': {
      return {
        ...state,
        rowsToUpdate: null
      };
    }

    case 'CELL_CHANGE': {
      const colName = state.columns[action.colId].name;
      const rowsToUpdate = [...state.rows];
      rowsToUpdate[action.rowId] = {
        ...rowsToUpdate[action.rowId],
        [colName]: action.value
      };

      const selectionEnd = {
        rowId: action.rowId,
        colId: action.colId,
      } as CellPosition;

      let newInternalRows: InternalRow[] = [];
      newInternalRows = setEditingCell(state.internalRows, null, null);
      newInternalRows = selectCellsInRange(newInternalRows, selectionEnd, selectionEnd);
      newInternalRows = checkAndSetErrors(rowsToUpdate, newInternalRows, state.columns, state.rowErrorChecks);

      return {
        ...state,
        rowsToUpdate: rowsToUpdate,
        internalRows: newInternalRows,
        editing: false,
        selectionStart: selectionEnd,
        selectionEnd: selectionEnd,
      };
    }

    case 'CELL_MOUSE_DOWN': {
      if (action.mouseEvent.button !== 0 || state.editing) {
        return state; // Ignore right click and other clicks while editing a cell
      }

      const selectionEnd = {
        rowId: action.rowId,
        colId: action.colId,
      } as CellPosition;

      const selectionStart = action.mouseEvent.shiftKey
        ? state.selectionStart ?? selectionEnd
        : selectionEnd;

      return {
        ...state,
        selectionStart: selectionStart,
        selectionEnd: selectionEnd,
        internalRows: selectCellsInRange(state.internalRows, selectionStart, selectionEnd),
        focused: true,
        mouseDrag: true,
      };
    }

    case 'CELL_MOUSE_MOVE': {
      if (state.mouseDrag
        && !state.editing
        && !(state.selectionEnd?.rowId === action.rowId &&
          state.selectionEnd?.colId === action.colId)) {
        const selectionEnd = {rowId: action.rowId, colId: action.colId} as CellPosition;
        const selectionStart = state.selectionStart ?? selectionEnd;

        return {
          ...state,
          internalRows: selectCellsInRange(state.internalRows, selectionStart, selectionEnd),
          selectionEnd: selectionEnd,
        };
      }
      return state;
    }

    case 'CELL_MOUSE_UP': {
      return {...state, mouseDrag: false};
    }

    case 'CELL_MOUSE_OVER': {
      return {
        ...state,
        internalRows: setHoveredCell(state.internalRows, {rowId: action.rowId, colId: action.colId}),
      };
    }

    case 'CELL_MOUSE_OUT': {
      return {
        ...state,
        internalRows: setHoveredCell(state.internalRows, null),
      };
    }

    case 'CELL_DOUBLE_CLICK': {
      if (state.columns[action.colId].readOnly) {
        return state;
      }
      return {
        ...state,
        editing: true,
        internalRows: setEditingCell(
          state.internalRows, {
            rowId: action.rowId,
            colId: action.colId
          },
          null)
      };
    }

    case 'CELL_RIGHT_CLICK': {
      action.mouseEvent.preventDefault();

      return {
        ...state,
        focused: true,
        mouseDrag: false,
        contextMenu: {
          open: true,
          left: action.mouseEvent.clientX,
          top: action.mouseEvent.clientY,
          rowId: action.rowId !== -1 ? state.selectionEnd?.rowId ?? -1 : -1,
          colId: state.selectionEnd?.colId ?? -1,
          selectedSum: getSelectedSum(state),
        }
      };
    }

    case 'WINDOW_MOUSE_DOWN': {
      return {
        ...state,
        focused: action.clickOnTable,
        editing: action.clickOnTable && state.editing,
        internalRows: action.clickOnTable
          ? state.internalRows
          : setEditingCell(state.internalRows, null, null),
        contextMenu: {
          ...state.contextMenu,
          open: false
        }
      };
    }

    case 'WINDOW_KEY_DOWN': {
      if (!state.focused || state.editing) {
        return state;
      }
      if (action.event.key === 'v' && action.event.ctrlKey) {
        return state; // CTRL + V is handled in 'WINDOW_PASTE' action
      }
      action.event.preventDefault();
      action.event.stopPropagation();

      if (action.event.key === 'Enter' || (action.event.key.length === 1 && !action.event.ctrlKey)) {
        const selectionEnd = {
          rowId: state.selectionEnd?.rowId ?? 0,
          colId: state.selectionEnd?.colId ?? 0,
        } as CellPosition;

        const editStartKey = action.event.key.length === 1 // All printable characters have length 1
          ? action.event.key
          : null;

        if (state.columns[state.selectionEnd?.colId ?? 0].readOnly) {
          return state;
        }

        return {
          ...state,
          selectionStart: state.selectionEnd,
          selectionEnd: selectionEnd,
          editing: true,
          internalRows: setEditingCell(state.internalRows, state.selectionEnd, editStartKey),
        }
      } else if (action.event.key === 'Delete' || action.event.key === 'Backspace') {

        const rowsToUpdate = [...state.rows];
        const {minR, maxR, minC, maxC} = getSelectionRange(state.selectionStart, state.selectionEnd);

        for (let r = minR; r <= maxR; r++) {
          rowsToUpdate[r] = {...rowsToUpdate[r]};
          for (let c = minC; c <= maxC; c++) {
            if (!state.columns[c].readOnly) {
              rowsToUpdate[r][state.columns[c].name] = null;
            }
          }
        }
        return {
          ...state,
          rowsToUpdate: rowsToUpdate,
          internalRows: checkAndSetErrors(rowsToUpdate, state.internalRows, state.columns, state.rowErrorChecks),
        };

      } else if (action.event.key === 'c' && action.event.ctrlKey) {

        const values = [];
        for (let rId = 0; rId < state.rows.length; rId++)
          for (let cId = 0; cId < state.columns.length; cId++)
            if (state.internalRows[rId].cells[cId].selected)
              values.push(state.rows[rId][state.columns[cId].name]);
        const copyValue = values.map(v => v?.toString()).join('\n');
        navigator.clipboard.writeText(copyValue);
        return state;

      } else if (action.event.key === 'x' && action.event.ctrlKey) {

        const rowsToUpdate = [...state.rows];
        const selectedRange = getSelectionRange(state.selectionStart, state.selectionEnd);
        const copyValues: string[] = [];

        for (let r = selectedRange.minR; r <= selectedRange.maxR; r++) {
          for (let c = selectedRange.minC; c <= selectedRange.maxC; c++) {
            copyValues.push(rowsToUpdate[r][state.columns[c].name]?.toString() ?? '');
            rowsToUpdate[r] = {
              ...rowsToUpdate[r],
              [state.columns[c].name]: null,
            };
          }
        }
        navigator.clipboard.writeText(copyValues.join('\n'));
        return {
          ...state,
          rowsToUpdate: rowsToUpdate,
          contextMenu: {...state.contextMenu, open: false}
        };

      } else if (action.event.key === 'ArrowRight') {
        return moveSelection(state, action.event.shiftKey, 0, 1);
      } else if (action.event.key === 'ArrowLeft') {
        return moveSelection(state, action.event.shiftKey, 0, -1);
      } else if (action.event.key === 'ArrowUp') {
        return moveSelection(state, action.event.shiftKey, -1, 0);
      } else if (action.event.key === 'ArrowDown') {
        return moveSelection(state, action.event.shiftKey, 1, 0);
      }
      return {...state, mouseDrag: false};
    }

    case "WINDOW_PASTE": {
      if (!state.focused) {
        return state;
      }
      const text = action.event.clipboardData?.getData("text") ?? '';
      const lines = text.split('\n');

      const colId = state.selectionEnd?.colId ?? 0;
      const startRowId = state.selectionEnd?.rowId ?? 0;
      const endRowId = startRowId + lines.length;

      if (state.columns[colId].readOnly) {
        return state;
      }

      const rows = [...state.rows];
      for (let rId = startRowId; rId < endRowId; rId++) {
        if (rows.length <= rId) {
          rows.push({});
          for (let c = 0; c < state.columns.length; c++) {
            rows[rId][state.columns[c].name] = null;
          }
        } else {
          rows[rId] = {...rows[rId]};
        }
        const lineId = rId - startRowId;
        switch (state.columns[colId].type) {
          case 'TEXT':
            rows[rId][state.columns[colId].name] = lines[lineId];
            break;
          case 'NUMBER':
          case 'AMOUNT':
            rows[rId][state.columns[colId].name] = lines[lineId] ? Number(cleanNumber(lines[lineId])) : null
            break;
          case 'SELECT':
            const option = state.columns[colId]
              .selectOptions?.find(o => o.label === lines[lineId] || o.value === lines[lineId]) ?? null;
            rows[rId][state.columns[colId].name] = option?.value ?? null;
            break;
        }
      }
      let newInternalRows = adjustInternalRows(rows, state.internalRows, state.columns);
      newInternalRows = checkAndSetErrors(rows, newInternalRows, state.columns, state.rowErrorChecks);

      return {
        ...state,
        rowsToUpdate: rows,
        internalRows: newInternalRows,
      }
    }

    case 'CONTEXT_MENU_CUT': {
      const rowsToUpdate = [...state.rows];
      const selectedRange = getSelectionRange(state.selectionStart, state.selectionEnd);
      const copyValues: string[] = [];

      for (let r = selectedRange.minR; r <= selectedRange.maxR; r++) {
        for (let c = selectedRange.minC; c <= selectedRange.maxC; c++) {
          copyValues.push(rowsToUpdate[r][state.columns[c].name]?.toString() ?? '');
          rowsToUpdate[r] = {
            ...rowsToUpdate[r],
            [state.columns[c].name]: null,
          };
        }
      }
      navigator.clipboard.writeText(copyValues.join('\n'));
      return {
        ...state,
        rowsToUpdate: rowsToUpdate,
        contextMenu: {...state.contextMenu, open: false}
      };
    }

    case 'CONTEXT_MENU_COPY': {
      const rowsToUpdate = [...state.rows];
      const selectedRange = getSelectionRange(state.selectionStart, state.selectionEnd);
      let copyValues: string[] = [];

      for (let r = selectedRange.minR; r <= selectedRange.maxR; r++) {
        for (let c = selectedRange.minC; c <= selectedRange.maxC; c++) {
          copyValues.push(rowsToUpdate[r][state.columns[c].name]?.toString() ?? '');
        }
      }
      navigator.clipboard.writeText(copyValues.join('\n'));
      return {
        ...state,
        rowsToUpdate: rowsToUpdate,
        contextMenu: {...state.contextMenu, open: false}
      };
    }

    case 'CONTEXT_MENU_COPY_SELECTED_SUM': {
      const sum = getSelectedSum(state);
      navigator.clipboard.writeText(sum.toString());
      return {
        ...state,
        contextMenu: {...state.contextMenu, open: false}
      };
    }

    case 'CONTEXT_MENU_SEARCH_AND_REPLACE': {
      const rowsToUpdate = [...state.rows];
      const selectedRange = getSelectionRange(state.selectionStart, state.selectionEnd);

      for (let r = selectedRange.minR; r <= selectedRange.maxR; r++) {
        for (let c = selectedRange.minC; c <= selectedRange.maxC; c++) {
          const strValue = rowsToUpdate[r][state.columns[c].name]?.toString() as string | null;
          let newValue: number | string | null = null;
          switch (state.columns[c].type) {
            case "TEXT":
              newValue = strValue?.replace(action.searchValue, action.replaceValue) ?? null;
              break;
            case "AMOUNT":
            case "NUMBER":
              newValue = Number(strValue?.replace(action.searchValue, action.replaceValue) ?? null);
              break
            default:
              newValue = rowsToUpdate[r][state.columns[c].name];
          }

          rowsToUpdate[r] = {
            ...rowsToUpdate[r],
            [state.columns[c].name]: newValue
          };
        }
      }
      return {
        ...state,
        rowsToUpdate: rowsToUpdate,
        contextMenu: {...state.contextMenu, open: false}
      };
    }

    case 'CONTEXT_MENU_INSERT_ROW_BELOW': {
      const emptyRow: Row = {
        cells: state.columns.map(col => ({type: col.type, value: null}))
      }
      const rowsBelow = state.rows.filter((_, idx) => idx <= state.contextMenu.rowId);
      const rowsAbove = state.rows.filter((_, idx) => idx > state.contextMenu.rowId);
      return {
        ...state,
        rowsToUpdate: [...rowsBelow, emptyRow, ...rowsAbove],
        contextMenu: {...state.contextMenu, open: false}
      };
    }

    case 'CONTEXT_MENU_INSERT_ROW_ABOVE': {
      const emptyRow: Row = {
        cells: state.columns.map(col => ({type: col.type, value: null}))
      }
      const rowsBelow = state.rows.filter((_, idx) => idx < state.contextMenu.rowId);
      const rowsAbove = state.rows.filter((_, idx) => idx >= state.contextMenu.rowId);
      return {
        ...state,
        rowsToUpdate: [...rowsBelow, emptyRow, ...rowsAbove],
        contextMenu: {...state.contextMenu, open: false}
      };
    }

    case 'CONTEXT_MENU_DELETE_ROWS': {
      const {minR, maxR} = getSelectionRange(state.selectionStart, state.selectionEnd);
      const rowsBelow = state.rows.filter((_, idx) => idx < minR);
      const rowsAbove = state.rows.filter((_, idx) => idx > maxR);
      return {
        ...state,
        rowsToUpdate: [...rowsBelow, ...rowsAbove],
        contextMenu: {...state.contextMenu, open: false}
      };
    }
  }

  return state;
}

interface UpdateCell {
  predicateCell: (rowId: number, colId: number, cell: InternalCell) => boolean,
  updateCell: (cell: InternalCell) => InternalCell
}

function updateCells(rows: InternalRow[],
                     predicateCell: (rowId: number, colId: number, cell: InternalCell) => boolean,
                     updateCell: (cell: InternalCell) => InternalCell) {
  return rows.map((row, rowId) =>
    row.cells.map((cell, colId) => predicateCell(rowId, colId, cell)).filter(x => x).length > 0
      ? {
        ...row,
        cells: row.cells.map((cell, colId) => predicateCell(rowId, colId, cell)
          ? updateCell(cell)
          : cell)
      }
      : row);
}

function updateCellsChain(rows: InternalRow[], updates: UpdateCell[]) {
  return updates.reduce((agg, curr) => updateCells(agg, curr.predicateCell, curr.updateCell), rows);
}

function getSelectedSum(state: State) {
  const range = getSelectionRange(state.selectionStart, state.selectionEnd);
  let sum = 0;
  for (let r = range.minR; r >= 0 && r < state.rows.length && r <= range.maxR; r++)
    for (let c = range.minC; c >= 0 && c < state.columns.length && c <= range.maxC; c++) {
      const col = state.columns[c];
      sum += Number(cleanNumber(state.rows[r][col.name]));
    }
  return sum;
}

export function getSelectionRange(selectionStart: CellPosition | null, selectionEnd: CellPosition | null) {
  return {
    minR: Math.min(selectionStart?.rowId ?? 0, selectionEnd?.rowId ?? 0),
    maxR: Math.max(selectionStart?.rowId ?? 0, selectionEnd?.rowId ?? 0),
    minC: Math.min(selectionStart?.colId ?? 0, selectionEnd?.colId ?? 0),
    maxC: Math.max(selectionStart?.colId ?? 0, selectionEnd?.colId ?? 0),
  } as CellRange;
}

export function inCellRange(cellRange: CellRange, rId: number, cId: number) {
  const {minR, maxR, minC, maxC} = cellRange;
  return rId >= minR && rId <= maxR && cId >= minC && cId <= maxC;
}

function selectCellsInRange(rows: InternalRow[], selectionStart: CellPosition, selectionEnd: CellPosition) {
  const selectionRange = getSelectionRange(selectionStart, selectionEnd);

  return updateCellsChain(rows,
    [
      { // Select current cell
        predicateCell: (rId, cId) => inCellRange(selectionRange, rId, cId),
        updateCell: (cell) => ({...cell, selected: true})
      },
      { // Deselect other cells
        predicateCell: (rId, cId, cell) => !(inCellRange(selectionRange, rId, cId)) && cell.selected,
        updateCell: (cell) => ({...cell, selected: false})
      }
    ]);
}

function setEditingCell(rows: InternalRow[], cellPos: CellPosition | null, editStartKey: string | null) {
  const rowId = cellPos?.rowId ?? -1; // If null is specified we just stop editing all cells
  const colId = cellPos?.colId ?? -1;
  return updateCellsChain(rows,
    [
      { // Start editing current cell
        predicateCell: (rId, cId) => rId === rowId && cId === colId,
        updateCell: (cell) => ({...cell, editing: true, editStartKey: editStartKey})
      },
      { // Stop editing other cells
        predicateCell: (rId, cId, cell) => !(rId === rowId && cId === colId) && (cell.editing || cell.selected),
        updateCell: (cell) => ({...cell, editing: false, selected: false})
      }
    ]);
}

function setHoveredCell(rows: InternalRow[], cellPos: CellPosition | null) {
  const rowId = cellPos?.rowId ?? -1; // If null is specified we aren't moving over cells anymore
  const colId = cellPos?.colId ?? -1;
  return updateCellsChain(rows,
    [
      { // Start editing current cell
        predicateCell: (rId, cId) => rId === rowId && cId === colId,
        updateCell: (cell) => ({...cell, hovered: true})
      },
      { // Stop editing other cells
        predicateCell: (rId, cId, cell) => !(rId === rowId && cId === colId) && cell.hovered,
        updateCell: (cell) => ({...cell, hovered: false})
      }
    ]);
}

function moveSelection(state: State, shiftKeyPressed: boolean, dirRow: number, dirCol: number) {
  const rowId = Math.min(Math.max((state.selectionEnd?.rowId ?? 0) + dirRow, 0), state.rows.length - 1);
  const colId = Math.min(Math.max((state.selectionEnd?.colId ?? 0) + dirCol, 0), state.columns.length - 1);

  const selectionEnd = {rowId, colId} as CellPosition;
  const selectionStart = shiftKeyPressed
    ? state.selectionStart ?? selectionEnd
    : selectionEnd;

  return {
    ...state,
    selectionStart: selectionStart,
    selectionEnd: selectionEnd,
    internalRows: selectCellsInRange(state.internalRows, selectionStart, selectionEnd)
  }
}

function checkAndSetErrors(rows: Row[], internalRows: InternalRow[], columns: Column[], rowErrorCheckers: RowErrorCheck[]) {
  for (let r = 0; r < rows.length; r++) {
    const rowErrors = getRowErrors(rows[r], rowErrorCheckers);
    for (let c = 0; c < columns.length; c++) {
      const newErrors = [
        ...rowErrors,
        ...getCellErrors(rows[r][columns[c].name], columns[c])
      ]
      const oldErrors = internalRows[r].cells[c].errors;
      if (JSON.stringify(newErrors) !== JSON.stringify(oldErrors)) {
        internalRows[r].cells[c] = {
          ...internalRows[r].cells[c],
          errors: newErrors
        };
      }
    }
  }
  return internalRows;
}

function getCellErrors(cellValue: CellValue, column: Column): string[] {
  if (column.checkErrors === undefined) {
    return [];
  } else if (Array.isArray(column.checkErrors)) {
    return column.checkErrors.map(check => check(cellValue)).filter(e => e !== null) as string[];
  } else {
    return [column.checkErrors(cellValue)].filter(e => e !== null) as string[];
  }
}

function getRowErrors(row: Row, rowErrorChecks: RowErrorCheck[]): string[] {
  return rowErrorChecks.map(check => check(row)).filter(e => e !== null) as string[];
}

export function initInternalRows(rows: Row[], columns: Column[], rowErrorChecks: RowErrorCheck[]) {
  const internalRows = [];
  for (let r = 0; r < rows.length; r++) {
    internalRows.push({cells: [], errors: []} as InternalRow);
    for (let c = 0; c < columns.length; c++) {
      internalRows[r].cells.push({
        editing: false,
        focused: false,
        selected: false,
        hovered: false,
        editStartKey: null,
        errors: [],
      } as InternalCell);
    }
    for (let c = 0; c < columns.length; c++) {
      internalRows[r].cells[c].errors = [
        ...getRowErrors(rows[r], rowErrorChecks),
        ...getCellErrors(rows[r][columns[c].name], columns[c])
      ];
    }
  }
  return internalRows;
}

function adjustColumnWidths(columns: Column[], rows: Row[]) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return columns;
  }
  context.font = "14px Verdana, sans-serif";
  return columns.map((col, colIdx) => {
    if (col.width) {
      return col; // If there is a defined width, do not override it
    }
    const colName = col.displayName ?? col.name;
    const headerWords = col.wordWrap
      ? colName.split(" ").map(word => word + "   ")
      : [colName + "     "];

    const cells = rows
      .map(r => r[col.name] ?? '')
      .map(cellValue => renderCellTextValue(cellValue, columns[colIdx]));

    const minWordWidth = [...cells, ...headerWords]
      .map(cell => (context?.measureText(cell ?? '').width ?? 0) + 2)
      .reduce((acc, curr) => Math.max(acc, curr), 0);

    return {...col, width: Math.max(minWordWidth, 0)};
  });
}

function adjustInternalRows(rows: Row[], internalRows: InternalRow[], columns: Column[]) {
  if (rows.length === internalRows.length) {
    return internalRows;
  }
  const newInternalRows: InternalRow[] = [];
  for (let r = 0; r < rows.length; r++) {
    newInternalRows.push({cells: []});
    if (r < internalRows.length) {
      newInternalRows[r] = internalRows[r];
    } else {
      for (let c = 0; c < columns.length; c++) {
        newInternalRows[r].cells.push({
          editing: false,
          focused: false,
          selected: false,
          hovered: false,
          editStartKey: null,
          errors: []
        } as InternalCell);
      }
    }
  }
  return newInternalRows;
}