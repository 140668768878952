import React from "react";
import {Badge, Flex, Text, Tooltip} from "@mantine/core";
import {
  IconFlipHorizontal,
  IconFlipVertical, IconRotate,
  IconRotate2,
  IconRotateClockwise,
  IconRotateClockwise2
} from "@tabler/icons-react";
import {ImageAction} from "../../../../utils/http/apiClient";
import {ImageActionButton} from "../../../../common/ImageActionButton";
import {truncate} from "../../../../utils/formatUtils";


interface PageControlProps {
  fileName?: string
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
  onImageAction?: (action: ImageAction) => void
  disabled?: boolean
}

export const PageControl = ({
                              fileName,
                              pageCount,
                              currentPage,
                              onPageChange,
                              onImageAction,
                              disabled
                            }: PageControlProps) => {
  return <Flex direction="row" justify="space-between" align="center">

    <Tooltip label={fileName ?? 'No file selected'}>
      <Text size="sm" style={{paddingRight: '0.5rem'}}>
        File: {truncate(fileName ?? 'No file selected', 27)}
      </Text>
    </Tooltip>

    <Flex align="center">
      <Text size="sm" style={{paddingRight: '0.5rem'}}>Actions: </Text>
      <ImageActionButton toolTip={"Rotate image 90 degrees counter clockwise"}
                         icon={<IconRotate size="2rem" stroke={1.5}/>}
                         onClick={() => onImageAction?.("ROTATE_90_CCW")}
                         disabled={disabled}/>
      <ImageActionButton toolTip={"Rotate image 90 degrees clockwise"}
                         icon={<IconRotateClockwise size="2rem" stroke={1.5}/>}
                         onClick={() => onImageAction?.("ROTATE_90_CW")}
                         disabled={disabled}/>
      <ImageActionButton toolTip={"Rotate image 1 degree counter clockwise"}
                         icon={<IconRotate2 size="2rem" stroke={1.5}/>}
                         onClick={() => onImageAction?.("ROTATE_1_CCW")}
                         disabled={disabled}/>
      <ImageActionButton toolTip={"Rotate image 1 degree clockwise"}
                         icon={<IconRotateClockwise2 size="2rem" stroke={1.5}/>}
                         onClick={() => onImageAction?.("ROTATE_1_CW")}
                         disabled={disabled}/>
      <ImageActionButton toolTip={"Flip image vertically"}
                         icon={<IconFlipHorizontal size="2rem" stroke={1.5}/>}
                         onClick={() => onImageAction?.("FLIP_VERTICAL")}
                         disabled={disabled}/>
      <ImageActionButton toolTip={"Flip image horizontally"}
                         icon={<IconFlipVertical size="2rem" stroke={1.5}/>}
                         onClick={() => onImageAction?.("FLIP_HORIZONTAL")}
                         disabled={disabled}/>

      <Text size="sm" style={{paddingLeft: '1rem', paddingRight: '0.5rem'}}>Pages: </Text>
      {[...Array(pageCount)].map((_, idx) => (
        <Badge key={idx}
               onClick={() => onPageChange(idx)}
               size="md"
               variant={idx === currentPage ? 'filled' : 'default'}
               style={{cursor: 'pointer'}}
        >
          {idx + 1}
        </Badge>
      ))}
    </Flex>
  </Flex>;
}