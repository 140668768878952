import {ApiInvoice, BookingStatus} from "../../../../../utils/http/apiClient";
import {Button, Flex} from "@mantine/core";
import {ActionButton} from "../../../../../common/actionButton/ActionButton";
import {IconBook, IconCircleX, IconDeviceFloppy, IconTrashX} from "@tabler/icons-react";
import {StatusBadge} from "../../../../../common/StatusBadge";
import React from "react";

interface Props {
  invoiceBookingStatus: BookingStatus
  handleSave: (bookingStatus: BookingStatus) => void
  handleCancel: () => void
  handleDelete: () => void
  loading: boolean
}

export const InvoiceSaveCancelButtons = ({
                                           invoiceBookingStatus,
                                           handleSave,
                                           handleCancel,
                                           handleDelete,
                                           loading
                                         }: Props) => {
  return <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
    <ActionButton
      defaultAction={
        {
          label: "Save as Draft",
          action: () => handleSave('DRAFT'),
          leftSection: <IconDeviceFloppy size="1.5rem" stroke={1.5}/>,
          disabled: invoiceBookingStatus !== 'DRAFT'
        }
      }
      options={[
        {
          label: <>Save and book as <StatusBadge status="PARTIAL"/></>,
          action: () => handleSave('PARTIAL'),
          leftSection: <IconBook size="1.5rem" stroke={1.5}/>,
          disabled: invoiceBookingStatus === 'PARTIAL'
        },
        {
          label: <>Save and book as <StatusBadge status="BOOKED"/></>,
          action: () => handleSave('BOOKED'),
          leftSection: <IconBook size="1.5rem" stroke={1.5}/>,
          disabled: invoiceBookingStatus === 'BOOKED'
        },
        'DIVIDER',
        {
          label: 'Delete',
          action: () => handleDelete(),
          leftSection: <IconTrashX size="1.5rem" stroke={1.5} color={'red'}/>,
          disabled: invoiceBookingStatus === undefined,
          confirmation: `Are you sure you want delete this?`
        },
      ]}
    />
    <Button variant="outline" rightSection={<IconCircleX/>} onClick={handleCancel}
            disabled={loading}>
      Cancel
    </Button>
  </Flex>
}