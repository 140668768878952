import React, {MouseEvent, useCallback, useEffect, useReducer, useRef} from "react";
import {Column, ContextMenuResponse, ContextMenuState, Row, RowErrorCheck, State} from "./types";
import {initInternalRows, reducer} from "./reducer";
import {DataSheetRow} from "./DataSheetRow";
import {DataSheetContextMenu} from "./DataSheetContextMenu";
import {Flex, Text} from "@mantine/core";

export interface DataSheetProps {
  columns: Column[]
  rows: Row[]
  onRowsChanged: (rows: Row[]) => void
  showLineNumbers: boolean
  caption?: string
  rowErrorChecks: RowErrorCheck[]
}

export type ChangeCause = 'Enter' | 'Tab' | 'Escape' | 'ArrowRight' | 'ArrowUp' | 'ArrowDown' | 'Unknown';

export function mapChangeCause(value: any): ChangeCause {
  switch (value) {
    case 'Enter':
    case 'Tab':
    case 'Escape':
    case 'ArrowRight':
    case 'ArrowUp':
    case 'ArrowDown':
      return value;
    default:
      return 'Unknown';
  }
}

export const DataSheet = ({
                            columns,
                            rows,
                            onRowsChanged,
                            showLineNumbers,
                            rowErrorChecks,
                            caption
                          }: DataSheetProps) => {
  const tableRef = useRef<any>();
  const contextMenuRef = useRef<any>();
  const [state, dispatch] = useReducer(reducer, {
    columns: columns,
    rows: rows,
    internalRows: initInternalRows(rows, columns, rowErrorChecks),
    rowsToUpdate: null,
    focused: false,
    editing: false,
    mouseDrag: false,
    selectionStart: null,
    selectionEnd: null,
    contextMenu: {open: false, left: 0, top: 0, rowId: -1, colId: -1, selectedSum: 0} as ContextMenuState,
    rowErrorChecks: rowErrorChecks,
  } as State);

  useEffect(() => {
    dispatch({type: 'PROPS_UPDATE', rows: rows, columns: columns});
  }, [rows, columns]);

  useEffect(() => {
    if (state.rowsToUpdate !== null) {
      dispatch({type: 'ROWS_UPDATE_COMPLETED'});
      onRowsChanged(state.rowsToUpdate);
    }
  }, [state.rowsToUpdate, onRowsChanged]);

  const handleCellMouseDown = useCallback((event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => {
    dispatch({type: 'CELL_MOUSE_DOWN', rowId: rowId, colId: colId, mouseEvent: event});
  }, []);

  const handleCellMouseMove = useCallback((event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => {
    dispatch({type: 'CELL_MOUSE_MOVE', rowId: rowId, colId: colId, mouseEvent: event});
  }, []);

  const handleCellMouseUp = useCallback((event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => {
    dispatch({type: 'CELL_MOUSE_UP', rowId: rowId, colId: colId, mouseEvent: event});
  }, []);

  const handleCellMouseOver = useCallback((event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => {
    dispatch({type: 'CELL_MOUSE_OVER', rowId: rowId, colId: colId, mouseEvent: event});
  }, []);

  const handleCellMouseOut = useCallback((event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => {
    dispatch({type: 'CELL_MOUSE_OUT', rowId: rowId, colId: colId, mouseEvent: event});
  }, []);

  const handleCellDoubleClick = useCallback((event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => {
    dispatch({type: 'CELL_DOUBLE_CLICK', rowId: rowId, colId: colId, mouseEvent: event});
  }, []);

  const handleCellRightClick = useCallback((event: MouseEvent<HTMLTableCellElement>, rowId: number, colId: number) => {
    dispatch({type: 'CELL_RIGHT_CLICK', rowId: rowId, colId: colId, mouseEvent: event});
  }, []);

  const handleCellChange = useCallback((value: any, rowId: number, colId: number) => {
    dispatch({type: 'CELL_CHANGE', rowId: rowId, colId: colId, value: value});
  }, []);

  const handleContextMenuResponse = useCallback((response: ContextMenuResponse) => {
    switch (response.selectedMenuItem) {
      case 'CUT':
        dispatch({type: 'CONTEXT_MENU_CUT'});
        break;
      case 'COPY':
        dispatch({type: 'CONTEXT_MENU_COPY'});
        break;
      case 'COPY_SELECTED_SUM':
        dispatch({type: 'CONTEXT_MENU_COPY_SELECTED_SUM'});
        break;
      case 'SEARCH_AND_REPLACE':
        dispatch({
          type: 'CONTEXT_MENU_SEARCH_AND_REPLACE',
          searchValue: response.searchValue ?? '',
          replaceValue: response.replaceValue ?? '',
        });
        break;
      case 'INSERT_ROW_ABOVE':
        dispatch({type: 'CONTEXT_MENU_INSERT_ROW_ABOVE'});
        break;
      case 'INSERT_ROW_BELOW':
        dispatch({type: 'CONTEXT_MENU_INSERT_ROW_BELOW'});
        break;
      case 'DELETE_ROW':
        dispatch({type: 'CONTEXT_MENU_DELETE_ROWS'});
        break;
    }
  }, []);

  const windowMouseDownHandler = useCallback((event: Event) => {
    if (!contextMenuRef.current?.contains(event.target)) {
      dispatch({type: 'WINDOW_MOUSE_DOWN', clickOnTable: tableRef.current?.contains(event.target)});
    }
  }, []);

  const windowKeyDownHandler = useCallback((event: KeyboardEvent) => {
    dispatch({type: 'WINDOW_KEY_DOWN', event});
  }, []);

  const windowPasteHandler = useCallback((event: Event) => {
    dispatch({type: 'WINDOW_PASTE', event: (event as ClipboardEvent)});
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', windowMouseDownHandler, false);
    window.addEventListener('keydown', windowKeyDownHandler, false);
    window.addEventListener('paste', windowPasteHandler, false);
    return () => {
      window.removeEventListener('mousedown', windowMouseDownHandler, false);
      window.removeEventListener('keydown', windowKeyDownHandler, false);
      window.removeEventListener('paste', windowPasteHandler, false);
    }
  }, [tableRef, windowMouseDownHandler, windowKeyDownHandler, windowPasteHandler]);

  return <>
    <Flex direction="column" align="center">
      {caption &&
          <Text size="sm" c="dimmed">{caption}</Text>}
      <table ref={tableRef}
             tabIndex={-1}
             className={"datasheetV2"}>

        <thead>
        <tr>
          {showLineNumbers &&
              <th style={{
                width: '15px',
                textAlign: 'center',
                color: 'white',
                background: 'var(--mantine-color-blue-6)'
              }}>
                  #
              </th>}
          {state.columns.map((column, colIdx) => (
            <th key={colIdx}
                style={{
                  width: column.width + 'px',
                  maxWidth: column.width + 'px',
                  textAlign: 'center',
                  color: column.color ?? 'white',
                  background: column.background ?? 'var(--mantine-color-blue-6)',
                }}
                className={column.readOnly ? 'readOnly' : ''}
                onContextMenu={e => handleCellRightClick(e, -1, colIdx)}
                onMouseMove={e => handleCellMouseMove(e, -1, colIdx)}
                onMouseUp={e => handleCellMouseUp(e, -1, colIdx)}
            >
              {column.displayName ?? column.name}
            </th>
          ))}
        </tr>
        </thead>

        <tbody>
        {state.rows.map((row, rowId) =>
          <DataSheetRow key={rowId}
                        rowId={rowId}
                        row={row}
                        internalRow={state.internalRows[rowId]}
                        columns={state.columns}
                        showLineNumbers={showLineNumbers}
                        onCellMouseDown={handleCellMouseDown}
                        onCellMouseMove={handleCellMouseMove}
                        onCellMouseUp={handleCellMouseUp}
                        onCellMouseOver={handleCellMouseOver}
                        onCellMouseOut={handleCellMouseOut}
                        onCellDoubleClick={handleCellDoubleClick}
                        onCellRightClick={handleCellRightClick}
                        onCellChange={handleCellChange}
          />)}
        </tbody>
      </table>
    </Flex>

    <DataSheetContextMenu ref={contextMenuRef}
                          state={state.contextMenu}
                          onResponse={handleContextMenuResponse}/>
  </>;
}