import {Divider, Flex, Grid, LoadingOverlay, Modal, Space, Text} from "@mantine/core";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {ApiClient, ApiContact, ApiFile, ApiLink, FileStatus, ImageAction} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {Box, mapToBox} from "./canvas/canvas";
import {FileCanvasReadonly} from "./canvas/FileCanvasReadonly";
import {PageControl} from "./PageControl";
import {IconReceipt, IconRubberStamp, IconTrashX} from "@tabler/icons-react";
import {StatusBadge} from "../../../../common/StatusBadge";
import {ActionMenu} from "../../../../common/actionButton/ActionMenu";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";
import {createIdMap} from "../../../../utils/objectUtils";
import {FileLinks} from "./FileLinks";
import {AxiosResponse} from "axios";
import {ModalTitle} from "../../../../common/ModalTitle";
import {InvoiceModal} from "../invoices/modal/InvoiceModal";

interface Props {
  onSuccess?: () => void
  onClose?: () => void
}

export const FileModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [contactsById, setContactsById] = useState<Record<string, ApiContact>>({});

  const modalRefs = {
    extraction: useRef<any>(),
    incomingInvoice: useRef<any>(),
    contact: useRef<any>(),
  };

  const [fileId, setFileId] = useState<string | undefined>();
  const [file, setFile] = useState<ApiFile | undefined>();
  const [links, setLinks] = useState<ApiLink[]>([]);
  const [page, setPage] = useState(0);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [boxes, setBoxes] = useState<Box[]>([]);

  const fetchEntities = () => {
    if (organisationId && fileId) {
      setLoading(true);
      Promise.all([
        ApiClient.getFile(organisationId, fileId),
        ApiClient.getContacts(organisationId),
        ApiClient.getLinks(organisationId, "FILE", fileId)
      ]).then(([file, contacts, links]) => {
        setFile(file.data);
        setBoxes(file.data.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
        setContactsById(createIdMap(contacts.data));
        setLinks(links.data);
      });
    }
  };

  useEffect(() => {
    fetchEntities();
  }, [organisationId, fileId]);

  const openModal = useCallback((fileId?: string) => {
    setFileId(fileId);
    setPage(0);
    open();
  }, [open, fileId]);

  const closeModal = useCallback((success?: Boolean) => {
    setFileId(undefined);
    close();
    if (success) {
      onSuccess?.();
    }
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const fetchPageImage = () => {
    if (!organisationId || !file) {
      return;
    }
    setLoading(true);
    Promise.all([
      ApiClient.getFilePageImage(organisationId, file.id, page),
    ])
      .then(([fileResp]) => {
        const img = new Image();
        img.onload = () => setImage(img);
        img.src = 'data:image/png;base64,' + fileResp.data;
      })
      .then(() => {
        setBoxes(file.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
      })
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPageImage();
  }, [file, page]);

  const doApiCall = (call: () => Promise<AxiosResponse<unknown, any>>) => {
    setLoading(true);
    return call()
      .then(fetchEntities)
      .then(notifySavedChanges)
      .catch(notifyError)
      .finally(() => setLoading(false))
  }

  const updateFileStatus = (status: FileStatus) => {
    if (organisationId && fileId) {
      doApiCall(() => ApiClient.updateFileStatus(organisationId, fileId, status))
        .then(() => closeModal(true));
    }
  }

  const createInvoiceFromFile = () => {
    if (organisationId && fileId) {
      doApiCall(() => ApiClient.createIncomingInvoiceFromFile(organisationId, fileId));
    }
  }

  const unlinkInvoiceFromFile = (invoiceId: string) => {
    if (organisationId && fileId) {
      doApiCall(() => ApiClient.unlinkInvoiceFromFile(organisationId, fileId, invoiceId));
    }
  }

  const unlinkAndDeleteInvoiceFromFile = (invoiceId: string) => {
    if (organisationId && fileId) {
      doApiCall(() => ApiClient.unlinkAndDeleteInvoiceFromFile(organisationId, fileId, invoiceId));
    }
  }

  const deleteFile = () => {
    if (organisationId && fileId) {
      setLoading(true);
      return ApiClient.deleteFile(organisationId, fileId)
        .then(notifySavedChanges)
        .then(() => closeModal(true))
        .catch(notifyError)
        .finally(() => setLoading(false))
    }
  }

  const performImageAction = useCallback((action: ImageAction) => {
    if (organisationId && fileId) {
      setLoading(true);
      ApiClient.editFilePageImage(organisationId, fileId, page, action)
        .then(() => fetchEntities())
        .finally(() => setLoading(false));
    }
  }, [organisationId, fileId, page]);

  return <>
    <LoadingOverlay visible={loading}/>
    <Modal opened={opened}
           id="file_modal"
           onClose={closeModal}
           title={<ModalTitle name={"File"} id={fileId}/>}
           closeOnClickOutside={false}
           returnFocus={true}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size={"auto"}
    >
      <Flex align="center" direction="column" gap="md">

        <Flex direction="row" gap="md">

          <Flex direction="column">
            <PageControl pageCount={file?.ocrResponse.pages.length ?? 0}
                         currentPage={page}
                         onPageChange={(page) => setPage(page)}
                         onImageAction={performImageAction}/>
            <FileCanvasReadonly image={image} boxes={boxes} width={900} height={window.innerHeight * 0.75}/>
          </Flex>

          <div style={{width: '700px'}}>
            <Flex direction="column">
              <Flex direction={"column"} gap="md" style={{width: '100%', height: '2rem'}} align={"center"}>
                <Text c="dimmed" size="sm">General</Text>
              </Flex>

              <Grid style={{width: '30rem'}} gutter="sm" align={"center"}>
                <Grid.Col span={3}>
                  <Text size="sm">Status: </Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <StatusBadge status={file?.status}/>
                </Grid.Col>

                <Grid.Col span={3}>
                  <Text size="sm">Name: </Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <Text size="sm">{file?.name} </Text>
                </Grid.Col>
              </Grid>

              <Space h={20}/>
              <Divider/>
              <Space h={20}/>

              <FileLinks links={links}
                         handleUnlinkAndDeleteInvoice={(invoiceId) => unlinkAndDeleteInvoiceFromFile(invoiceId)}
                         handleUnlink={(invoiceId) => unlinkInvoiceFromFile(invoiceId)}
                         openModal={(invoiceType, invoiceId) => modalRefs.incomingInvoice?.current.openModal(invoiceType, invoiceId)}/>
            </Flex>

            <Space h={20}/>
            <Divider/>
            <Space h={20}/>

            <ActionMenu label={"Actions"}
                        options={[
                          {
                            label: <Flex dir="row" gap="xs">Mark as <StatusBadge status="NEW"/></Flex>,
                            action: () => updateFileStatus('NEW'),
                            leftSection: <IconRubberStamp size="1.5rem" stroke={1.5}/>,
                            disabled: file?.status === 'NEW'
                          },
                          {
                            label: <Flex dir="row" gap="xs">Mark as <StatusBadge status="LINKED"/></Flex>,
                            action: () => updateFileStatus('LINKED'),
                            leftSection: <IconRubberStamp size="1.5rem" stroke={1.5}/>,
                            disabled: file?.status === 'LINKED'
                          },
                          {
                            label: <Flex dir="row" gap="xs">Mark as <StatusBadge status="BLOCKED"/></Flex>,
                            action: () => updateFileStatus('BLOCKED'),
                            leftSection: <IconRubberStamp size="1.5rem" stroke={1.5}/>,
                            disabled: file?.status === 'BLOCKED'
                          },
                          {
                            label: <Flex dir="row" gap="xs">Create an invoice from this file</Flex>,
                            action: () => createInvoiceFromFile(),
                            leftSection: <IconReceipt size="1.5rem" stroke={1.5}/>,
                          },
                          'DIVIDER',
                          {
                            label: 'Delete',
                            action: () => deleteFile(),
                            leftSection: <IconTrashX size="1.5rem" stroke={1.5} color={'red'}/>,
                          },
                        ]}/>
          </div>
        </Flex>
      </Flex>
    </Modal>

    <InvoiceModal ref={modalRefs.incomingInvoice} onSuccess={() => null}/>
  </>
});
